const PageTitle = ({ title, description }) => {
  return (
    <div className="d-flex flex-column justify-content-center h-100">
      <h1>{title}</h1>
      <p className="text-black-50 m-0 ">{description}</p>
    </div>
  );
};

export default PageTitle;
