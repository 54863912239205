import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const CreateProject = ({title, description}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);

  const [formData, setFormData] = useState({
    projectName: "",
    SOW: "",
    payment: "",
    startDate: "",
    endDate: "",
    commercialSummary: "",
    billingSchedule: "",
    projectDuration: "",
    projectDurationCount: "",
    resources: "",
    resourcesCount: "",
    projectType: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleAddMoreResources = () => {
    setAdditionalFields((prevFields) => [...prevFields, { id: Date.now() }]);
  };

  const handleRemoveResources = (fieldId) => {
    setAdditionalFields((prevFields) =>
      prevFields.filter((field) => field.id !== fieldId)
    );
  };

  return (
    <div className="wrapper-body">
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          <Link to="/dashboard/projectList" className="brand-color">
            Back to Project List
          </Link>
        </div>
      </div>

      <form id="assign-asset">
        <div className="card">
          <div className="inputs">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 mt-1">
                    <label htmlFor="projectName">Project Name*</label>
                  </div>
                  <div className="col-md-8 mt-1">
                    <div className="input-group">
                      <input
                        type="text"
                        name="projectName"
                        value={formData.projectName}
                        onChange={handleInputChange}
                        required
                        onKeyPress={(e) => {
                          const inputValue = String.fromCharCode(e.charCode);
                          if (
                            (e.charCode === 32 &&
                              e.target.selectionStart === 0) ||
                            (inputValue.match(/^\d+$/) &&
                              e.target.selectionStart === 0)
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="SOW">SOW*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <select
                        id="SOW"
                        required
                        name="SOW"
                        value={formData.SOW}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Statement of work</option>
                        <option value="SIGNED">SIGNED</option>
                        <option value="PENDING">PENDING</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="payment">Payment*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <select
                        id="payment"
                        required
                        name="payment"
                        value={formData.payment}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Payment</option>
                        <option value="FIXED">FIXED</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="startDate">Start Date*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <input
                        type="date"
                        required
                        name="startDate"
                        value={formData.startDate}
                        placeholder="08/02/2019"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="endDate">End Date*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <input
                        type="date"
                        required
                        name="endDate"
                        value={formData.endDate}
                        placeholder="08/02/2019"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="resources">Resources*</label>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="input-group">
                      <select
                        id="resources"
                        required
                        name="resources"
                        value={formData.resources}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Resource</option>
                        <option value="">---</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 mt-2 ">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="resourcesCount"
                        value={formData.resourcesCount}
                        onChange={handleInputChange}
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (!isDigit) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                    </div>
                  </div>

                  {additionalFields.map((field) => (
                    <React.Fragment key={field.id}>
                      <div
                        className="col-md-4 mt-2"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          className="btn btn-danger btn-sm mt-2"
                          onClick={() => handleRemoveResources(field.id)}
                        >
                          X
                        </button>
                      </div>
                      <div className="col-md-6 mt-2">
                        <div className="input-group">
                          <select
                            id={`resources_${field.id}`}
                            required
                            name={`resources_${field.id}`}
                            value={formData[`resources_${field.id}`]}
                            onChange={handleInputChange}
                          >
                            {/* Options for dynamically added select */}
                            <option value="">Select Resource</option>
                            <option value="">---</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 mt-2 ">
                        <div className="input-group">
                          <input
                            type="tel"
                            name={`resourcesCount_${field.id}`}
                            value={formData[`resourcesCount_${field.id}`]}
                            onChange={handleInputChange}
                            onKeyPress={(e) => {
                              const isDigit = /\d/.test(e.key);
                              if (!isDigit) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="col-md-4 mt-2"></div>
                  <div className="col-md-8 mt-1">
                    <button
                      className="btn btn-primary btn-sm mt-2"
                      onClick={handleAddMoreResources}
                      type="button"
                    >
                      + Add More Resources
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="commercialSummary">
                      Commercial Summary*
                    </label>
                    <div className="input-group">
                      <textarea
                        id="commercialSummary"
                        name="commercialSummary"
                        required
                        rows={8}
                        cols={30}
                        value={formData.commercialSummary}
                        onChange={handleInputChange}
                        onKeyPress={(e) => {
                          // Prevent adding space at the beginning
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault();
                          }

                          // Regular expression to allow only letters, numbers, and spaces
                          const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                          // Check if the character is allowed
                          const char = String.fromCharCode(e.charCode);
                          if (!allowedCharsRegex.test(char)) {
                            e.preventDefault(); // Prevent non-alphanumeric character input
                          }
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-4 mt-2">
                    <label htmlFor="billingSchedule">Billing Schedule*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <select
                        id="billingSchedule"
                        required
                        name="billingSchedule"
                        value={formData.billingSchedule}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Schedule</option>
                        <option value="DIRECT">DIRECT</option>
                        <option value="MONTHLY">MONTHLY</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="projectDuration">Project Duration*</label>
                  </div>
                  <div className="col-md-2 mt-2 ">
                    <div className="input-group">
                      <input
                        type="tel"
                        name="projectDurationCount"
                        value={formData.projectDurationCount}
                        onChange={handleInputChange}
                        onKeyPress={(e) => {
                          const isDigit = /\d/.test(e.key);
                          if (!isDigit) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2 ">
                    <div className="input-group">
                      <select
                        id="projectDuration"
                        required
                        name="projectDuration"
                        value={formData.projectDuration}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Duration</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="projectType">Project Type*</label>
                  </div>
                  <div className="col-md-8 mt-2">
                    <div className="input-group">
                      <select
                        id="projectType"
                        required
                        name="projectType"
                        value={formData.projectType}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Project Type</option>
                        <option value="Direct">Direct</option>
                        <option value="In-Direct">In-Direct</option>
                        <option value="PSG">PSG</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row mt-2"></div> */}
            </div>
          </div>
          <br />

          <div className="action text-end ">
            <button
              className={
                isLoading ? "theme-button disabled " : "theme-button  "
              }
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>

            <a
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropAsset"
              className="open-modal visually-hidden"
            >
              click
            </a>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default CreateProject;
