import Footer from "../../../components/Footer";
import PageTitle from "../../../components/PageTitle";
import { toggleMenu } from "../../../utils/helpers";

const EmployeeEvent = ({title, description}) => {
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Requests : <span>{0}</span>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default EmployeeEvent;
