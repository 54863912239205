import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { getApi, postApi, putApi } from "../../utils/api";
import {
  API_APPROVE_REQUEST,
  API_V2_APPROVE_REQUEST,
  ASSET_LIST,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toggleMenu } from "../../utils/helpers";
import moment from "moment";
import PageTitle from "../../components/PageTitle";

const AssetRequestITApproverForm = ({
  assetId,
  // selectedAssetRequest,
  // assetStatusList,
  fetchData,
  // assetAvailability,
  header,
  title, description
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);

  const { state } = useLocation();
  const { data, assetStatusList } = state || {};
  const [selectedAssetRequest, setSelectedAssetRequest] = useState(data);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    action: "",
    comment: "",
  });
  const [assetAvailability, setAssetAvailability] = useState("");
  const [assetList, setAssetList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [selectedAsset, setSelectedAsset] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState();
  const [selectedAssetDataText, setSelectedAssetDataText] = useState(null);
  const navigate = useNavigate();

  let isAssetSelect = false;

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter((item) =>
      item.serialNumber.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  const handleClose = () => {
    setSearchTerm("");
    if (!isAssetSelect || filteredItems?.length === 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedAsset: "",
      }));
      setSelectedAsset("");
    } else {
      isAssetSelect = false;
    }
  };
  const handleChangeAsset = (e) => {
    const assetId = Number(e.target.value);
    setSelectedAssetId(assetId);
    isAssetSelect = true;
    const selectedAsset = filteredItems.find((asset) => asset.id === assetId);
    if (selectedAsset) {
      let assetInfo = "";
      for (const key in selectedAsset) {
        if (selectedAsset.hasOwnProperty(key)) {
          assetInfo += `${key === "assestName" ? "assetName" : key}: ${
            selectedAsset[key]
          }\n`;
        }
      }
      setSelectedAssetDataText(assetInfo);
      const modalClose = document.getElementsByClassName("close-class")[0];
      modalClose.click();
    } else {
      setSelectedAssetDataText("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const url = selectedAssetId
        ? `${API_V2_APPROVE_REQUEST}?assestRequestId=${selectedAssetRequest?.assestRequestId}&comment=${formData.comment}&status=${formData.action}&masterAssetId=${selectedAssetId}`
        : `${API_V2_APPROVE_REQUEST}?assestRequestId=${selectedAssetRequest?.assestRequestId}&comment=${formData.comment}&status=${formData.action}`;
      await putApi(url, {}, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/dashboard/approve-asset-request-it");
          } else {
            setIsLoading(false);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error("Something went wrong please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAsset = async (id) => {
    // Get asset list regardless of whether the asset type is the same or different
    setAssetAvailability("");
    setIsAssetLoading(true);
    await getApi(ASSET_LIST + `?assestTypeId=${id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetAvailability(res?.data?.data?.length);
          if (res?.data?.data?.length > 0) {
            setSelectedAssetDataText("");
          }
          setAssetList(res?.data?.data);
          setFilteredItems(res.data.data);
          setIsAssetLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsAssetLoading(false);

        // setIsAssetLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      handleAsset(data?.assetTypeId);
    }
  }, [data]);

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({
      action: "",
      comment: "",
    });
    navigate("/dashboard/approve-asset-request-it");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="wrapper-body">
      {isAssetLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          <Link
            to="/dashboard/approve-asset-request-it"
            className="brand-color"
          >
            Back to Request Asset List
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Asset Type</th>
                  <th>Requested Date</th>
                  <th>Reason</th>
                  <th>First Approver Comment</th>
                  <th>First Approver Date</th>
                  <th>Second Approver Comment</th>
                  <th>Second Approver Date </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                <tr>
                  <td data-title="employeeName">
                    {selectedAssetRequest?.employeeName}
                  </td>
                  <td data-title="name">{selectedAssetRequest?.assestType}</td>
                  <td data-title="requestDate">
                    {selectedAssetRequest?.requestDate}
                  </td>
                  <td data-title="reason">{selectedAssetRequest?.reason}</td>
                  <td data-title="firstApproverComment">
                    {selectedAssetRequest?.firstApproverComment}
                  </td>
                  <td data-title="firstApprovalDateTime">
                    {/* {moment(selectedAssetRequest?.firstApprovalDateTime).format(
                      "lll"
                    )} */}
                    {moment(selectedAssetRequest?.firstApprovalDateTime).format(
                      "MM/DD/YYYY"
                    )}
                  </td>
                  <td data-title="secondApproverComment">
                    {selectedAssetRequest?.secondApproverComment}
                  </td>
                  <td data-title="secondApprovalDateTime">
                    {/* {moment(
                      selectedAssetRequest?.secondApprovalDateTime
                    ).format("lll")} */}
                    {moment(
                      selectedAssetRequest?.secondApprovalDateTime
                    ).format("MM/DD/YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {isLoading && (
          <div className="overlay">
            <div className="mt-5">
              <TailSpin
                height="100"
                width="100"
                radius="1"
                color="blue"
                ariaLabel="tail-spin-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}
        <div className="card">
          <h3 className="heading">Approve Asset Request (IT) Action</h3>
          <div className="modal-body">
            {assetAvailability > 0 && (
              <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <label htmlFor="action" className="has-asterisk">
                    Select Asset
                  </label>
                </div>

                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropAsset"
                  >
                    Choose Asset for {selectedAssetRequest?.assestType}
                  </button>
                </div>
                <div className="col-md-2"></div>
              </div>
            )}
            {selectedAssetDataText !== "" && selectedAssetDataText !== null && (
              <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <label htmlFor="action" className="has-asterisk">
                    Selected Asset
                  </label>
                </div>

                <div className="col-md-4">
                  <textarea
                    className="border"
                    value={selectedAssetDataText}
                    rows={7}
                    cols={30}
                    readOnly
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="action" className="has-asterisk">
                  Action
                </label>
              </div>

              <div className="col-md-4">
                <select
                  id="action"
                  required
                  name="action"
                  className="border"
                  value={formData.action}
                  onChange={handleInputChange}
                >
                  <option value="">Select Action</option>
                  {assetStatusList?.map((item) => (
                    <option
                      value={item}
                      disabled={
                        item === "APPROVED"
                          ? assetAvailability === 0 && true
                          : false
                      }
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <label htmlFor="comment" className="has-asterisk">
                  Comment
                </label>
              </div>
              <div className="col-md-4">
                <textarea
                  rows={3}
                  cols={5}
                  className="border"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  required
                  maxLength={200}
                  onKeyPress={(e) => {
                    // Prevent adding space at the beginning
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }

                    // Regular expression to allow only letters, numbers, and spaces
                    const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                    // Check if the character is allowed
                    const char = String.fromCharCode(e.charCode);
                    if (!allowedCharsRegex.test(char)) {
                      e.preventDefault(); // Prevent non-alphanumeric character input
                    }
                  }}
                ></textarea>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="text-center">
                  {assetAvailability === 0 && (
                    <span className="badge badge-danger">
                      No asset is available for the selected asset type{" - "}"
                      {selectedAssetRequest?.assestType}".
                    </span>
                  )}
                  {assetAvailability > 0 && (
                    <span className="badge badge-success">
                      <strong>
                        Asset Available count : {assetAvailability}
                      </strong>
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>

          <div className="justify-content-center modal-footer">
            <button
              type="submit"
              className={
                isLoading || selectedAssetDataText === ""
                  ? "theme-button disabled "
                  : "theme-button "
              }
            >
              {isLoading ? (
                <ThreeDots
                  height="20"
                  width="53"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
            <button
              type="button"
              className="theme-button bg-grey mx-3 w-35 cancel-modal"
              data-bs-dismiss="modal"
              onClick={handleReset}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>

      <div
        className="modal fade"
        id="staticBackdropAsset"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center flex"
                id="staticBackdropLabel"
              >
                Choose Asset Action for {selectedAssetRequest?.assestType}
              </h5>
              <button
                type="button"
                className="btn-close close-class"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="table-responsive">
              <div className="filters">
                {/* <form action="index.html" id="form_filters" className="d-f"> */}
                {/* Search By Company */}
                {assetList?.length !== 0 && (
                  <div className="search">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search By Serial Number"
                      value={searchTerm}
                      className="form-input border border-2"
                      onChange={handleSearch}
                    />
                    <img
                      src="/assets/images/search-ico.svg"
                      width="14"
                      alt="Search"
                    />
                  </div>
                )}

                {/* </form> */}
              </div>
              {filteredItems?.length > 0 && (
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Manufacture</th>
                      <th>Asset Name</th>
                      <th>Serial Number</th>
                      <th>Owner</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((asset, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {/* <td>{asset.id}</td> */}
                        <td>{asset.manufacture}</td>
                        <td>{asset.assestName}</td>
                        <td>{asset.serialNumber}</td>
                        <td>{asset.owner}</td>
                        <td>
                          <div className="radio-group">
                            <input
                              type="radio"
                              id={asset.id}
                              name="selectAsset"
                              value={asset.id}
                              checked={selectedAssetId === asset.id}
                              onChange={handleChangeAsset}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {filteredItems?.length === 0 && (
                <div className="text-center">
                  <h4>Assets are not available for selected type !</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetRequestITApproverForm;
