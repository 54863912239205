import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { deleteApi, getApi } from "../../utils/api";
import { API_EMPLOYEE_EDUCATION } from "../../config/Endpoints";
import { toast } from "react-toastify";
import AddEducation from "./AddEducation";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const Education = ({ educationData }) => {
  const [educationList, setEducationList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [selectedEducation, setSelectedEducation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEducationId, setSelectedEducationId] = useState("");

  useEffect(() => {
    setEducationList(educationData);
  }, [educationData]);

  const handleDelete = () => {
    setIsLoading(true);
    deleteApi(API_EMPLOYEE_EDUCATION + `/${selectedEducationId}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let newArray = educationList.filter(
            (item) => item.educationId !== selectedEducationId
          );
          setEducationList(newArray);
          setIsLoading(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-education-delete"
          )[0];
          modalClose.click();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleView = (item) => {
    setSelectedEducation(item);
  };
  const handleDeleteAlert = (id) => {
    setSelectedEducationId(id);
  };

  return (
    <>
      <div className="table-responsive">
        {educationList?.length === 0 && educationList && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {educationList?.length !== 0 && (
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>Degree</th>
                <th>College</th>
                <th>From</th>
                <th>To</th>
                <th>Actions</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {educationList &&
                educationList?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.highestDegree}</td>
                    <td>{item.college}</td>
                    <td>{moment(item.fromDate).format("DD-MM-yyyy")}</td>
                    <td>{moment(item.toDate).format("DD-MM-yyyy")}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleDeleteAlert(item.educationId)}
                        className="text-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropEducationDelete"
                      >
                        Delete
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleView(item)}
                        className="ms-2"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <button
        className="btn btn-primary mt-3 add-education"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropEducation"
      >
        + Add Education
      </button>

      <div
        className="modal fade"
        id="staticBackdropEducation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AddEducation
              setEducationList={setEducationList}
              selectedEducation={selectedEducation}
              setSelectedEducation={setSelectedEducation}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropEducationDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this education?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoading ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-education-delete"
                data-bs-dismiss="modal"
                onClick={() => setSelectedEducationId("")}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Education;
