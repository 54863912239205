import React, { useEffect, useState } from "react";
import {
  API_ASSET_EXPORT,
  API_TOTAL_ASSET,
  ASSET_TYPE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";

const AssetDashboard = ({ title, description }) => {
  const [assetList, setAssetList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [assetTypeList, setAssetTypeList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const accessToken = Cookies.get("accessToken");

  const ownerFilterOptionsDefaultValue = "ALL";
  const ownerFilterOptions = [
    { value: ownerFilterOptionsDefaultValue, label: "All" },
    { value: "Caelius", label: "Caelius" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "Consultant", label: "Consultant" },
    { value: "SQE", label: "SQE Labs" },
  ];

  const assetTypeFilterOptionsDefaultValue = 0;
  const assetTypeFilterOptions = assetTypeList.map((item) => ({
    value: item.assestId,
    label: item.name,
  }));
  assetTypeFilterOptions.unshift({
    value: assetTypeFilterOptionsDefaultValue,
    label: "All",
  });

  const [formData, setFormData] = useState({
    filterOwner: ownerFilterOptionsDefaultValue,
    filterAssetType: assetTypeFilterOptionsDefaultValue,
  });

  useEffect(() => {
    fetchAssetTypeList();
    handleFilter();
  }, []);

  const fetchAssetTypeList = async () => {
    setIsListLoading(true);
    await getApi(ASSET_TYPE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetTypeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assetList.filter((item) =>
      item.assestType.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFilter = async () => {
    setIsLoading(true);
    const owner = formData.filterOwner;
    const assetTypeId = formData.filterAssetType;

    try {
      await getApi(
        API_TOTAL_ASSET + `?owner=${owner}&assestTypeId=${assetTypeId}`,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setAssetList(res.data.data);
            setFilteredItems(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async () => {
    setIsExportLoading(true);
    await fetch(
      `${API_ASSET_EXPORT}?owner=${formData.filterOwner}&assestTypeId=${formData.filterAssetType}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/vnd.ms-excel",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          toast.error(" No Record Available!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `asset_list.xlsx`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
        setIsExportLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setIsExportLoading(false);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="wrapper-body">
      {isExportLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {filteredItems?.length > 0 && (
          <div className="total">Total :{filteredItems?.length}</div>
        )}
      </div>
      <div className="action-bar">
        {/* <form action="index.html" id="form_filters"> */}
        {/* Search By Company */}
        <div className="filters-wrapper">
          <div className="filter">
            <label className="label" htmlFor="filterAssetType">
              Asset Type
            </label>
            <div className="select">
              <select
                id="filterAssetType"
                value={formData.filterAssetType}
                required
                onChange={handleInputChange}
                name="filterAssetType"
                disabled={isListLoading}
              >
                {assetTypeFilterOptions.map((item, index) => {
                  const { label, value } = item;
                  return (
                    <option value={value} key={`asset-type-${index}`}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="filter">
            <label className="label" htmlFor="filterOwner">
              Owner
            </label>
            <div className="select">
              <select
                id="filterOwner"
                value={formData.filterOwner}
                required
                onChange={handleInputChange}
                name="filterOwner"
              >
                <option value="ALL" selected>
                  All
                </option>
                <option value="Caelius">Caelius</option>
                <option value="Salesforce">Salesforce</option>
                <option value="Consultant">Consultant</option>
                <option value="SQE">SQE Labs</option>
              </select>
            </div>
          </div>
        </div>
        <div className="actions-wrapper">
          <div className="actions">
            <button
              className="export theme-button bg-grey"
              type="button"
              onClick={handleFilter}
            >
              Filter
            </button>
          </div>
          <div className="actions">
            <a
              disabled={filteredItems?.length === 0}
              className="export theme-button"
              onClick={handleExport}
            >
              Export &nbsp;&nbsp;
              <img
                src="/assets/images/export-ico.svg"
                style={{ filter: "invert(1)" }}
                width="15"
                alt="Export"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {isLoading && (
            <div className="centered-loader">
              <ThreeDots
                height="100"
                width="100"
                radius="9"
                color="blue"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
          {/* {!isLoading && filteredItems?.length === 0 && (
            <div className="fs-4 text-secondary text-center">
              No Record Available
            </div>
          )} */}
          <div className="row asset-list-card mt-3">
            {!isLoading &&
              filteredItems?.length > 0 &&
              filteredItems.map((employee, index) => (
                <div className="col-md-3" key={index}>
                  <div className="card">
                    <div className="card-body text-center">
                      <h5 className="card-title text-secondary fw-bolder">
                        {employee.assestType}
                      </h5>
                      <h6 className="card-text fs-6">{employee.owner}</h6>
                      <span className="badge badge-info ">
                        Assigned: {employee.assigned}
                      </span>
                      <span className="badge badge-success mx-2">
                        Available : {employee.available}
                      </span>
                      <span className="badge badge-danger ">
                        Total : {employee.total}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {!isLoading && filteredItems?.length === 0 && (
              <div className="fs-4 text-secondary text-center">
                No Record Available
              </div>
            )}
          </div>
          {/* {!isLoading && filteredItems?.length > 0 && (
            <div className="table-responsive">
              <table className="resume custom"> */}
          {/* Table Headings */}
          {/* <thead>
                  <tr>
                    <th>Serial No.</th>
                    <th>Asset Type</th>
                    <th>Owner</th>
                    <th>Available</th>
                    <th>Assigned</th>
                    <th>Total</th>
                  </tr>
                </thead> */}

          {/* Table Body */}
          {/* <tbody>
                  {filteredItems &&
                    filteredItems?.map((item, index) => (
                      <tr key={index}>
                        <td data-title="Serial_No">{index + 1}</td>
                        <td>{item.assestType}</td>
                        <td>{item.owner}</td>
                        <td>{item.available}</td>
                        <td>{item.assigned}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AssetDashboard;
