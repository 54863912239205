import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import { API_FORM16, API_FORM16_DOWNLOAD } from "../../config/Endpoints";
import { getUser } from "../../utils/Storage";
import { TailSpin } from "react-loader-spinner";

const Form16 = () => {
  const [formList, setFormList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [selectedYear, setSelectedYear] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const userData = JSON.parse(getUser("userData"));

  const handleYearChnage = (e) => {
    setIsLoading(true);
    setSelectedYear(e.target.value);
    let value = e.target.value;
    getApi(API_FORM16 + `?year=` + value, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setFormList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleDownload = (e, item) => {
    e.preventDefault();
    setIsLoadingDownload(true);

    fetch(`${API_FORM16_DOWNLOAD}?form16Id=${item.id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoadingDownload(false);
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL object from the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Form16_${userData?.name}_${item.year}.pdf`;
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(url);
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setIsLoadingDownload(false);
      });
  };

  return (
    <div className="mt-4 mb-5 mx-2">
      {(isLoading || isLoadingDownload) && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="row">
        <h3 className="mb-3 fs-5 color-blue-grey">Form 16</h3>
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="comment" className="has-asterisk">
              Please select financial year{" "}
            </label>
            <select
              id="year"
              required
              name="yearSalary"
              onChange={handleYearChnage}
            >
              <option value="">Select Year</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
            </select>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3"></div>
        </div>
      </div>
      {!isLoading && isLoading !== null && (
        <div className="table-responsive mt-4">
          {formList?.length === 0 && formList && (
            <div className="fs-4 text-secondary text-center">
              No records available
            </div>
          )}
          {formList?.length !== 0 && (
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>PAN</th>
                  <th>Year</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {formList &&
                  formList?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.pan}</td>
                      <td>{item.year}</td>
                      <td>{item.documentType}</td>
                      <td>
                        <a
                          className="ms-3 fs-5 "
                          href="javascript:void(0)"
                          download
                          onClick={(e) => handleDownload(e, item)}
                        >
                          <i class="fa fa-download"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Form16;
