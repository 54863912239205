import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi, putApi } from "../../utils/api";
import {
  API_PROMOTE_EMPLOYEE,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const PromoteEmployee = ({title, description}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeSelect, setSelectedEmployeeSelect] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [designationId, setDesignationId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchList();
    fetchDepartmentList();
  }, []);

  const fetchList = () => {
    setIsListLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleChangeEmployee = async (e) => {
    let employeeId = e.value;
    setSelectedEmployeeId(employeeId);
    setSelectedEmployeeSelect(e);
    let data = employeeList.find((item) => item.id === Number(employeeId));
    setSelectedEmployee(data);

    let oldDepartment = departmentList?.find(
      (item) => item.name === data?.department
    );

    setDepartmentId(oldDepartment?.id);
    await handleDepartment(null, oldDepartment, data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      await putApi(
        API_PROMOTE_EMPLOYEE +
          "/" +
          selectedEmployeeId +
          `/promotions/${designationId}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          setSelectedEmployee(res?.data?.data?.personalDetail);
          setDepartmentId("");
          setDesignationId("");
          const modalClose =
            document.getElementsByClassName("cancel-promote")[0];
          modalClose.click();
          toast.success("Successfully Promoted!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handlePromote = async () => {
    let oldDepartment = departmentList?.find(
      (item) => item.name === selectedEmployee?.department
    );
    setDepartmentId(oldDepartment?.id);
    await handleDepartment(null, oldDepartment, selectedEmployee);
  };

  const fetchDepartmentList = () => {
    setIsDataLoading(true);
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
          setIsDataLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsDataLoading(false);
      });
  };

  const handleDepartment = (e, oldDepartment, employee) => {
    setIsDataLoading(true);
    setDepartmentId(e ? e.target.value : oldDepartment?.id);
    setDesignationList([]);
    getApi(
      GET_DESIGNATION_LIST +
        `?departmentId=${e ? e.target.value : oldDepartment?.id}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setDesignationList(res.data.data);
          let oldDesignation = res?.data?.data?.find(
            (item) => item.name === employee.designation
          );
          setDesignationId(oldDesignation?.id);
          setIsDataLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsDataLoading(false);
      });
  };

  const handleReset = () => {
    setSelectedEmployeeId("");
    setDepartmentId("");
    setDesignationId("");
    // setSelectedEmployee({});
  };

  const handleClose = () => {
    setDepartmentId("");
    setDesignationId("");
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Employee</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployeeSelect}
                    placeholder="Select employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>

      {Object.keys(selectedEmployee).length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            <thead>
              <tr>
                <th>Employee Id</th>
                <th>Name</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                
                <td>{selectedEmployee?.employeeId}</td>
                <td>
                  {selectedEmployee?.firstName} {selectedEmployee?.lastName}
                </td>
                <td>
                  {selectedEmployee?.department
                    ? selectedEmployee?.department
                    : "-"}
                </td>
                <td>
                  {selectedEmployee?.designation
                    ? selectedEmployee?.designation
                    : "-"}
                </td>
                <td>
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropPromote"
                    onClick={handlePromote}
                  >
                    Promote
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropPromote"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Promote Employee
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="department" className="has-asterisk">
                      Department
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      id="department"
                      className="border"
                      required
                      name="department"
                      value={departmentId}
                      onChange={handleDepartment}
                    >
                      <option value="">Select Department</option>
                      {departmentList &&
                        departmentList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="designation" className="has-asterisk">
                      Designation
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      id="designation"
                      className="border"
                      required
                      name="designation"
                      value={designationId}
                      disabled={designationList.length === 0}
                      onChange={(e) => setDesignationId(e.target.value)}
                    >
                      <option value="">Select Designation</option>
                      {designationList &&
                        designationList.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <center>
                      {designationList.length === 0 && isDataLoading && (
                        <ThreeDots
                          height="20"
                          width="50"
                          radius="2"
                          color="blue"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}
                    </center>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-promote"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PromoteEmployee;
