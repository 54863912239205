import React, { useEffect, useState } from "react";
import { getApi } from "../../utils/api";
import { ASSIGN_ASSET } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
import { toast } from "react-toastify";

const AssetList = ({ title, description }) => {
  const [assignAssetList, setAssignAssetList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();

  const accessToken = Cookies.get("accessToken");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalAssest, setTotalAssest] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(ASSIGN_ASSET + `?pageSize=${limit}&page=${currentPage}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssignAssetList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalAssest(res.data.totalAssest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(
          e?.response?.data?.message
            ? e?.response?.data?.message
            : "Something went wrong please try again !!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = assignAssetList.filter(
      (item) =>
        item.assestName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.empName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.serialNumber.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered.length > 0 ? filtered : null);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Asset Assigned : <span>{totalAssest}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Asset type, Serial Number or Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
            Search
          </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions">
          <Link to="/dashboard/assign-asset" className="export theme-button">
            Assign Asset
          </Link>
          {/* <Link to="/dashboard/create-asset" className="export theme-button">
            Create Asset
          </Link> */}
        </div>
      </div>

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={() => handleSort("owner")}>
                    Owner{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("assestName")}>
                    Asset Type{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("empName")}>
                    Employee{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("serialNumber")}>
                    Serial Number{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="Serial_No">
                        {(currentPage - 1) * limit + index + 1}
                      </td>
                      <td data-title="owner">{item.owner}</td>
                      <td data-title="Name">{item.assestName}</td>
                      <td data-title="empName">{item.empName}</td>
                      <td data-title="serialNumber">{item.serialNumber}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25 p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems === null && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available.</h4>
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          <h4>Assets are not allocated .</h4>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AssetList;
