import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import CreateEmployee from "./Pages/EmployeeOnboard/CreateEmployee";
import { getAuthToken } from "./utils/Storage";
import { ToastContainer } from "react-toastify";
import Login from "./Pages/Auth/Login";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Home from "./Pages/Home";

function App() {
  const { pathname } = useLocation();
  const path = String(pathname);

  function RequireAuth({ children, redirectTo }) {
    let accessToken = getAuthToken("accessToken");
    return accessToken ? <Navigate to={redirectTo} /> : children;
  }

  return (
    <>
      {path.includes("/dashboard") ? (
        <Home />
      ) : (
        <Routes>
          <Route path="*" exact element={<Navigate to="/" />} />
          <Route
            path="/"
            exact
            element={
              <RequireAuth redirectTo="/dashboard">
                <Login />
              </RequireAuth>
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/create-employee" element={<CreateEmployee />} />
        </Routes>
      )}
      <ToastContainer />
      <ScrollToTop />
    </>
  );
}

export default App;
