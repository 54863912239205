import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { deleteApi, getApi } from "../../utils/api";
import { API_POLICY, API_ROLE } from "../../config/Endpoints";
import { toggleMenu } from "../../utils/helpers";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import CreateRoleModal from "./CreateRoleModal";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const RoleList = ({title, description}) => {
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [selectedRole, setSelectedRole] = useState({});

  const accessToken = Cookies.get("accessToken");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRole, setTotalRole] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setisLoading(true);
    getApi(API_ROLE + `?pageSize=${limit}&page=${currentPage}`, accessToken)
      // getApi(API_ROLE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setRoleList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalRole(res.data.totalRoles);
          setTotalPages(res.data.totalPages);
          setisLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = roleList.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleViewDetails = (data) => {
    setSelectedRole(data);
  };

  const handleRoleDelete = (id) => {
    setSelectedRoleId(id);
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    deleteApi(API_ROLE + `/${selectedRoleId}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          let newArray = roleList.filter(
            (item) => item.roleId !== selectedRoleId
          );
          setRoleList(newArray);
          setTotalRole(totalRole - 1);
          setFilteredItems(newArray);
          setIsLoadingDelete(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-dependent-delete"
          )[0];
          modalClose.click();
          toast.success(
            res.data.message ? res.data.message : "Successfully Deleted.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((e) => {
        toast.error(e?.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoadingDelete(false);
      });
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Role : <span>{totalRole}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Role Title"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* Actions */}
        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropRole"
          >
            + Add Role
          </Link>
        </div>
      </div>

      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}

      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      {/* <td data-title="roleId">{item.roleId}</td> */}
                      <td data-title="title">{item.name}</td>
                      <td data-title="description" className="w-50">
                        {item.description}
                      </td>

                      <td className="text-center">
                        <a
                          href="javascript:void(0)"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropRole"
                          onClick={() => handleViewDetails(item)}
                          className="fw-bolder"
                        >
                          Edit
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleRoleDelete(item.roleId)}
                          className="ms-3 text-danger fw-bolder"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropDependentDelete"
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="staticBackdropRole"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <CreateRoleModal
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropDependentDelete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">
                Are you sure you want to delete this role?
              </h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoadingDelete ? "theme-button disabled " : "theme-button "
                }
                onClick={handleDelete}
              >
                {isLoadingDelete ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-dependent-delete"
                data-bs-dismiss="modal"
                onClick={() => {
                  setSelectedRoleId("");
                  setIsLoadingDelete(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default RoleList;
