import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import {
  API_EMPLOYEE_REIMBURSEMENT_LIST,
  API_REIMBURSEMENT_LIST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import PageTitle from "../../components/PageTitle";

const ReimbursementHistory = ({title, description}) => {
  const [reimbursementList, setReimbursementList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const [employeeList, setEmployeeList] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalReimbursement, setTotalReimbursement] = useState(0);
  const accessToken = Cookies.get("accessToken");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchListEmployee();
  }, []);

  const fetchListEmployee = () => {
    setIsLoading(true);
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchListReimbursement();
  }, [currentPage, limit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setCurrentPage(1);
      getApi(
        API_EMPLOYEE_REIMBURSEMENT_LIST +
          `?empId=${selectedEmployee.value}&status=${selectedStatus?.value}&pageSize=${limit}&page=${currentPage}`,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setReimbursementList(res.data.data);
            setFilteredItems(res.data.data);
            setTotalReimbursement(res.data.totalRequest);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchListReimbursement = async () => {
    try {
      setIsLoading(true);
      getApi(
        API_EMPLOYEE_REIMBURSEMENT_LIST +
          `?empId=${selectedEmployee.value}&status=${selectedStatus?.value}&pageSize=${limit}&page=${currentPage}`,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setReimbursementList(res.data.data);
            setFilteredItems(res.data.data);
            setTotalReimbursement(res.data.totalRequest);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (key === "requestDate" || key === "date") {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        if (dateA < dateB) {
          return direction === "ascending" ? -1 : 1;
        }
        if (dateA > dateB) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Reimbursement : <span>{totalReimbursement}</span>
        </div>
      </div>

      <div className="card">
        <form id="assign-asset">
          <div className="inputs">
            <div className="row">
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={(option) => {
                      setSelectedEmployee(option);
                      setSearchTerm("");
                    }}
                    value={selectedEmployee}
                    placeholder="Select employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <Select
                  id="status"
                  required
                  name="status"
                  value={selectedStatus}
                  placeholder="Select status"
                  onChange={(option) => {
                    setSelectedStatus(option);
                    setSearchTerm("");
                  }}
                  options={[
                    { value: "PENDING_ADMIN", label: "Pending Admin" },
                    { value: "PENDING_FINANCE", label: "Pending  Finance" },
                    { value: "DISCREPANCY", label: "Discrepancy" },
                    { value: "PAID", label: "Paid" },
                    { value: "WITHDRAW", label: "Withdrawn" },
                    { value: "APPROVED", label: "Approved" },
                    { value: "REJECTED", label: "Rejected" },
                  ]}
                />
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="theme-button"
                  onClick={handleSubmit}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>
                    S.No.
                    {/* <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("reimbursementViewId")}
                    /> */}
                  </th>
                  <th>
                    Reimbursement Type{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("reimbursementType")}
                    />
                  </th>
                  <th>
                    Requested Date{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("requestDate")}
                    />
                  </th>
                  <th>
                    Invoice Date{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      onClick={() => handleSort("date")}
                      height={18}
                    />
                  </th>
                  <th>
                    Invoice No.{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("invoiceNo")}
                    />
                  </th>
                  <th>
                    Amount{" "}
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("reimbursementAmount")}
                    />
                  </th>
                  {/* <th>Attachment</th> */}
                  <th>
                    Status{" "}
                    {/* <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("trackingStatus")}
                    /> */}
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td data-title="reimbursementViewId">
                        {(currentPage - 1) * limit + index + 1}
                      </td>
                      <td data-title="name">{item.reimbursementType}</td>
                      <td data-title="requestDate">{item.requestDate}</td>
                      <td data-title="date">{item.date}</td>
                      <td data-title="invoiceNo">{item.invoiceNo}</td>
                      <td
                        data-title="reimbursementAmount"
                        className="text-right"
                      >
                        {item.reimbursementAmount.toLocaleString("en-IN")}
                      </td>

                      <td data-title="trackingStatus">{item.trackingStatus}</td>
                      <td data-title="download">
                        {item.filePath ? (
                          <a
                            href={
                              process.env.REACT_APP_IMAGE_URL + item.filePath
                            }
                            download
                            target="_blank"
                          >
                            Download
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>

                      {/* <td data-title="action">
                        {item.trackingStatus === "Discrepancy" ||
                        item.trackingStatus === "Pending-Admin" ? (
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropAssetwithdraw"
                            // onClick={() => {
                            //   //   setReimbursementId(item.reimbursementId);
                            //   setSelectedReimbursementRequest(item);
                            // }}
                          >
                            Withdraw
                          </a>
                        ) : (
                          <p className="ms-2">-</p>
                        )}
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          <h4>No Record Available</h4>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ReimbursementHistory;
