import React, { useEffect, useState } from "react";
import { API_ASSET, API_ASSET_USER } from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";

const EmployeeAsset = ({ assestData, employeeId }) => {
  const [assetList, setAssetList] = useState(assestData ? assestData : []);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalAssest, setTotalAssest] = useState(0);
  const accessToken = Cookies.get("accessToken");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit]);

  const fetchList = () => {
    setIsLoading(true);
    setAssetList([]);
    const url = employeeId
      ? API_ASSET +
        `?pageSize=${limit}&page=${currentPage}&employeeId=${employeeId}`
      : API_ASSET_USER + `?pageSize=${limit}&page=${currentPage}`;

    getApi(url, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAssetList(res.data.data);
          setTotalAssest(res.data.totalAssest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary mt-3 add-dependent text-right"
          onClick={fetchList}
          disabled={isLoading}
        >
          Refresh
        </button>
      </div>
      <div className="table-responsive mt-4">
        {assetList?.length === 0 && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {assetList?.length > 0 && (
          <>
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Manufacture</th>
                  <th>Asset Name</th>
                  <th>Serial Number</th>
                  <th>Owner</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {assetList &&
                  assetList?.map((asset, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      {/* <td>{asset.assignedAssestId}</td> */}
                      <td>{asset.manufacture}</td>
                      <td>{asset.assestName}</td>
                      <td>{asset.serialNumber}</td>
                      <td>{asset.owner}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-3">
                <div>
                  {/* Dropdown for selecting items per page */}
                  <label htmlFor="itemsPerPage">Items per page </label>
                  <select
                    id="itemsPerPage"
                    onChange={handleLimitChange}
                    value={limit}
                    className="border ms-3 w-25 p-2"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                  </select>
                </div>
              </div>
              <div className="col-md-9">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EmployeeAsset;
