import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select, { components } from "react-select";
import {
  API_ADD_LEAVE_CREDIT_REQUEST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { getApi, putApi } from "../../utils/api";
import { toast } from "react-toastify";

const AddLeaveCreditRequest = ({ updateList }) => {
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const leaveType = [
    { value: "PrivilegeLeave", label: "Privilege Leave" },
    // { value: "HalfYearly", label: "Half Yearly" },
    // { value: "PaidLeave", label: "Paid Leave" },
    { value: "WorkFromHome", label: "Work From Home" },
    { value: "MATERNITY", label: "Maternity" },
    { value: "PATERNITY", label: "Paternity" },
  ];

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const [formData, setFormData] = useState({
    noOfdays: null,
    typeOfLeave: "",
    comment: "",
  });

  const handleClose = () => {
    setFormData({
      noOfdays: "",
      typeOfLeave: "",
      comment: "",
    });
    setSelectedEmployee("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleKeyUp = (e) => {
    if (e.key === "-" || e.key < "0" || e.key > "9") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        API_ADD_LEAVE_CREDIT_REQUEST +
          `?employeeId=${selectedEmployee.value}&noOfdays=${formData?.noOfdays}&typeOfLeave=${formData?.typeOfLeave}&comment=${formData?.comment}`,
        {},
        accessToken
      )
        .then(async (res) => {
          setIsLoading(false);
          const modalClose =
            document.getElementsByClassName("cancel-promote")[0];
          modalClose.click();
          toast.success(
            res?.data?.message ? res?.data?.message : "Successfully Created.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          await updateList();
          await handleClose();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          Add Request
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label className="has-asterisk">Select Employee</label>
          </div>
          <div className="col-md-4">
            <div className="dropdown-container">
              <Select
                options={optionList}
                components={{ Option }}
                onChange={(selectedOption) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    selectedEmployee: selectedOption.value,
                  }));
                  setSelectedEmployee(selectedOption);
                }}
                value={selectedEmployee}
                placeholder="Choose"
                isSearchable={true}
                required
              />
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="noOfdays" className="has-asterisk">
              Leave Count
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="number"
              name="noOfdays"
              value={formData.noOfdays}
              onChange={handleInputChange}
              required
              className="border"
              min={0}
              step="0.01"
              onKeyUp={handleKeyUp}
              pattern="[0-9]+(\.[0-9]{1,2})?"
              onKeyPress={(e) => {
                const isDigit = /\d/.test(e.key);
                const isDecimal = e.key === ".";
                const currentValue = e.target.value;

                // Prevent non-digits and non-decimal points
                if (!isDigit && !isDecimal) {
                  e.preventDefault();
                }

                // Prevent multiple decimal points
                if (isDecimal && currentValue.includes(".")) {
                  e.preventDefault();
                }

                // Prevent more than two decimal places
                if (
                  isDigit &&
                  currentValue.includes(".") &&
                  currentValue.split(".")[1].length >= 2
                ) {
                  e.preventDefault();
                }

                // Prevent values greater than 25
                if (isDigit || isDecimal) {
                  const newValue = parseFloat(currentValue + e.key);
                  if (newValue > 25) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="typeOfLeave" className="has-asterisk">
              Leave Type
            </label>
          </div>
          <div className="col-md-4">
            <select
              id="typeOfLeave"
              className="border"
              required
              name="typeOfLeave"
              value={formData.typeOfLeave}
              onChange={handleInputChange}
            >
              <option value="">Select Leave Type</option>
              {leaveType &&
                leaveType.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="comment" className="has-asterisk">
              Comment
            </label>
          </div>
          <div className="col-md-4">
            <textarea
              rows={3}
              cols={5}
              className="border"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
              required
              maxLength={200}
              minLength={5}
              onKeyPress={(e) => {
                // Prevent adding space at the beginning
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault();
                }

                // Regular expression to allow only letters, numbers, and spaces
                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                // Check if the character is allowed
                const char = String.fromCharCode(e.charCode);
                if (!allowedCharsRegex.test(char)) {
                  e.preventDefault(); // Prevent non-alphanumeric character input
                }
              }}
            ></textarea>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-promote"
          data-bs-dismiss="modal"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddLeaveCreditRequest;
