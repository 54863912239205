const baseURL = process.env.REACT_APP_API_BASE_URL;

export const SIGN_IN = baseURL + "/api/auth/signin";
export const SIGN_UP = baseURL + "/api/auth/signup";
export const MENU = baseURL + "/menu";
export const MENU_USER = baseURL + "/menu/v1/users";
// Menu API
export const MENU_ALL = baseURL + "/menu/v1/all";
export const MENU_FIND = baseURL + "/menu/v1/find";
export const MENU_ASSIGNMENT = baseURL + "/menu/v1/manage/assignment";

export const EMPLOYEE_LIST = baseURL + "/user";
export const USER_PROFILE = baseURL + "/user/profile";

export const LEAVE_LIST = baseURL + "/leave";
export const APPLY_LEAVE = baseURL + "/leave/leaveApply";
export const LEAVE_TYPE = baseURL + "/leave/types";
export const LEAVE_BALANCE = baseURL + "/leave/masterleave";
export const LEAVE_APPROVER = baseURL + "/leave/leave/approver";

export const LEAVE_TYPE_V2 = baseURL + "/leave/v2/types";
export const LEAVE_APPROVE_HR = baseURL + "/leave/leave/approver/hr";
export const LEAVE_BULK_APPROVE_HR = baseURL + "/leave/leave/bulk/approver/hr";
export const LEAVE_APPROVE_MANAGER = baseURL + "/leave/leave/approver/manager";
export const API_LEAVE_WITHDRAW = baseURL + "/leave/leave/withdraw";
export const API_MY_ATTANDANCE = baseURL + "/leave/emp/attandance";
export const API_EXPORT_ATTANDANCE = baseURL + "/leave/export/attandance";

export const API_LEAVE_TODAY = baseURL + "/leave/emp/today";
export const API_POLICY = baseURL + "/policy";
export const API_VALIDATE_TOKEN = baseURL + "/api/auth/validate";
export const GET_ONBOARDING_EMPLOYEE_LIST = baseURL + "/onboarding";
export const GET_INVITATION_ONBOARDING_EMPLOYEE_LIST =
  baseURL + "/onboarding/invitations";

export const GET_ONBOARDING_EMPLOYEE_LIST_PAGINATION =
  baseURL + "/onboarding/paged";
export const GET_EMPLOYEE_LIST = baseURL + "/user";
export const GET_DEPARTMENT_LIST = baseURL + "/onboarding/departmentlist";
export const GET_DESIGNATION_LIST = baseURL + "/onboarding/designationlist";
export const UPDATE_CC_EMAIL = baseURL + "/onboarding/updateEmail";
export const API_UPDATE_EMPLOYEE = baseURL + "/onboarding/update";
export const API_USER_UPDATE_EMPLOYEE = baseURL + "/user/profile/update";

// V2 APIs
export const API_V2_UPDATE_CC_EMAIL = baseURL + "/onboarding/v2/updateEmail";

export const UPLOAD_USER_PHOTO = baseURL + "/user/photo";
export const ADMIN_UPLOAD_USER_PHOTO = baseURL + "/user/admin/photo/upload";
export const API_PROMOTE_EMPLOYEE = baseURL + "/user/employees";
export const API_PERFORMANCE_EMPLOYEE = baseURL + "/performance/employee";
export const API_PERFORMANCE = baseURL + "/performance";
export const API_PERFORMANCE_Evaluation = baseURL + "/performance/hr";
export const API_PERFORMANCE_POINTS = baseURL + "/performance/points";
export const API_PERFORMANCE_VIEW = baseURL + "/performance/view";
export const API_PERFORMANCE_EXPORT = baseURL + "/performance/export";

export const ASSIGN_ASSET = baseURL + "/assest";
export const ALLOCATE_ASSET = baseURL + "/assest/allocate";
export const ASSET_LIST = baseURL + "/assest/availableAssest";
export const DEALLOCATE_ASSET = baseURL + "/assest/deallocate";
export const ASSET_TYPE_LIST = baseURL + "/assest/list";
export const CREATE_ASSET = baseURL + "/assest/new";
export const BULK_CREATE_ASSET = baseURL + "/assest/bulk/new";
export const CREATE_ASSET_TYPE = baseURL + "/assest/create/category";
export const API_TOTAL_ASSET = baseURL + "/assest/total";
export const API_REQUEST_ASSET = baseURL + "/assest/requestAssest";
export const API_REQUEST_ASSET_LIST = baseURL + "/assest/assestRequestList";
export const API_APPROVE_REQUEST = baseURL + "/assest/approveRequest";
export const API_ASSET_EXPORT = baseURL + "/assest/export";
export const API_APPROVER_ASSEST_REQUEST_LIST =
  baseURL + "/assest/approverAssestRequestList";
export const API_ASSET_USER = baseURL + "/assest/my";
export const API_ASSET = baseURL + "/assest";

export const API_EXPERIENCE = baseURL + "/experience";

export const API_EMPLOYEE_EDUCATION = baseURL + "/education";
export const API_EMPLOYEE_DEPENDENT = baseURL + "/dependent";
export const API_EMPLOYEE_DOCUMENT = baseURL + "/document";
export const API_DOCUMENT_STATUS = baseURL + "/document/v1/list/status";
export const API_DOCUMENT = baseURL + "/document";
export const API_DOCUMENT_VERSION = baseURL + "/document/v2/upload";
export const API_DOCUMENT_LIST_APPROVER =
  baseURL + "/document/v1/list/for-approver";
export const API_DOCUMENT_APPROVER = baseURL + "/document/v1/approve";

export const API_SALARY = baseURL + "/salary";
export const API_UPLOAD_SALARY = baseURL + "/salary/salaryupload";
export const API_DOWNLOAD_SALARY = baseURL + "/salary/downloadFile";

export const API_FORGOT_PASSWORD = baseURL + "/password";
export const API_VERIFY_OTP = baseURL + "/password/verifyOTP";
export const API_CHANGE_PASSWORD = baseURL + "/newPassword";
export const API_RESET_PASSWORD = baseURL + "/password/changePassword";

export const API_REIMBURSEMENT = baseURL + "/reimbursement";
export const API_APPROVE_REIMBURSEMENT_LIST =
  baseURL + "/reimbursement/getApproveReimbursementList";
export const API_REIMBURSEMENT_LIST =
  baseURL + "/reimbursement/reimbursementList";
export const API_EMPLOYEE_REIMBURSEMENT_LIST = baseURL + "/reimbursement/list";

export const API_APPROVE_REIMBURSEMENT =
  baseURL + "/reimbursement/approvereimbursement";
export const API_REIMBURSEMENT_WITHDRAW =
  baseURL + "/reimbursement/withdrawReimbursement";
export const API_REIMBURSEMENT_EXPORT = baseURL + "/reimbursement/export";
export const API_REIMBURSEMENT_BULK_UPLOAD =
  baseURL + "/reimbursement/reimbursements/upload";

export const API_PUNCH = baseURL + "/punch";
export const API_ANNOUNCEMENT_DASHBOARD =
  baseURL + "/announcement/dashboard/list";

export const API_ROLE = baseURL + "/roles";

export const API_UPLOAD_APPRAISAL = baseURL + "/appraisal/upload";
export const API_APPRAISAL = baseURL + "/appraisal";
export const API_DOWNLOAD_APPRAISAL = baseURL + "/appraisal/downloadFile";

export const API_FORM16 = baseURL + "/form16";
export const API_FORM16_DOWNLOAD = baseURL + "/form16/downloadFile";

export const API_ROLE_MAPPING = baseURL + "/roles/mapping";
export const API_ROLE_MAPPING_USER = baseURL + "/roles/mapping/v1/user";

export const API_GET_EMAIL_REQUEST = baseURL + "/adhoc/email/list";
export const API_CREATE_EMAIL_REQUEST = baseURL + "/adhoc/email/create";
export const API_UPDATE_EMAIL_REQUEST = baseURL + "/adhoc/email/update";

export const API_UPDATE_EMPLOYEE_TYPE =
  baseURL + "/user/v1/employment-type/update";
export const API_UPDATE_EMPLOYEE_ACCESS = baseURL + "/user/v1/access/update";

export const API_V2_APPROVER_ASSEST_REQUEST_LIST =
  baseURL + "/assest/v2/approverAssestRequestList";

export const API_V2_APPROVE_REQUEST = baseURL + "/assest/v2/approveRequest";

export const API_USER_ROLE = baseURL + "/user/with-roles";

export const API_V1_TOTAL_ASSET = baseURL + "/assest/v1/list-all";
export const API_ASSET_RETURN = baseURL + "/asset/return/upload";
export const API_ASSET_RETURNED_LIST = baseURL + "/asset/return/list";

export const API_ANNOUNCEMENT = baseURL + "/announcement";
export const API_ANNOUNCEMENT_UPDATE = baseURL + "/announcement/update";
export const API_ANNOUNCEMENT_ATTACHMENT = baseURL + "/announcement/attachment";

export const API_CREATE_DEPARTMENT = baseURL + "/department/create";
export const API_UPDATE_DEPARTMENT = baseURL + "/department/update";

export const API_CREATE_DESIGNATION = baseURL + "/designation/create";
export const API_UPDATE_DESIGNATION = baseURL + "/designation/update";

// Biometric
export const API_BIOMETRIC_CREATE = baseURL + "/biometric/mapping/create";
export const API_BIOMETRIC_UPDATE = baseURL + "/biometric/mapping/update";
export const API_BIOMETRIC = baseURL + "/biometric/";

export const API_LEAVE_MANAGER = baseURL + "/user/v1/leave-manager";
export const API_ASSIGN_LEAVE_MANAGER =
  baseURL + "/user/v1/leave-manager/assign";
export const API_REMOVE_LEAVE_MANAGER =
  baseURL + "/user/v1/leave-manager/remove";

// Leave credit request
export const API_LEAVE_CREDIT_REQUEST = baseURL + "/leave-credit/v1/request";
export const API_ADD_LEAVE_CREDIT_REQUEST =
  baseURL + "/leave-credit/v1/request/add";
export const API_WITHDRAWAL_LEAVE_CREDIT_REQUEST =
  baseURL + "/leave-credit/v1/request/withdrawal";
export const API_APPROVE_LEAVE_CREDIT_REQUEST =
  baseURL + "/leave-credit/v1/request/approve";
export const API_REJECT_LEAVE_CREDIT_REQUEST =
  baseURL + "/leave-credit/v1/request/reject";

// Holiday
export const API_HOLIDAY = baseURL + "/holiday";
export const API_HOLIDAY_ADD = baseURL + "/holiday/add";
export const API_HOLIDAY_UPDATE = baseURL + "/holiday/update";
export const API_HOLIDAY_DELETE = baseURL + "/holiday/delete";

//Regularization
export const API_REGULARIZATION =
  baseURL + "/attendance/regularization/v1/apply";
export const API_REGULARIZATION_GET =
  baseURL + "/attendance/regularization/v1/get";
export const API_REGULARIZATION_WITHDRAW =
  baseURL + "/attendance/regularization/v1/withdraw";

// HR Approval
export const API_REGULARIZATION_HR_APPROVE =
  baseURL + "/attendance/regularization/v1/hr/approve";
export const API_REGULARIZATION_HR_LIST =
  baseURL + "/attendance/regularization/v1/hr";

// Manager Approval
export const API_REGULARIZATION_MANAGER_APPROVE =
  baseURL + "/attendance/regularization/v1/manager/approve";
export const API_REGULARIZATION_MANAGER_LIST =
  baseURL + "/attendance/regularization/v1/manager";

export const API_LEAVE_ATTANDANCE_COMPILE_LIST =
  baseURL + "/leave/emp/attandance/compile";
