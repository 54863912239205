import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getApi, putApi } from "../../utils/api";
import { LEAVE_APPROVE_MANAGER } from "../../config/Endpoints";
import { ThreeDots } from "react-loader-spinner";
import { formatLeaveType, toggleMenu } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import { getUser } from "../../utils/Storage";
import { toast } from "react-toastify";
import TruncateText from "../../components/TruncateText";
import CustomDataTable from "../../components/CustomDataTable";
import * as XLSX from "xlsx";
import PageTitle from "../../components/PageTitle";

const ApproveLeave = ({title, description}) => {
  const [leaveList, setLeaveList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [selectedLeave, setSelectedLeave] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [leaveAction, setLeaveAction] = useState("");
  const [reason, setReason] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalLeaves, setTotalLeaves] = useState(0);
  const userData = JSON.parse(getUser("userData"));

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      LEAVE_APPROVE_MANAGER +
        `?status=${selectedStatus}&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data);
          setData(res.data?.data);
          setFilteredItems(res.data?.data);
          setTotalPages(res.data?.totalPages);
          setTotalLeaves(res.data?.totalLeave);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setReason("");
    setLeaveAction("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        LEAVE_APPROVE_MANAGER +
          `?leaveId=${selectedLeave.laveId}&Status=${leaveAction}`,
        { comment: reason },
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          let dataRes = res?.data;
          // setSelectedEmployeeLeaveData(dataRes);
          const modalClose = document.getElementsByClassName("cancel-leave")[0];
          modalClose.click();
          toast.success(dataRes.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // const handleSearch = (e) => {
  //   const searchValue = e.target.value;
  //   setSearchTerm(searchValue);
  //   const filtered = leaveList.filter((item) =>
  //     item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setFilteredItems(filtered);
  // };

  const handleSearch = (e) => {
    const searchValue = e.target.value; // Get the raw input value
    setSearchTerm(searchValue);

    // Normalize the search value by removing spaces
    const normalizedSearchValue = searchValue.replace(/\s+/g, "").toLowerCase();

    const filtered = leaveList.filter((item) => {
      // const typeOfLeave = item.typeOfLeave.toLowerCase().replace(/\s+/g, ""); // Remove spaces for comparison
      const empName = item.employeeName.toLowerCase().replace(/\s+/g, ""); // Remove spaces for comparison
      return (
        // typeOfLeave.includes(normalizedSearchValue) ||
        empName.includes(normalizedSearchValue)
      );
    });

    setFilteredItems(filtered);
  };

  // const data = [
  //   { id: 1, name: "John Doe", age: 28 },
  //   { id: 2, name: "Jane Smith", age: 34 },
  //   { id: 3, name: "Smith", age: 34 },
  //   { id: 4, name: "Jane", age: 34 },
  //   { id: 5, name: "Jai", age: 34 },
  //   { id: 6, name: "Raj", age: 34 },
  //   { id: 7, name: "Sam", age: 34 },
  //   { id: 8, name: "Joe", age: 34 },
  //   { id: 9, name: "Aeen", age: 34 },
  //   { id: 10, name: "Sara", age: 34 },
  //   // Add more data as needed
  // ];

  const columns = [
    { accessor: "laveId", label: "Leave ID" },
    { accessor: "startDate", label: "Start Date" },
    { accessor: "endDate", label: "End Date" },
    { accessor: "reasonOfLeave", label: "Reason of Leave" },
    { accessor: "typeOfLeave", label: "Type of Leave" },
    { accessor: "noOfDays", label: "Number of Days" },
    { accessor: "employeeName", label: "Employee Name" },
    { accessor: "managerStatus", label: "Manager Status" },
    { accessor: "approverStatus", label: "Approver Status" },
    { accessor: "leaveFinalStatus", label: "Final Status" },
    { accessor: "approverName", label: "Approver Name" },
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("my-team-leave")
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${"My_Team_Leave" + selectedStatus}.xlsx`);
  };
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Leaves : <span>{totalLeaves}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSearchTerm("");
              setSelectedStatus(e.target.value);
            }}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
            <option value="WITHDRAW">Withdrawn</option>
          </select>
        </div>
        {/* Actions */}
        <div className="actions ms-2">
          <button
            className={
              "export theme-button" +
              (totalPages === 0 || filteredItems?.length === 0
                ? " disabled"
                : "")
            }
            onClick={exportToExcel}
            disabled={totalPages === 0 || filteredItems?.length === 0}
          >
            Export
          </button>
        </div>
      </div>

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom" id="my-team-leave">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Employee</th>
                  {/* <th>Leave ID</th> */}
                  <th>From</th>
                  <th>To</th>
                  <th>Reason</th>
                  <th>Type</th>
                  <th> Leave Count</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    <td>{item.employeeName}</td>
                    {/* <td>{item.laveId}</td> */}
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td style={{ maxWidth: "250px" }}>
                      <TruncateText text={item.reasonOfLeave} />
                    </td>
                    <td>{formatLeaveType(item.typeOfLeave)}</td>
                    <td>{item.noOfDays}</td>

                    <td>
                      {item.managerStatus === "WITHDRAW"
                        ? "WITHDRAWN"
                        : item.managerStatus}
                    </td>
                    <td>
                      {item.managerStatus === "PENDING" ? (
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropLeave"
                          onClick={() => setSelectedLeave(item)}
                        >
                          View
                        </a>
                      ) : (
                        <p className="text-muted">-</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          No Record Available
        </div>
      )}

      {/* <CustomDataTable data={data} columns={columns} itemsPerPage={5} /> */}

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropLeave"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Leave Approval
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="resume custom">
                    {/* Table Headings */}
                    <thead>
                      <tr>
                        <th>Employee</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Reason</th>
                        <th>Type</th>
                        <th> Leave Count</th>
                      </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                      <tr>
                        <td>{selectedLeave.employeeName}</td>
                        <td>{selectedLeave.startDate}</td>
                        <td>{selectedLeave.endDate}</td>
                        <td>{selectedLeave.reasonOfLeave}</td>
                        <td>{formatLeaveType(selectedLeave.typeOfLeave)}</td>
                        <td>{selectedLeave.noOfDays}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="leaveAction" className="has-asterisk">
                      Action
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      id="leaveAction"
                      className="border"
                      required
                      name="leaveAction"
                      value={leaveAction}
                      onChange={(e) => setLeaveAction(e.target.value)}
                    >
                      <option value="">Select Action</option>
                      <option value="APPROVED">Approve</option>
                      <option value="REJECTED">Reject</option>
                    </select>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="reason" className="has-asterisk">
                      Reason
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      value={reason}
                      rows={3}
                      cols={5}
                      required
                      className="border"
                      onChange={(e) => setReason(e.target.value)}
                      // onKeyPress={(e) => {
                      //   // Prevent adding space at the beginning
                      //   if (
                      //     e.charCode === 32 &&
                      //     e.target.selectionStart === 0
                      //   ) {
                      //     e.preventDefault();
                      //   }

                      //   // Regular expression to allow only letters, numbers, and spaces
                      //   const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                      //   // Check if the character is allowed
                      //   const char = String.fromCharCode(e.charCode);
                      //   if (!allowedCharsRegex.test(char)) {
                      //     e.preventDefault(); // Prevent non-alphanumeric character input
                      //   }
                      // }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-leave"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveLeave;
