import React, { useState } from "react";
import {
  API_UPLOAD_APPRAISAL,
  API_UPLOAD_SALARY,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi } from "../../utils/api";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const AppraisalUpload = ({title, description}) => {
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    employeeType: "",
    month: "",
    document: null,
  });
  const [isLoading, setIsLoading] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("excelFile", formData.document);

      await postApi(API_UPLOAD_APPRAISAL, formdata, accessToken)
        .then((res) => {
          setIsLoading(false);
          setFormData({
            employeeType: "",
            document: "",
            month: "",
          });
          toast.success("Successfully Uploaded!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          document.getElementById("file-input").value = "";
        })
        .catch((e) => {
          setIsLoading(false);

          toast.error(
            e?.response?.data.message
              ? e?.response?.data.message
              : "Something went wrong. Please try again!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      setIsLoading(false);

      toast.error(
        error?.response?.data.message
          ? error?.response?.data.message
          : "Something went wrong. Please try again!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const handleInputChange = (event) => {
    const { name, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (file) {
        if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type, Please select a valid Excel(.xlsx) file only.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    }
  };

  const handleReset = () => {
    setFormData({
      employeeType: "",
      document: null,
      month: "",
    });
    document.getElementById("file-input").value = "";
  };
  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {/* <div className="total">
          <Link to="/dashboard/assetAllocation" className="brand-color">
            Back to Assigned Asset List
          </Link>
        </div> */}
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Upload Appraisal</h3>
          <div className="inputs">
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="document" className="has-asterisk">
                  Document
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="file"
                    name="document"
                    id="file-input"
                    onChange={handleInputChange}
                    required
                    accept=".xlsx"
                  />
                  <small className="text-danger pt-0">
                    Note: Please upload <strong>.xlsx</strong> file only
                  </small>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default AppraisalUpload;
