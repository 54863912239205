import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ASSIGN_ASSET,
  DEALLOCATE_ASSET,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi, postApi } from "../../utils/api";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const AssetDeallocation = ({title, description}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [allocatedAssetList, setAllocatedAssetList] = useState([]);
  const [selectedAssetId, setSelectedAssetId] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [formData, setFormData] = useState({
    employeeId: "",
    assetCondition: "",
    repairCost: "",
    comment: "",
  });
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsListLoading(true);
    fetchList();
  }, []);

  const fetchList = () => {
    getApi(GET_EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsListLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsListLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeEmployeeType = async (e) => {
    setIsAssetLoading(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      employeeId: e.value,
    }));
    setAllocatedAssetList([]);
    setSelectedEmployee(e);
    if (e.value === "") {
      setAllocatedAssetList([]);
      setIsAssetLoading(false);
    } else {
      await getApi(ASSIGN_ASSET + `?employeeId=${e.value}`, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setAllocatedAssetList(res.data.data);
          } else {
            setAllocatedAssetList(res.data.data);
          }
          setIsAssetLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsAssetLoading(false);
        });
    }
  };

  const fetchEmployeeAssignAsset = (id) => {
    getApi(ASSIGN_ASSET + `?employeeId=${id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setAllocatedAssetList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeAsset = (e, id) => {
    setSelectedAssetId();
    const assetId = Number(id);
    setSelectedAssetId(assetId);
    const modalClose = document.getElementsByClassName(
      "open-modal-deallocation"
    )[0];
    modalClose.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let requestData = {
        assestCondition: formData.assetCondition,
        assignedAssestId: selectedAssetId,
        comment: formData.comment,
        repairCost: formData.repairCost,
      };
      await postApi(DEALLOCATE_ASSET, requestData, accessToken)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            fetchEmployeeAssignAsset(formData.employeeId);
            setFormData((prevFormData) => ({
              ...prevFormData,
              assetCondition: "",
              repairCost: "",
              comment: "",
            }));

            setSelectedAssetId("");
            toast.success("Successfully deallocated!", {
              position: toast.POSITION.TOP_RIGHT,
            });

            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          } else {
            setIsLoading(false);
            setError("Something went wrong. Please try again!");
          }
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
          setError("Something went wrong. Please try again!");
          setTimeout(() => {
            setIsError(false);
          }, 4000);
        });
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
      setError("Something went wrong. Please try again!");
      setTimeout(() => {
        setIsError(false);
      }, 4000);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      assetCondition: "",
      repairCost: "",
      comment: "",
    }));
    // setSelectedEmployee("");
    setSelectedAssetId();
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data, isSelected } = props;
    const { value, label, username, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>
            {username} ({type})
          </span>
        </div>
      </components.Option>
    );
  };
  const handleKeyUp = (e) => {
    if (e.key === "-" || e.key < "0" || e.key > "9") {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper-body">
      {isListLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Asset Management</h3>
          <div className="d-f f-w inputs">
            <div className="input-group">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="dropdown-container">
                    <Select
                      options={optionList}
                      components={{ Option }}
                      onChange={handleChangeEmployeeType}
                      value={selectedEmployee}
                      placeholder="Select employee"
                      isSearchable={true}
                      isDisabled={employeeList?.length === 0}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {allocatedAssetList.length > 0 && (
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Manufacture</th>
                <th>Asset Type</th>
                <th>Serial Number</th>
                <th>Owner</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allocatedAssetList.map((asset, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{asset.assignedAssestId}</td> */}
                  <td>{asset.manufacture}</td>
                  <td>{asset.assestName}</td>
                  <td>{asset.serialNumber}</td>
                  <td>{asset.owner}</td>
                  <td>
                    <div className="radio-group ms-3">
                      <a
                        id={asset.assignedAssestId}
                        name="selectAsset"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          handleChangeAsset(e, asset.assignedAssestId)
                        }
                      >
                        <i
                          class="fa fa-trash-o"
                          style={{ fontSize: "24px", color: "red" }}
                        ></i>
                      </a>
                      {/* <input
                        type="radio"
                        id={asset.assignedAssestId}
                        name="selectAsset"
                        value={asset.assignedAssestId}
                        checked={selectedAssetId === asset.assignedAssestId}
                        onChange={handleChangeAsset}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {formData?.employeeId !== "" &&
        allocatedAssetList.length === 0 &&
        !isAssetLoading && (
          <div className="fs-4 text-secondary text-center">
            <h4>Assets are not assigned to a user.</h4>
          </div>
        )}
      {formData?.employeeId !== "" &&
        allocatedAssetList.length === 0 &&
        isAssetLoading && (
          <div className="centered-loader">
            <ThreeDots
              height="100"
              width="100"
              radius="9"
              color="blue"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

      <a
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropAssetDeallocate"
        className="open-modal-deallocation visually-hidden"
      >
        click
      </a>
      <div
        className="modal fade"
        id="staticBackdropAssetDeallocate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Asset Deallocation Action
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label className="has-asterisk">Asset Condition</label>
                  </div>

                  <div className="col-md-5">
                    <h6>
                      <div className="input-group">
                        <select
                          id="assetCondition"
                          value={formData.assetCondition}
                          required
                          onChange={handleInputChange}
                          name="assetCondition"
                          className="border"
                        >
                          <option value="">Select asset condition</option>
                          <option value="Partially damaged but working">
                            Partially damaged but working
                          </option>
                          <option value="Only dent on body">
                            Only dent on body
                          </option>
                          <option value="Fully damaged">Fully damaged</option>
                          <option value="Good condition">Good condition</option>
                        </select>
                      </div>
                    </h6>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="repairCost" className="has-asterisk">
                      Repair Cost
                    </label>
                  </div>
                  <div className="col-md-5 ">
                    <input
                      type="tel"
                      name="repairCost"
                      value={formData.repairCost}
                      onChange={handleInputChange}
                      onKeyUp={handleKeyUp}
                      required
                      pattern="[0-9]*"
                      className="border"
                      onKeyPress={(e) => {
                        const isDigit = /\d/.test(e.key);
                        if (
                          !isDigit ||
                          parseInt(e.target.value + e.key) > 100000
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="comment" className="has-asterisk">
                      Comment
                    </label>
                  </div>
                  <div className="col-md-5">
                    <textarea
                      id="comment"
                      name="comment"
                      required
                      value={formData.comment}
                      onChange={handleInputChange}
                      rows={3}
                      cols={20}
                      className="border"
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AssetDeallocation;
