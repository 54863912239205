import moment from "moment/moment";

export const emailRegExp =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

export const passwordRegExp =
  /^.*(?=.{8,40})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const AADHAAR_REGEX = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export function generatePassword(username, dob) {
  const firstFourCapitalized = username.slice(0, 4).toUpperCase();
  const dobComponents = dob.split("-");
  const yearOfBirth = dobComponents[0];
  const monthOfBirth = dobComponents[1];
  const dayOfBirth = dobComponents[2];

  return `${firstFourCapitalized}${yearOfBirth}${monthOfBirth}${dayOfBirth}`;
}

export const generateYear = (startYear) => {
  const years = [];
  // const startYear = 2020;
  const endYear = new Date().getFullYear() + 1;
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years.reverse();
};

export const generateYearAll = (startYear) => {
  const years = [];
  // const startYear = 2020;
  const endYear = new Date().getFullYear() + 5;
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

// export function getWeekDates(year, weekNumber) {
//   const weekStart = moment().year(year).week(weekNumber).startOf("week");
//   const weekEnd = moment().year(year).week(weekNumber).endOf("week");
//   const dates = [];
//   let currentDate = weekStart.clone();
//   while (currentDate.isSameOrBefore(weekEnd)) {
//     dates.push(currentDate.format("ddd, MMM Do YYYY"));
//     currentDate.add(1, "day");
//   }
//   return dates;
// }

// export function getCurrentWeekDates() {
//   const currentYear = moment().year();
//   const currentWeekNumber = moment().week();
//   return getWeekDates(currentYear, currentWeekNumber);
// }

// export function getPreviousWeekDates() {
//   const currentYear = moment().year();
//   const currentWeekNumber = moment().week();
//   const previousWeekNumber = currentWeekNumber - 1;
//   return getWeekDates(currentYear, previousWeekNumber);
// }

// export function getNextWeekDates() {
//   const currentYear = moment().year();
//   const currentWeekNumber = moment().week();
//   const nextWeekNumber = currentWeekNumber + 1;
//   return getWeekDates(currentYear, nextWeekNumber);
// }

export function getWeekDates(startDate) {
  const dates = [];
  let currentDate = moment(startDate).startOf("isoWeek"); // Start of the ISO week (Monday)

  for (let i = 0; i < 7; i++) {
    dates.push(currentDate.format("ddd, MMM Do YYYY"));
    currentDate.add(1, "day");
  }
  return dates;
}

export function getCurrentWeekDates() {
  const currentMonday = moment().startOf("isoWeek"); // Start of the ISO week (Monday)
  return getWeekDates(currentMonday);
}

export function getPreviousWeekDates() {
  const currentMonday = moment().startOf("isoWeek"); // Start of the ISO week (Monday)
  const previousMonday = currentMonday.subtract(7, "days");
  return getWeekDates(previousMonday);
}

export function getNextWeekDates() {
  const currentMonday = moment().startOf("isoWeek"); // Start of the ISO week (Monday)
  const nextMonday = currentMonday.add(7, "days");
  return getWeekDates(nextMonday);
}

// // Example usage:
// console.log("Current Week Dates:", getCurrentWeekDates());

// // To get the previous week's dates
// console.log("Previous Week Dates:", getPreviousWeekDates());

// // To get the next week's dates
// console.log("Next Week Dates:", getNextWeekDates());

export function getGreeting() {
  const currentTime = new Date().getHours();
  if (currentTime >= 5 && currentTime < 12) {
    return "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

// Function to toggle the "show-menu" class on the element with the "wrapper" class
export function toggleMenu() {
  var wrapper = document.querySelector(".wrapper");
  var isMenuShown = wrapper.classList.contains("show-menu");
  if (isMenuShown) {
    wrapper.classList.remove("show-menu");
  } else {
    wrapper.classList.add("show-menu");
  }
}

export function isCardNumberValid(cardType, cardNumber) {
  if (cardType === "AADHAAR") {
    return AADHAAR_REGEX.test(cardNumber);
  } else if (cardType === "PAN") {
    return PAN_REGEX.test(cardNumber);
  } else {
    throw new Error(`Invalid card type: ${cardType}`);
  }
}

export function truncateString(str) {
  if (str.length > 20) {
    return str.substring(0, 20) + "...";
  }
  return str;
}

export function getCurrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

export function formatLeaveType(leaveType) {
  if (leaveType === "PrivilegeLeave") {
    return leaveType.replace(leaveType, "Privilege Leave");
  }
  if (leaveType === "UnPaidLeave") {
    return leaveType.replace(leaveType, "Un-Paid Leave");
  }
  if (leaveType === "HalfDayLeave" || leaveType === "halfDayLeave") {
    return leaveType.replace(leaveType, "Half Day Leave");
  }
  if (leaveType === "halfYearlyLeave" || leaveType === "HalfYearly") {
    return leaveType.replace(leaveType, "Half Yearly Leave");
  }
  if (leaveType === "ShortLeave") {
    return leaveType.replace(leaveType, "Short Leave");
  }
  if (leaveType === "ONOFFICIALDUTY") {
    return leaveType.replace(leaveType, "On Official Duty");
  }
  if (leaveType === "privilegeLeave") {
    return leaveType.replace(leaveType, "Privilege Leave");
  }
  if (leaveType === "paternityLeave") {
    return leaveType.replace(leaveType, "Paternity Leave");
  }
  if (leaveType === "workFromHomeLeave" || leaveType === "WorkFromHome") {
    return leaveType.replace(leaveType, "Work From Home (current Month)");
  }
  if (leaveType === "shortLeave") {
    return leaveType.replace(leaveType, "Short Leave");
  }
  if (leaveType === "workFromHomeLeaveForQuarter") {
    return leaveType.replace(leaveType, "Work From Home (current Quarter)");
  }
  if (leaveType === "Week-off") {
    return leaveType.replace(leaveType, "Weekend Off");
  }
  return leaveType;
}

export const capitalize = (str) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return null;

  const date = new Date(timestamp);

  return date.toLocaleDateString("en-UK");
};
