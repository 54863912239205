import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getApi, putApi } from "../../utils/api";
import {
  API_LEAVE_CREDIT_REQUEST,
  API_WITHDRAWAL_LEAVE_CREDIT_REQUEST,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { toggleMenu } from "../../utils/helpers";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import moment from "moment";
import Pagination from "../../components/Pagination";
import AddLeaveCreditRequest from "./AddLeaveCreditRequest";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";

const LeaveCreditRequest = ({title, description}) => {
  const [leaveRequestList, setLeaveRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [filteredItems, setFilteredItems] = useState([]);
  const [comment, setComment] = useState("");

  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalLeaveRequest, setTotalLeaveRequest] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_LEAVE_CREDIT_REQUEST +
        `?pageSize=${limit}&page=${currentPage}&status=${selectedStatus}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveRequestList(res.data.data ? res.data.data : []);
          setFilteredItems(res.data.data ? res.data.data : []);
          setTotalLeaveRequest(
            res.data.totalElements ? res.data.totalElements : 0
          );
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  // const handleSearch = (e) => {
  //   const searchValue = e.target.value;
  //   setSearchTerm(searchValue);
  //   const filtered = leaveRequestList.filter(
  //     (item) =>
  //       item.typeOfLeave.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       item.empName.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  //   setFilteredItems(filtered);
  // };

  const handleSearch = (e) => {
    const searchValue = e.target.value; // Get the raw input value
    setSearchTerm(searchValue);

    // Normalize the search value by removing spaces
    const normalizedSearchValue = searchValue.replace(/\s+/g, "").toLowerCase();

    const filtered = leaveRequestList.filter((item) => {
      const typeOfLeave = item.typeOfLeave.toLowerCase().replace(/\s+/g, ""); // Remove spaces for comparison
      const empName = item.empName.toLowerCase().replace(/\s+/g, ""); // Remove spaces for comparison
      return (
        typeOfLeave.includes(normalizedSearchValue) ||
        empName.includes(normalizedSearchValue)
      );
    });

    setFilteredItems(filtered);
  };

  const handleReset = () => {
    setSearchTerm("");
    setComment("");
    setSelectedRequest({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        API_WITHDRAWAL_LEAVE_CREDIT_REQUEST +
          `?empId=${selectedRequest?.empId}&leaveRequestId=${selectedRequest?.id}&comment=${comment}`,
        {},
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchList();
            const modalClose = document.getElementsByClassName(
              "cancel-modal-withdraw-leave"
            )[0];
            modalClose.click();
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {/* <div className="total">
          Total Request : <span>{totalLeaveRequest}</span>
        </div> */}
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Leave Type or Employee Name"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setSearchTerm("");
            }}
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="Pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="Withdraw">Withdrawn</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        <div className="actions">
          <button
            className="export theme-button bg-success"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropRequest"
            // onClick={handleFilter}
          >
            + Add Request
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom" id="table-email-request">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={() => handleSort("empName")}>
                    Employee Name
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("requestedByName")}>
                    Requested By
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("typeOfLeave")}>
                    Leave Type
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("comment")}>
                    Reason
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("source")}>
                    Source
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("dateTime")}>
                    Requested Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("noOfDays")}>
                    Leave Count
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  {selectedStatus === "Rejected" && (
                    <th onClick={() => handleSort("rejectReason")}>
                      Rejected Reason
                      <img
                        src="/assets/images/sort.png"
                        alt="sort"
                        width={18}
                        height={18}
                      />
                    </th>
                  )}
                  <th>Status</th>
                  {selectedStatus === "Pending" && <th>Action</th>}
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td data-title="empName">{item.empName}</td>
                      <td data-title="requestedByName">
                        {item.requestedByName}
                      </td>
                      <td data-title="typeOfLeave">{item.typeOfLeave}</td>
                      <td data-title="comment">{item.comment}</td>

                      <td data-title="source">{item.source}</td>

                      <td data-title="requestDateTime">
                        {moment(item.dateTime).format("MM/DD/YYYY")}
                      </td>
                      <td data-title="noOfDays">{item.noOfDays}</td>

                      {item.status === "rejected" && (
                        <td>{item.rejectReason}</td>
                      )}
                      <td data-title="status">
                        <small
                          className={`text-capitalize badge bg-${
                            item.status === "pending" ||
                            item.status === "withdraw"
                              ? "warning"
                              : item.status === "rejected"
                              ? "danger"
                              : "success"
                          }`}
                        >
                          {item.status === "withdraw"
                            ? "Withdrawn"
                            : item.status}
                        </small>
                      </td>

                      {item.status === "pending" && (
                        <td>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropLeaveRequestwithdraw"
                            onClick={() => {
                              setSelectedRequest(item);
                            }}
                          >
                            Withdraw
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          <h4>No Record Available</h4>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AddLeaveCreditRequest updateList={fetchList} />
          </div>
        </div>
      </div>

      {/* WithDraw Request */}
      <div
        className="modal fade"
        id="staticBackdropLeaveRequestwithdraw"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              {isLoading && (
                <div className="overlay">
                  <div className="loader-container">
                    <TailSpin
                      height="100"
                      width="100"
                      radius="1"
                      color="blue"
                      ariaLabel="tail-spin-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </div>
              )}
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Withdraw Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                {selectedRequest !== null &&
                  Object.keys(selectedRequest).length > 0 && (
                    <div className="table-responsive">
                      <table className="resume custom">
                        <thead>
                          <tr>
                            <th>Employee Name</th>
                            <th>Requested By</th>
                            <th>Leave Type</th>
                            <th>Leave Count</th>
                            <th>Requested Date</th>
                            <th>Comment</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{selectedRequest?.empName}</td>
                            <td>{selectedRequest?.requestedByName}</td>
                            <td>{selectedRequest?.typeOfLeave}</td>
                            <td>{selectedRequest?.noOfDays}</td>
                            <td>
                              {moment(selectedRequest.dateTime).format("l")}
                            </td>
                            <td>{selectedRequest?.comment}</td>
                            <td>
                              {
                                <small
                                  className={`text-capitalize badge bg-${
                                    selectedRequest.status === "pending"
                                      ? "warning"
                                      : selectedRequest.status === "Rejected"
                                      ? "danger"
                                      : "success"
                                  }`}
                                >
                                  {selectedRequest.status}
                                </small>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="comment" className="has-asterisk">
                      Comment
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      rows={3}
                      cols={5}
                      className="border"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      required
                      maxLength={200}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  Withdraw
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal-withdraw-leave"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LeaveCreditRequest;
