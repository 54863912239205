import { TailSpin, ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import Pagination from "../../components/Pagination";
import { toggleMenu } from "../../utils/helpers";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApi, putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { API_ANNOUNCEMENT } from "../../config/Endpoints";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

const ViewAnnouncement = ({title, description}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    eventType: "",
    presentedBy: "",
    startDateTime: "",
    endDateTime: "",
    venue: "",
    mode: "",
    file: null,
  });
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [isLoading, setIsLoading] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [classroomSession, setClassroomSession] = useState([]);
  const [coffeeLearnSession, setCoffeeLearnSession] = useState([]);
  const [salesforceSession, setSalesforceSession] = useState([]);
  const [muleSoftMeetups, setMuleSoftMeetups] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRequest, setTotalRequest] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { hash } = useLocation();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  useEffect(() => {
    if (hash) {
      const tabId = hash.replace("#", "");
      const navTabs = document.querySelector("#myTabs");
      const tabContent = document.querySelector("#myTabsContent");

      if (navTabs && tabContent) {
        // Remove 'active' class from all tabs and tab contents
        navTabs.querySelectorAll(".nav-link").forEach((tab) => {
          tab.classList.remove("active");
          tab.setAttribute("aria-selected", "false");
        });

        tabContent.querySelectorAll(".tab-pane").forEach((content) => {
          content.classList.remove("show", "active");
        });

        // Add 'active' class to the selected tab and content
        const activeTab = navTabs.querySelector(`[data-bs-target="#${tabId}"]`);
        const activeContent = document.querySelector(`#${tabId}`);

        if (activeTab && activeContent) {
          activeTab.classList.add("active");
          activeTab.setAttribute("aria-selected", "true");
          activeContent.classList.add("show", "active");
        }
      }
    }
  }, [hash]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_ANNOUNCEMENT +
        `?pageSize=${limit}&page=${currentPage}&status=APPROVED`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          const resultData = res?.data?.data?.data;
          const classSession = resultData?.filter(
            (it) => it.eventType === "Classroom Session"
          );
          const coffeeSession = resultData?.filter(
            (it) => it.eventType === "Coffee & Learn Session"
          );

          const muleSoft = resultData?.filter(
            (it) => it.eventType === "MuleSoft Meetups"
          );
          const salesforce = resultData?.filter(
            (it) => it.eventType === "Salesforce Session"
          );
          setClassroomSession(classSession);
          setCoffeeLearnSession(coffeeSession);
          setMuleSoftMeetups(muleSoft);
          setSalesforceSession(salesforce);

          setAnnouncementList(res?.data?.data?.data);
          setFilteredItems(res?.data?.data?.data);
          setTotalRequest(res.data.data.totalRequest);
          setTotalPages(res.data.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("title", formData.title);
      formdata.append("description", formData.description);
      formdata.append("eventType", formData.eventType);
      formdata.append("presentedBy", formData.presentedBy);
      formdata.append(
        "startDateTime",
        formData.startDateTime.toISOString().slice(0, 19)
      );
      formdata.append(
        "endDateTime",
        formData.endDateTime.toISOString().slice(0, 19)
      );
      formdata.append("venue", formData.venue);
      formdata.append("mode", formData.mode);
      if (formData.file) {
        formdata.append("file", formData.file);
      }

      await putApi(API_ANNOUNCEMENT, formdata, accessToken)
        .then((res) => {
          setIsButtonLoading(false);
          handleReset();
          fetchList();
          toast.success(res?.data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const modalClose = document.getElementsByClassName("cancel-modal")[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsButtonLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsButtonLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReset = () => {
    setFormData({
      title: "",
      description: "",
      eventType: "",
      presentedBy: "",
      startDateTime: "",
      endDateTime: "",
      venue: "",
      mode: "",
      file: null,
    });
    document.getElementById("file-input").value = "";
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpeg",
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes
      if (file) {
        if (file.size > maxFileSize) {
          // File size exceeds the maximum allowed size
          toast.error("File size exceeds the maximum limit of 8MB.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          event.target.value = null; // Clear the file input
        } else if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please upload a valid PDF, JPG, PNG, or JPEG.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = announcementList.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const getLastPDFFileName = (filePath) => {
    const parts = filePath.split("/");
    let lastFileName = null;

    for (let i = parts.length - 1; i >= 0; i--) {
      const fileName = parts[i];

      if (
        [".pdf", ".jpeg", ".png", ".jpg"].some((ext) => fileName.endsWith(ext))
      ) {
        lastFileName = fileName;
        break;
      }
    }

    return lastFileName;
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredItems(sortedData);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        {/* <div className="total">
          Total Request : <span>{filteredItems?.length}</span>
        </div> */}
      </div>

      {/* Filters */}
      {/* <div className="filters"> */}
      {/* <form action="index.html" id="form_filters" className="d-f"> */}
      {/* Search By Company */}
      {/* <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Title"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div> */}
      {/* </div> */}
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="row ">
        <div className="container">
          <ul className="nav nav-tabs" id="myTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="tab1-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab1"
                type="button"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                Classroom Sessions
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab2-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab2"
                type="button"
                role="tab"
                aria-controls="tab2"
                aria-selected="false"
              >
                Coffee & Learn Sessions
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab3-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab3"
                type="button"
                role="tab"
                aria-controls="tab3"
                aria-selected="false"
              >
                MuleSoft Meetups
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab4-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab4"
                type="button"
                role="tab"
                aria-controls="tab4"
                aria-selected="false"
              >
                Salesforce Sessions
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabsContent">
            <div
              className="tab-pane fade show active "
              id="tab1"
              role="tabpanel"
              aria-labelledby="tab1-tab"
            >
              {/* Data Table */}
              {!isLoading && classroomSession?.length > 0 && (
                <>
                  {/* <div className="filters">
                    <div className="search">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search By Title"
                        value={searchTerm}
                        onChange={handleSearch}
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                      <img
                        src="/assets/images/search-ico.svg"
                        width="14"
                        alt="Search"
                      />
                    </div>
                  </div> */}
                  <div className="table-responsive">
                    <table className="resume custom" id="table-email-request">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>
                            S.No.
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Title{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>Description</th>
                          {/* <th>Event Type</th> */}
                          <th>
                            Venue{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Mode{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Presented By{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Start Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            End Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          {/* <th>Duration</th> */}
                          <th>Attachment</th>
                          <th>
                            State{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {classroomSession &&
                          classroomSession?.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * limit + index + 1}</td>
                              {/* <td data-title="id">{item.id}</td> */}
                              <td data-title="title">{item.title}</td>
                              <td data-title="description">
                                {item.description}
                              </td>
                              {/* <td data-title="eventType">{item.eventType}</td> */}
                              <td data-title="venue">{item.venue}</td>
                              <td data-title="mode">{item.mode}</td>
                              <td data-title="presentedBy">
                                {item.presentedBy}
                              </td>
                              <td data-title="startDateTime">
                                {moment(item.startDateTime).format("LLL")}
                              </td>
                              <td data-title="endDateTime">
                                {moment(item.endDateTime).format("LLL")}
                              </td>
                              {/* <td data-title="duration">{item.duration}</td> */}
                              <td>
                                {getLastPDFFileName(item?.attachment) !==
                                null ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_IMAGE_URL +
                                      item?.attachment
                                    }
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td data-title="status">
                                <small
                                  className={`text-capitalize badge bg-${
                                    item.status === "Upcoming"
                                      ? "success"
                                      : item.status === "Past"
                                      ? "danger"
                                      : "warning"
                                  }`}
                                >
                                  {item.status}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25  p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {!isLoading && classroomSession?.length === 0 && (
                <div className="fs-4 text-secondary text-center mt-5">
                  <h4>No Record Available</h4>
                </div>
              )}
            </div>

            <div
              className="tab-pane fade"
              id="tab2"
              role="tabpanel"
              aria-labelledby="tab2-tab"
            >
              {/* Data Table */}
              {!isLoading && coffeeLearnSession?.length > 0 && (
                <>
                  {/* <div className="filters mt-3">
                    <div className="search">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search By Title"
                        value={searchTerm}
                        onChange={handleSearch}
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                      <img
                        src="/assets/images/search-ico.svg"
                        width="14"
                        alt="Search"
                      />
                    </div>
                  </div> */}
                  <div className="table-responsive">
                    <table className="resume custom" id="table-email-request">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>
                            S.No.
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Title{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>Description</th>
                          {/* <th>Event Type</th> */}
                          <th>
                            Venue{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Mode{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Presented By{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Start Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            End Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          {/* <th>Duration</th> */}
                          <th>Attachment</th>
                          <th>
                            State{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                        </tr>
                      </thead>
                      {/* Table Body */}
                      <tbody>
                        {coffeeLearnSession &&
                          coffeeLearnSession?.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * limit + index + 1}</td>
                              {/* <td data-title="id">{item.id}</td> */}
                              <td data-title="title">{item.title}</td>
                              <td data-title="description">
                                {item.description}
                              </td>
                              {/* <td data-title="eventType">{item.eventType}</td> */}
                              <td data-title="venue">{item.venue}</td>
                              <td data-title="mode">{item.mode}</td>
                              <td data-title="presentedBy">
                                {item.presentedBy}
                              </td>
                              <td data-title="startDateTime">
                                {moment(item.startDateTime).format("LLL")}
                              </td>
                              <td data-title="endDateTime">
                                {moment(item.endDateTime).format("LLL")}
                              </td>
                              {/* <td data-title="duration">{item.duration}</td> */}
                              <td>
                                {getLastPDFFileName(item?.attachment) !==
                                null ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_IMAGE_URL +
                                      item?.attachment
                                    }
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td data-title="status">
                                <small
                                  className={`text-capitalize badge bg-${
                                    item.status === "Upcoming"
                                      ? "success"
                                      : item.status === "Past"
                                      ? "danger"
                                      : "warning"
                                  }`}
                                >
                                  {item.status}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25  p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {!isLoading && coffeeLearnSession?.length === 0 && (
                <div className="fs-4 text-secondary text-center mt-5">
                  <h4>No Record Available</h4>
                </div>
              )}
            </div>

            <div
              className="tab-pane fade "
              id="tab3"
              role="tabpanel"
              aria-labelledby="tab3-tab"
            >
              {/* Data Table */}
              {!isLoading && muleSoftMeetups?.length > 0 && (
                <>
                  {/* <div className="filters mt-3">
                    <div className="search">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search By Title"
                        value={searchTerm}
                        onChange={handleSearch}
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                      <img
                        src="/assets/images/search-ico.svg"
                        width="14"
                        alt="Search"
                      />
                    </div>
                  </div> */}
                  <div className="table-responsive">
                    <table className="resume custom" id="table-email-request">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>
                            S.No.
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Title{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>Description</th>
                          {/* <th>Event Type</th> */}
                          <th>
                            Venue{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Mode{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Presented By{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Start Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            End Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          {/* <th>Duration</th> */}
                          <th>Attachment</th>
                          <th>
                            State{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {muleSoftMeetups &&
                          muleSoftMeetups?.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * limit + index + 1}</td>
                              {/* <td data-title="id">{item.id}</td> */}
                              <td data-title="title">{item.title}</td>
                              <td data-title="description">
                                {item.description}
                              </td>
                              {/* <td data-title="eventType">{item.eventType}</td> */}
                              <td data-title="venue">{item.venue}</td>
                              <td data-title="mode">{item.mode}</td>
                              <td data-title="presentedBy">
                                {item.presentedBy}
                              </td>
                              <td data-title="startDateTime">
                                {moment(item.startDateTime).format("LLL")}
                              </td>
                              <td data-title="endDateTime">
                                {moment(item.endDateTime).format("LLL")}
                              </td>
                              {/* <td data-title="duration">{item.duration}</td> */}
                              <td>
                                {getLastPDFFileName(item?.attachment) !==
                                null ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_IMAGE_URL +
                                      item?.attachment
                                    }
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td data-title="status">
                                <small
                                  className={`text-capitalize badge bg-${
                                    item.status === "Upcoming"
                                      ? "success"
                                      : item.status === "Past"
                                      ? "danger"
                                      : "warning"
                                  }`}
                                >
                                  {item.status}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25  p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {!isLoading && muleSoftMeetups?.length === 0 && (
                <div className="fs-4 text-secondary text-center mt-5">
                  <h4>No Record Available</h4>
                </div>
              )}
            </div>

            <div
              className="tab-pane fade"
              id="tab4"
              role="tabpanel"
              aria-labelledby="tab4-tab"
            >
              {/* Data Table */}
              {!isLoading && salesforceSession?.length > 0 && (
                <>
                  <div className="table-responsive">
                    <table className="resume custom" id="table-email-request">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>
                            S.No.
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Title{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>Description</th>
                          {/* <th>Event Type</th> */}
                          <th>
                            Venue{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Mode{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Presented By{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            Start Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          <th>
                            End Date Time{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                          {/* <th>Duration</th> */}
                          <th>Attachment</th>
                          <th>
                            State{" "}
                            {/* <img
                              src="/assets/images/sort.png"
                              alt="sort"
                              width={18}
                              height={18}
                            /> */}
                          </th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        {salesforceSession &&
                          salesforceSession?.map((item, index) => (
                            <tr key={index}>
                              {/* <td data-title="id">{item.id}</td> */}
                              <td>{(currentPage - 1) * limit + index + 1}</td>
                              <td data-title="title">{item.title}</td>
                              <td data-title="description">
                                {item.description}
                              </td>
                              {/* <td data-title="eventType">{item.eventType}</td> */}
                              <td data-title="venue">{item.venue}</td>
                              <td data-title="mode">{item.mode}</td>
                              <td data-title="presentedBy">
                                {item.presentedBy}
                              </td>
                              <td data-title="startDateTime">
                                {moment(item.startDateTime).format("LLL")}
                              </td>
                              <td data-title="endDateTime">
                                {moment(item.endDateTime).format("LLL")}
                              </td>
                              {/* <td data-title="duration">{item.duration}</td> */}
                              <td>
                                {getLastPDFFileName(item?.attachment) !==
                                null ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_IMAGE_URL +
                                      item?.attachment
                                    }
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td data-title="status">
                                <small
                                  className={`text-capitalize badge bg-${
                                    item.status === "Upcoming"
                                      ? "success"
                                      : item.status === "Past"
                                      ? "danger"
                                      : "warning"
                                  }`}
                                >
                                  {item.status}
                                </small>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        {/* Dropdown for selecting items per page */}
                        <label htmlFor="itemsPerPage">Items per page </label>
                        <select
                          id="itemsPerPage"
                          onChange={handleLimitChange}
                          value={limit}
                          className="border ms-3 w-25  p-2"
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={40}>40</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {!isLoading && salesforceSession?.length === 0 && (
                <div className="fs-4 text-secondary text-center mt-5">
                  <h4>No Record Available</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ViewAnnouncement;
