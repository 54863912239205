import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { putApi } from "../../utils/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { API_HOLIDAY_ADD, API_HOLIDAY_UPDATE } from "../../config/Endpoints";

const CreateHoliday = ({ selectedHoliday, setSelectedHoliday, fetchList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    holiday: "",
    date: "",
    status: "",
  });
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    if (Object.keys(selectedHoliday).length > 0) {
      setFormData({
        holiday: selectedHoliday?.nameOfHoliday,
        date: selectedHoliday?.date,
        status: selectedHoliday?.status?.toUpperCase(),
      });
    }
  }, [selectedHoliday]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setSelectedHoliday({});
    setFormData({
      holiday: "",
      date: "",
      status: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedHoliday?.id) {
        await putApi(
          API_HOLIDAY_UPDATE +
            `?date=${formData.date}&holidayFor=${formData.holiday}&status=${formData.status}&id=${selectedHoliday?.id}`,
          {},
          accessToken
        )
          .then((res) => {
            setIsLoading(false);
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: true,
            });
            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        await putApi(
          API_HOLIDAY_ADD +
            `?date=${formData.date}&holidayFor=${formData.holiday}`,
          {},
          accessToken
        )
          .then((res) => {
            setIsLoading(false);
            toast.success(
              res.data.message ? res.data.message : res?.data?.data?.message,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: true,
              }
            );
            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {Object.keys(selectedHoliday).length > 0 ? "Update" : "Add"} Holiday
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="tholidaytle" className="has-asterisk">
              Holiday
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="holiday"
              value={formData.holiday}
              onChange={handleInputChange}
              placeholder="Enter Holiday"
              className="border"
              maxLength={20}
              required
              onKeyPress={(e) => {
                const inputValue = e.key;
                // Prevent adding space at the beginning or first character as number
                if (
                  (e.charCode === 32 && e.target.selectionStart === 0) || // Prevent space at the beginning
                  (e.target.selectionStart === 0 && /^\d$/.test(inputValue)) // Prevent first character as number
                ) {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                const inputValue = e.target.value;
                // Remove numbers if the input field only contains numbers
                if (/^\d+$/.test(inputValue)) {
                  e.target.value = "";
                }
              }}
            />
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="date" className="has-asterisk">
              Date
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              name="date"
              className="border"
              value={formData.date}
              required
              onChange={handleInputChange}
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
              max={`${new Date().getFullYear() + 5}-12-31`}
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        {selectedHoliday?.id && (
          <div className="row mt-3">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <label htmlFor="status" className="has-asterisk">
                Status
              </label>
            </div>
            <div className="col-md-4">
              <div className="input-group">
                <select
                  id="status"
                  required
                  className="border"
                  name="status"
                  onChange={handleInputChange}
                  value={formData.status}
                >
                  <option value="">Select Status</option>
                  <option value="PENDING">Pending</option>
                  <option value="APPROVED">Approved</option>
                  <option value="REJECTED">Rejected</option>
                </select>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        )}
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CreateHoliday;
