import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Cookies from "js-cookie";
import { toggleMenu } from "../../utils/helpers";
import { getApi } from "../../utils/api";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { API_USER_ROLE, GET_DEPARTMENT_LIST } from "../../config/Endpoints";
import PageTitle from "../../components/PageTitle";

const UsersRoleReport = ({title, description}) => {
  const [userRoleList, setUserRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalUserRole, setTotalUserRole] = useState(0);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);
  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedDepartmentId, selectedStatus]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_USER_ROLE +
        `?pageSize=${limit}&currentPage=${currentPage}&departmentId=${selectedDepartmentId}&status=${selectedStatus}`,
      accessToken
    )
      // getApi(API_ROLE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setUserRoleList(res.data.data);
          setFilteredItems(res.data.data);
          setTotalUserRole(res.data.totalRequest);
          setTotalPages(res.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const fetchDepartmentList = () => {
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = userRoleList.filter((item) =>
      item.empName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Users : <span>{totalUserRole}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        <div className="search">
          <select
            id="department"
            required
            className="form-select"
            name="department"
            value={selectedDepartmentId}
            onChange={(e) => setSelectedDepartmentId(e.target.value)}
          >
            <option value="">Select Department</option>
            {departmentList &&
              departmentList.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="search">
          <select
            id="selectedStatus"
            required
            className="form-select"
            name="selectedStatus"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">Select Status</option>
            <option value="VERIFIED">VERIFIED</option>
            <option value="BLOCKED">BLOCKED (Temporally Disable)</option>
            <option value="LEFTOUT">LEFTOUT (Permanently Disable)</option>
          </select>
        </div>
        {/* Actions */}
        <div className="actions">
          {/* <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropRole"
          >
            + Add Role
          </Link> */}
        </div>
      </div>
      {/* Data Table */}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Employee Type</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Roles</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems &&
                  filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      {/* <td data-title="id">{item.id}</td> */}
                      <td data-title="empName">{item.empName}</td>
                      <td data-title="ccEmail">{item.ccEmail}</td>
                      <td data-title="employeeType">{item.employeeType}</td>
                      <td data-title="department">{item.department}</td>
                      <td data-title="designation" className="w-50">
                        {item.designation}
                      </td>
                      <td data-title="roles" className="w-50">
                        {item.roles?.map((item) => (
                          <li>{item}</li>
                        ))}
                      </td>
                      {/* <td data-title="action" className="w-50">
                        <a>View</a>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value="">All</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UsersRoleReport;
