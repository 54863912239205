import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API_EMPLOYEE_EDUCATION } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi, putApi } from "../../utils/api";
import * as Yup from "yup";

const AddEducation = ({
  setEducationList,
  selectedEducation,
  setSelectedEducation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");

  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    highestDegree: "",
    college: "",
    from: "",
    to: "",
  });
  const [errors, setErrors] = useState({});

  const EducationSchema = Yup.object().shape({
    highestDegree: Yup.string().trim().required("Degree is required"),
    college: Yup.string().trim().required("College is required"),
    from: Yup.date().required("From Date is required"),
    to: Yup.date()
      .required("To Date is required")
      .when("from", (from, schema) => {
        return schema.test({
          test: (to) => {
            return new Date(to) >= new Date(from);
          },
          message: "'To Date' must be later than or equal to 'From Date'",
        });
      }),
  });
  const educationValidation = {
    highestDegree: formData.highestDegree,
    college: formData.college,
    from: formData.from,
    to: formData.to,
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await EducationSchema.validate(educationValidation, {
        abortEarly: false,
      });

      const requestData = {
        fromDate: formData.from,
        highestDegree: formData.highestDegree,
        college: formData.college,
        toDate: formData.to,
      };

      await postApi(API_EMPLOYEE_EDUCATION, requestData, accessToken)
        .then((res) => {
          setErrors({});
          if (res?.data?.data) {
            setEducationList(res.data.data);
          }
          setIsLoading(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-education"
          )[0];
          modalClose.click();
          toast.success("Education successfully added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await EducationSchema.validate(educationValidation, {
        abortEarly: false,
      });
      const requestData = {
        fromDate: formData.from,
        highestDegree: formData.highestDegree,
        college: formData.college,
        toDate: formData.to,
      };

      await putApi(
        API_EMPLOYEE_EDUCATION + `/${selectedEducation?.educationId}`,
        requestData,
        accessToken
      )
        .then((res) => {
          setErrors({});
          if (res?.data?.data) {
            setEducationList(res.data.data);
          }
          setIsLoading(false);
          const modalClose = document.getElementsByClassName(
            "cancel-modal-education"
          )[0];
          modalClose.click();
          toast.success("Education successfully updated!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((e) => {
          toast.error("Something went wrong please try again !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
      setIsLoading(false);
    }
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsLoading(true);
      if (selectedEducation?.educationId) {
        handleUpdate(e);
      } else {
        handleAdd(e);
      }
    } else {
      form.reportValidity();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedEducation).length !== 0) {
      setFormData({
        highestDegree: selectedEducation?.highestDegree,
        college: selectedEducation?.college,
        from: selectedEducation?.fromDate,
        to: selectedEducation?.toDate,
      });
      const modalClose = document.getElementsByClassName("add-education")[0];
      modalClose.click();
    }
  }, [selectedEducation?.educationId]);

  const handleReset = () => {
    setFormData({
      highestDegree: "",
      college: "",
      from: "",
      to: "",
    });
    setSelectedEducation({});
    setErrors({});
  };

  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];

  return (
    <form ref={formRef}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {selectedEducation?.educationId
            ? "Update Education"
            : "Add Education"}
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="highestDegree" className="has-asterisk">
              Degree
            </label>
          </div>

          <div className="col-md-4">
            <input
              type="text"
              name="highestDegree"
              value={formData.highestDegree}
              onChange={handleInputChange}
              required
              maxLength={100}
              className="border"
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
            />
            {errors.highestDegree && (
              <small className="text-danger">{errors.highestDegree}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="college" className="has-asterisk">
              College
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="college"
              value={formData.college}
              maxLength={100}
              onChange={handleInputChange}
              required
              className="border"
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
            />
            {errors.college && (
              <small className="text-danger">{errors.college}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="from" className="has-asterisk">
              From
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="date"
              required
              name="from"
              value={formData.from}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
              pattern="\d{2}/\d{2}/\d{4}"
              max={today}

              // onInput={(e) => {
              //   const inputDate = e.target.value;
              //   const maxYearLength = 4;

              //   // Extract the year part from the input date
              //   const yearPart = inputDate.split("-")[0];

              //   // Check if the year part exceeds 4 digits and trim it
              //   if (yearPart.length > maxYearLength) {
              //     e.target.value = inputDate.slice(0, maxYearLength + 1);
              //   }
              // }}
            />
            {errors.from && (
              <small className="text-danger">{errors.from}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="to" className="has-asterisk">
              To
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="date"
              required
              name="to"
              value={formData.to}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
              max={today}
            />
            {errors.to && <small className="text-danger">{errors.to}</small>}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="button"
          className={isLoading ? "theme-button disabled " : "theme-button "}
          onClick={handleManualSubmit}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal-education"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddEducation;
