import React, { useState } from "react";
import { API_UPLOAD_SALARY } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { postApi } from "../../utils/api";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const UploadSalary = ({title, description}) => {
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    employeeType: "",
    month: "",
    salarySlip: null,
  });
  const [isLoading, setIsLoading] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("excelFile", formData.salarySlip);

      const { employeeType, month } = formData;
      await postApi(
        API_UPLOAD_SALARY +
          "?employeeType=" +
          employeeType +
          "&month=" +
          month.toLowerCase(),
        formdata,
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          setFormData({
            employeeType: "",
            salarySlip: "",
            month: "",
          });
          toast.success("Successfully Uploaded!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          document.getElementById("file-input").value = "";
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
          toast.error("Something went wrong. Please try again!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = [
        // "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel file
      ];

      if (file) {
        if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please select a valid .xlsx file.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          event.target.value = null; // Clear the file input
        }
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleReset = () => {
    setFormData({
      employeeType: "",
      salarySlip: null,
      month: "",
    });
    document.getElementById("file-input").value = "";
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <PageTitle title={title} description={description} /> */}
        {/* <h1>Upload Salary</h1> */}
        {/* <div className="total">
          <Link to="/dashboard/assetAllocation" className="brand-color">
            Back to Assigned Asset List
          </Link>
        </div> */}
      </div>
      <form id="assign-asset" onSubmit={handleSubmit}>
        <div className="card">
          <h3 className="heading">Upload Salary</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="employeeType" className="has-asterisk">
                  Employee Type
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="employeeType"
                    required
                    name="employeeType"
                    value={formData.employeeType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select type</option>
                    <option value="CONTRACT">CONSULTANT </option>
                    <option value="REGULAR">REGULAR</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 ">
                <label htmlFor="salarySlip" className="has-asterisk">
                  Salary Slip
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <input
                    type="file"
                    name="salarySlip"
                    id="file-input"
                    onChange={handleInputChange}
                    required
                  />
                  <small className="text-danger pt-0">
                    Note: Please upload <strong>.xlsx</strong> file only.
                  </small>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row  mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <label htmlFor="month" className="has-asterisk">
                  Month
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="month"
                    required
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button className="btn btn-secondary" onClick={handleReset}>
              Reset
            </button>
            <button
              className={
                isLoading ? "theme-button disabled ms-2 " : "theme-button ms-2 "
              }
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default UploadSalary;
