import React, { useEffect, useRef, useState } from "react";
import { getApi, postApi } from "../../utils/api";
import Cookies from "js-cookie";
import {
  API_DOCUMENT,
  API_DOCUMENT_STATUS,
  API_DOCUMENT_VERSION,
  API_EMPLOYEE_DOCUMENT,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import { getUser } from "../../utils/Storage";
import { ThreeDots } from "react-loader-spinner";
import DocumentUpload from "../Dashboard/DocumentUpload";
const DocumentList = () => {
  const [documentList, setDocumentList] = useState([]);
  const [documentListStatus, setDocumentListStatus] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState(null);
  const [hasPendingFiles, setHasPendingFiles] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [documentType, setDocumentType] = useState({});
  const [selectedDocument, setSelectedDocument] = useState({});
  const userData = JSON.parse(getUser("userData"));
  const fileInputRef = useRef({});

  const fetchData = async () => {
    await getApi(API_EMPLOYEE_DOCUMENT, accessToken)
      .then((res) => {
        setDocumentList(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  console.log("docList: ", documentList);
  console.log("docType: ", documentType);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("docFile", uploadFile);
      formdata.append("employeeId", userData?.id);
      formdata.append("docType", documentType?.documentName);
      await postApi(API_DOCUMENT_VERSION, formdata, accessToken)
        .then((res) => {
          setIsLoading(false);

          toast.success(
            documentType?.documentType + " Uploaded Successfully.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          handleClose();
          handleReset();
          fetchData();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleUploadClick = (item) => {
    setSelectedDocument(item);
    setDocumentType(item);
    const openModalButton =
      document.getElementsByClassName("open-modal-upload")[0];
    if (openModalButton) {
      openModalButton.click();
    }
  };

  useEffect(() => {
    fetchData();
    fetchDocumentStatus();
  }, []);

  const fetchDocumentStatus = () => {
    setIsLoading(true);
    getApi(API_DOCUMENT_STATUS, accessToken)
      .then((res) => {
        setDocumentListStatus(res?.data?.data);
        const hasPendingFiles = res?.data?.data?.filter(
          (doc) => !doc.isUploaded
        );
        setHasPendingFiles(hasPendingFiles.length > 0 ? true : false);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setUploadFile(null);
    setDocumentType({});
    if (fileInputRef.current) {
      Object.keys(fileInputRef.current).forEach((docType) => {
        if (fileInputRef.current[docType]) {
          fileInputRef.current[docType].value = null;
        }
      });
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Define an array of accepted file types
      const acceptedFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/jpeg",
      ];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes
      if (!acceptedFileTypes.includes(selectedFile.type)) {
        // File type is not supported, show an alert
        toast.error(
          "Unsupported file type. Please upload a valid PDF, JPG, PNG, or JPEG.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        event.target.value = "";
      } else if (selectedFile.size > maxFileSize) {
        // File size exceeds the maximum allowed size
        toast.error("File size exceeds the maximum limit of 8MB.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        event.target.value = null; // Clear the file input
      } else {
        setUploadFile(selectedFile);
      }
    }
  };

  const handleClose = () => {
    document.getElementById("file-input").value = "";
    const modalClose = document.getElementsByClassName("close-class")[0];
    modalClose.click();
  };

  return (
    <>
      {hasPendingFiles && (
        <div class="d-grid gap-1 d-md-flex justify-content-md-end ">
          <a
            className=" text-primary mt-3 pe-auto"
            style={{ cursor: "pointer" }}
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropDocumentStatus"
            disabled={isLoading || documentListStatus?.length === 0}
          >
            Upload Pending Documents
          </a>
        </div>
      )}

      <div className="table-responsive mt-4">
        {documentList?.length === 0 && documentList && (
          <div className="fs-4 text-secondary text-center">
            No records available
          </div>
        )}
        {documentList?.length !== 0 && (
          <table className="resume custom">
            {/* Table Headings */}
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Document Type</th>
                <th>Status</th>
                <th>Comment</th>
                <th>View</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {documentList &&
                documentList?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {/* <td>{item.documentId}</td> */}
                    <td>{item.documentType}</td>
                    <td>
                      <small
                        className={
                          " badge " +
                          ((item.approvalStatus === "0" ||
                            item.approvalStatus === "pending") &&
                            " badge-warning ") +
                          (item.approvalStatus === "rejected" &&
                            " badge-danger ") +
                          (item.approvalStatus === "approved" &&
                            " badge-success ")
                        }
                      >
                        {item.approvalStatus === "0"
                          ? "Pending"
                          : item.approvalStatus.charAt(0).toUpperCase() +
                            item.approvalStatus.slice(1)}
                      </small>
                    </td>
                    <td>{item.reason ? item.reason : "-"}</td>

                    <td>
                      <a
                        className="fs-5"
                        href={
                          process.env.REACT_APP_IMAGE_URL + item.documentPath
                        }
                        target="_blank"
                      >
                        <i className="fa fa-eye"></i>
                      </a>

                      {item.approvalStatus === "0" ||
                      item.approvalStatus === "pending" ||
                      item.approvalStatus === "rejected" ? (
                        <>
                          <a
                            className="ms-3 fs-5 "
                            onClick={() => handleUploadClick(item)}
                          >
                            <i class="fa fa-upload"></i>
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <button
        className="open-modal-upload"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropDocument"
        style={{ display: "none" }}
      >
        click
      </button>
      <div
        className="modal fade"
        id="staticBackdropDocument"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center flex"
                id="staticBackdropLabel"
              >
                Upload Document Action
              </h5>
              <button
                type="button"
                className="btn-close close-class"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {Object.keys(selectedDocument)?.length > 0 && (
                  <div className="table-responsive mt-2">
                    <table className="resume custom ">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          {/* <th>Document ID</th> */}
                          <th>Document Type</th>
                          <th>Status</th>
                          <th>Comment</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      {/* Table Body */}
                      <tbody>
                        <tr>
                          {/* <td>{selectedDocument.documentId}</td> */}
                          <td>{selectedDocument.documentType}</td>

                          <td>
                            <small
                              className={
                                " badge " +
                                ((selectedDocument.approvalStatus === "0" ||
                                  selectedDocument.approvalStatus ===
                                    "pending") &&
                                  " badge-warning ") +
                                (selectedDocument.approvalStatus ===
                                  "rejected" && " badge-danger ") +
                                (selectedDocument.approvalStatus ===
                                  "approved" && " badge-success ")
                              }
                            >
                              {selectedDocument.approvalStatus === "0"
                                ? "Pending"
                                : selectedDocument.approvalStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                  selectedDocument.approvalStatus.slice(1)}
                            </small>
                          </td>
                          <td>
                            {selectedDocument.reason
                              ? selectedDocument.reason
                              : "-"}
                          </td>
                          <td>
                            <a
                              className="fs-5"
                              href={
                                process.env.REACT_APP_IMAGE_URL +
                                selectedDocument.documentPath
                              }
                              target="_blank"
                            >
                              <i className="fa fa-eye"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="department" className="has-asterisk">
                      Document
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="file"
                      id="file-input"
                      className="border "
                      onChange={handleFileChange}
                      required
                    />
                  </div>

                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                  // onClick={handleSubmit}
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-promote"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdropDocumentStatus"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <button
            type="button"
            className="btn-close-document"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ display: "none" }}
          ></button>
          <div className="modal-content">
            <DocumentUpload
              documentList={documentListStatus}
              fetchData={fetchData}
              fetchDocumentStatus={fetchDocumentStatus}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentList;
