import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_FORGOT_PASSWORD, API_VERIFY_OTP } from "../../config/Endpoints";
import { postApi } from "../../utils/api";
import * as Yup from "yup";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [forgotToken, setForgotToken] = useState(false);
  const [errors, setErrors] = useState("");
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
  const [isLinkActive, setIsLinkActive] = useState(false);

  useEffect(() => {
    if (isOTPSent) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
        if (timeLeft <= 0) {
          setIsLinkActive(true);
          clearInterval(timerId);
        }
      }, 1000); // 1 second interval
      return () => {
        clearInterval(timerId);
      };
    }
  }, [isOTPSent, timeLeft]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { email },
        {
          abortEarly: false,
        }
      );
      setErrors("");
      setIsLoading(true);
      await postApi(API_FORGOT_PASSWORD + "?email=" + email, {})
        .then((res) => {
          setIsLoading(false);
          if (res?.data.message === "OTP Sent Successfully") {
            toast.success(res?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeLeft(120);
            setIsLinkActive(false);
            setIsOTPSent(true);
            setForgotToken(res?.data.token);
          } else {
            toast.error(res?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error("Something went wrong please try again!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setIsLoading(false);

        setErrors(validationErrors);
      } else {
        toast.error("Something went wrong please try again!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await postApi(
      API_VERIFY_OTP +
        "?email=" +
        email +
        "&OTP=" +
        OTP +
        "&token=" +
        forgotToken,
      {}
    )
      .then((res) => {
        if (res?.data?.message === "Invalid OTP!") {
          setIsLoading(false);
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          navigate("/reset-password", {
            state: {
              email: res?.data?.email,
              token: forgotToken,
            },
          });
          toast.success(res?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="wrapper login_bg">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="wrapper-body auth">
        <img
          src="https://www.caeliusconsulting.com/image/logo.svg"
          alt="Caelius Consulting Logo"
          width="150"
          height="35"
          style={{ margin: "0 auto" }}
        />
        <div className="login">
          <div>
            <p
              className="text-center"
              style={{
                color: "#8186d5",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Please provide following details to reset your password.
            </p>
          </div>
          <form
            id="passwordForm"
            action="dashboard"
            onSubmit={isOTPSent ? handleOTP : handleSubmit}
          >
            <input
              type="email"
              placeholder="Email Address"
              maxLength={100}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prevData) => ({ ...prevData, email: "" }));
              }}
              disabled={isOTPSent ? true : false}
              className={errors.email && "error-text"}
            />
            {errors.email && (
              <div className="error text-danger">{errors.email}</div>
            )}
            <br />
            {isOTPSent && (
              <>
                <input
                  type="text"
                  placeholder="Verify OTP"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                  required
                  onKeyPress={(e) => {
                    if (e.charCode === 32 && e.target.selectionStart === 0) {
                      e.preventDefault(); // Prevent adding space at the beginning
                    }
                  }}
                />
                <small className="pt-0">
                  <strong className="text-danger">
                    Note: The OTP can only be used once and will expire in 30
                    minutes.
                  </strong>
                </small>
                <small>
                  <a
                    onClick={isLinkActive ? handleSubmit : null}
                    style={{ cursor: isLinkActive ? "pointer" : "not-allowed" }}
                    className={isLinkActive ? "text-primary" : "text-seconadry"}
                  >
                    Resend the OTP
                  </a>
                  {!isLinkActive && (
                    <span className="text-primary ms-1">
                      <a style={{ pointerEvents: "none" }}>
                        {formatTime(timeLeft)}
                      </a>
                    </span>
                  )}
                </small>
              </>
            )}
            <button
              type="submit"
              form="passwordForm"
              className={isLoading ? "theme-button disabled " : "theme-button "}
            >
              {isOTPSent ? "Verify OTP" : "Submit"}
            </button>
            <a href="/" className="forgot-password">
              Back to Login
            </a>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPassword;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email address is required")
    .matches(/^$|.+@.+\..+/, "Invalid email address"),
});
