import { useEffect, useState } from "react";
import {
  API_CREATE_DESIGNATION,
  API_UPDATE_DESIGNATION,
  GET_DEPARTMENT_LIST,
  GET_DESIGNATION_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import Footer from "../../components/Footer";
import { getApi, postApi, putApi } from "../../utils/api";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";

const Designationlist = ({ title, description }) => {
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [departmentId, setDepartmentId] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState({});
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  const fetchDepartmentList = () => {
    setIsLoading(true);
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const departmentListOption = departmentList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleDepartment = (e) => {
    setDepartmentId(e);
    setDesignationList([]);
    getApi(GET_DESIGNATION_LIST + `?departmentId=${e.value}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDesignationList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleReset = () => {
    setFormData({
      name: "",
    });
    setSelectedDesignation({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingSubmit(true);
      const requestData = {
        name: formData.name,
        status: formData.status,
        departmentId: departmentId.value,
      };

      await putApi(API_CREATE_DESIGNATION, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            await handleDepartment(departmentId);
            setIsLoadingSubmit(false);
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            toast.success(res?.data?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoadingSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        name: formData.name,
        status: formData.status,
        id: selectedDesignation?.id,
      };
      setIsLoadingSubmit(true);

      await postApi(API_UPDATE_DESIGNATION, requestData, accessToken)
        .then(async (res) => {
          if (res?.data?.data) {
            await handleDepartment(departmentId);
            setIsLoadingSubmit(false);
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
            toast.success(res?.data?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoadingSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const handleView = (item) => {
    setSelectedDesignation(item);
    setFormData({
      name: item?.name,
    });
  };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="mt-5">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Department</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={departmentListOption}
                    onChange={handleDepartment}
                    value={departmentId}
                    placeholder="Select Department"
                    isSearchable={true}
                    isDisabled={departmentList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>
      {/* Filters */}

      {departmentId && (
        <div className="filters">
          {/* Actions */}
          <div className="actions">
            <Link
              className="export theme-button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropAddDesignation"
            >
              + Add Designation
            </Link>
          </div>
        </div>
      )}

      {designationList?.length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Designation</th>
                <th>Creation Date</th>
                {/* <th>Last Modified Date</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {designationList &&
                designationList.map((item, index) => (
                  <tr key={index}>
                    <td data-title="Serial_No">{index + 1}</td>
                    <td data-title="Name">{item.name}</td>
                    <td data-title="creationDate">
                      {moment(item.creationDate).format("DD-MM-yyyy")}
                    </td>
                    {/* <td data-title="lastModifiedDate">
                      {moment(item.lastModifiedDate).format("DD-MM-yyyy")}
                    </td> */}
                    <td data-title="Phone">{item.status}</td>

                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleView(item)}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropAddDesignation"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropAddDesignation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropAddDesignation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  {selectedDesignation?.id ? "Update " : "Add "} Designation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <form
                onSubmit={selectedDesignation?.id ? handleUpdate : handleSubmit}
              >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label className="has-asterisk">Name</label>
                    </div>

                    <div className="col-md-5">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter Designation Name"
                        required
                        className="border"
                        maxLength={150}
                        onKeyPress={(e) => {
                          if (
                            e.charCode === 32 &&
                            e.target.selectionStart === 0
                          ) {
                            e.preventDefault(); // Prevent adding space at the beginning
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <label htmlFor="repairCost" className="has-asterisk">
                        Status
                      </label>
                    </div>
                    <div className="col-md-5 ">
                      <div className="input-group">
                        <select
                          id="status"
                          value={formData.status}
                          required
                          onChange={handleInputChange}
                          name="status"
                          className="border"
                        >
                          <option value="">Select Status</option>
                          <option value="VERIFIED">Verified</option>
                          <option value="PENDING">Pending</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div> */}
                </div>
                <div className="justify-content-center modal-footer">
                  <button
                    type="submit"
                    className={
                      isLoadingSubmit
                        ? "theme-button disabled "
                        : "theme-button "
                    }
                  >
                    {isLoadingSubmit ? (
                      <ThreeDots
                        height="20"
                        width="53"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : selectedDesignation?.id ? (
                      "Update "
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    type="button"
                    className="theme-button bg-grey mx-3 w-35 cancel-modal"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Designationlist;
