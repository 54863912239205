import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getApi, postApi } from "../../utils/api";
import {
  API_EMPLOYEE_DOCUMENT,
  API_ROLE,
  GET_EMPLOYEE_LIST,
  MENU,
  MENU_ALL,
  MENU_ASSIGNMENT,
  MENU_FIND,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";

const MenuAssign = ({title, description}) => {
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleselect, setSelectedRoleSelect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [assignedMenuList, setAssignedMenuList] = useState([]);
  const accessToken = Cookies.get("accessToken");
  const [checkedItems, setCheckedItems] = useState({});
  useEffect(() => {
    fetchList();
    fetchMenuList();
  }, []);

  const fetchList = () => {
    getApi(API_ROLE, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setRoleList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchMenuList = () => {
    getApi(MENU_ALL, accessToken)
      .then((res) => {
        if (res.data.code === "200") {
          setMenuList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const optionList = roleList?.map((item) => ({
    value: item.roleId,
    label: item.name,
  }));

  const handleChangeRole = (e) => {
    setIsLoading(true);
    setIsUpdated(false);
    let roleId = e.value;
    setSelectedRoleId(roleId);
    setSelectedRoleSelect(e);
    setCheckedItems({});
    getApi(MENU_FIND + `/${roleId}`, accessToken)
      .then((res) => {
        if (res.data.code === "200" && res.data.data !== null) {
          setAssignedMenuList(res.data.data);
          setMenuList(res.data.data);
          const menuItems = res.data.data;
          const updatedCheckedItems = {};
          // Iterate through menuItems
          menuItems.forEach((menuItem) => {
            if (menuItem.assigned) {
              updatedCheckedItems[menuItem.id] = true;
            }
            menuItem?.subMenu?.forEach((submenuItem) => {
              if (submenuItem.assigned) {
                updatedCheckedItems[submenuItem.id] = true;
              }
            });
          });

          // Update the checkedItems state
          setCheckedItems(updatedCheckedItems);
        } else {
          setCheckedItems({});
        }
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    // setSelectedRoleId("");
    // setSelectedRoleSelect("");
    setCheckedItems({});
    // setMenuList([]);
  };

  function transformCheckedItems(checkedItems) {
    const result = [];
    for (const [id, checked] of Object.entries(checkedItems)) {
      if (checked) {
        result.push({ id: parseInt(id), operation: "assign" });
      } else {
        result.push({ id: parseInt(id), operation: "remove" });
      }
    }
    return result;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const transformedArray = transformCheckedItems(checkedItems);

      for (const menuItem of menuList) {
        if (menuItem.subMenu && menuItem.subMenu.length > 0) {
          const allSubmenusChecked = menuItem.subMenu.some(
            (submenu) => checkedItems[submenu.id]
          );
          if (!allSubmenusChecked && checkedItems[menuItem.id]) {
            setIsLoading(false);
            toast.error(
              `Please select at least one submenu for ${menuItem.name}.`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            return;
          }
        }
      }
      setIsLoading(true);

      let requestObj = {
        roleId: selectedRoleId,
        menuList: transformedArray,
      };

      await postApi(MENU_ASSIGNMENT, requestObj, accessToken)
        .then((res) => {
          setIsLoading(false);
          toast.success(" Successfully Updated.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Please try again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  // Handler for checkbox changes
  const handleCheckboxChange = (e, itemName) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemName]: !prevCheckedItems[itemName],
    }));
  };
  // const handleCheckboxChange = (e, id) => {
  //   const { checked } = e.target;

  //   if (!checked && menuList.some((menuItem) => menuItem.id === id)) {
  //     const subMenuItems = menuList.find(
  //       (menuItem) => menuItem.id === id
  //     )?.subMenu;
  //     if (subMenuItems) {
  //       const allSubMenuItemsUnselected = subMenuItems.every(
  //         (submenu) => !checkedItems[submenu.id]
  //       );
  //       if (allSubMenuItemsUnselected) {
  //         setCheckedItems((prevCheckedItems) => {
  //           const newCheckedItems = { ...prevCheckedItems };
  //           delete newCheckedItems[id];
  //           return newCheckedItems;
  //         });
  //       } else {
  //         setCheckedItems((prevCheckedItems) => {
  //           const newCheckedItems = { ...prevCheckedItems };
  //           subMenuItems.forEach((submenu) => {
  //             newCheckedItems[submenu.id] = false;
  //           });
  //           return newCheckedItems;
  //         });
  //       }
  //     }
  //   } else {
  //     setCheckedItems((prevCheckedItems) => {
  //       const newCheckedItems = { ...prevCheckedItems };
  //       newCheckedItems[id] = checked;
  //       return newCheckedItems;
  //     });
  //   }
  // };

  return (
    <div className="wrapper-body">
      {isLoading && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <PageTitle title={title} description={description} /> */}
      </div>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Menu Assignment</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    onChange={handleChangeRole}
                    value={selectedRoleselect}
                    placeholder="Select Role"
                    isSearchable={true}
                    isDisabled={roleList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </form>
        {menuList?.length > 0 && selectedRoleId && (
          <form
            id="assign-asset d-flex"
            className="mt-5"
            onSubmit={handleSubmit}
          >
            <div className="inputs">
              {menuList?.map((menuItem, index) => (
                <div key={index} className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    {/* Main Menu Checkbox */}
                    <input
                      type="checkbox"
                      id={`check_${menuItem.name.replace(/\s+/g, "")}`}
                      checked={checkedItems[menuItem.id]}
                      value={menuItem.id}
                      onChange={(e) => handleCheckboxChange(e, menuItem.id)}
                    />
                    <label
                      htmlFor={`check_${menuItem.name.replace(/\s+/g, "")}`}
                      className="mx-3"
                    >
                      {menuItem.name}
                    </label>

                    {/* Submenu Checkboxes */}
                    <div className="ms-4">
                      {menuItem?.subMenu?.map((submenu, submenuIndex) => (
                        <div key={submenuIndex}>
                          <input
                            type="checkbox"
                            id={`check_${submenu.name.replace(/\s+/g, "")}`}
                            checked={checkedItems[submenu.id]}
                            value={submenu.id}
                            onChange={(e) =>
                              handleCheckboxChange(e, submenu.id)
                            }
                            disabled={!checkedItems[menuItem.id]} // Disable submenu if main menu is not checked
                          />
                          <label
                            htmlFor={`check_${submenu.name.replace(
                              /\s+/g,
                              ""
                            )}`}
                            className="mx-3"
                          >
                            {submenu.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="action text-end">
                  {/* <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset
                  </button> */}
                  {!isUpdated && (
                    <button
                      className={
                        isLoading
                          ? "theme-button disabled ms-2 "
                          : "theme-button ms-2 "
                      }
                      type="submit"
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="40"
                          radius="2"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  )}
                </div>
                {isUpdated && (
                  <div className="text-center my-3 fs-1">
                    <span className="badge badge-success fs-5">
                      <strong>
                        The menu has been successfully assigned to{" "}
                        {selectedRoleselect.label} .
                      </strong>
                    </span>
                  </div>
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
          </form>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default MenuAssign;
