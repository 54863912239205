import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getApi, postApi } from "../../utils/api";
import {
  ADMIN_UPLOAD_USER_PHOTO,
  API_USER_UPDATE_EMPLOYEE,
  USER_PROFILE,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import Salary from "../Employee/Salary";
import WorkExperience from "../Employee/WorkExperience";
import EmployeeAsset from "../Employee/EmployeeAsset";
import OrgChartExample from "../OrgChartExample";
import IndexChart from "../org-diagram";
import Education from "../Employee/Education";
import Dependents from "../Employee/Dependent";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import EmployeeType from "./EmployeeType";
import EmployeeAccess from "./EmployeeAccess";
import EditEmployee from "./EditEmployee";
import moment from "moment";
import PageTitle from "../../components/PageTitle";

const EmployeeProfile = ({ title, description }) => {
  const { state } = useLocation();
  const { data } = state || {};
  const [userData, setUserData] = useState(data ? data : {});
  const accessToken = Cookies.get("accessToken");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isBasicInfoEdit, setIsBasicInfoEdit] = useState(false);
  const [isWorkEdit, setIsWorkEdit] = useState(false);
  const [isPersonalDetailEdit, setIsPersonalDetailEdit] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    bloodGroup: "",
    dob: "",
    employeeSubType: "",
    aadharNumber: "",
    panCardNumber: "",
    passportNumber: "",
    gender: "",
    joiningDate: "",
    maritalStatus: "",
    anniversaryDate: "",
    phoneNumber: "",
    alternateNumber: "",
    presentAddress: "",
    permanentAddress: "",
    personalEmail: "",
    alternateName: "",
    relationWithAlternateNo: "",
    aadharNumber: "",
    panCardNumber: "",
    passportNumber: "",
  });
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData?.personalDetail?.firstName,
        middleName: userData?.personalDetail?.middleName,
        lastName: userData?.personalDetail?.lastName,
        bloodGroup: userData?.personalDetail?.bloodGroup,
        dob: moment(userData?.personalDetail?.dob, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        aadharNumber: userData?.personalDetail?.aadharNum,
        employeeSubType: userData?.personalDetail?.employeeSubType,
        panCardNumber: userData?.personalDetail?.panNum,
        passportNumber: userData?.personalDetail?.passportNum,
        gender: userData?.personalDetail?.gender,
        joiningDate: moment(userData?.personalDetail?.doj, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        maritalStatus: userData?.personalDetail?.maritalStatus,
        anniversaryDate: userData?.personalDetail?.anniversaryDate,
        phoneNumber: userData?.personalDetail?.phoneNumber,
        alternateNumber: userData?.personalDetail?.alternateNumber,
        presentAddress: userData?.personalDetail?.presentAddress,
        permanentAddress: userData?.personalDetail?.permanentAddress,
        personalEmail: userData?.personalDetail?.ccEmail,
        alternateName: userData?.personalDetail?.alternateName,
        relationWithAlternateNo:
          userData?.personalDetail?.relationWithAlternateNo,
      });
    }
  }, [userData]);

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    setErrors("");
    if (type === "date") {
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  // Get today's date in the format yyyy-mm-dd
  const today = new Date().toISOString().split("T")[0];
  // Function to get the minimum selectable date (today's date - 30 days)
  function getMinDate() {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );
    return minDate.toISOString().split("T")[0];
  }

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/jpg, image/png"; // Accepts only JPEG, JPG, and PNG files
    const acceptedFileTypes = ["image/jpeg", "image/png", "image/jpeg"];

    input.onchange = (event) => {
      const selectedFile = event.target.files[0];
      const maxFileSize = 8 * 1024 * 1024; // 8 MB in bytes

      if (!acceptedFileTypes.includes(selectedFile.type)) {
        // File type is not supported
        toast.error(
          "Unsupported file type. Please select a valid image (PNG, JPEG and JPG) file.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        event.target.value = null; // Clear the file input
      } else if (selectedFile.size > maxFileSize) {
        // File size exceeds the maximum allowed size
        toast.error("File size exceeds the maximum limit of 8MB.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        event.target.value = null; // Clear the file input
      } else {
        setSelectedImageFile(selectedFile);
        setSelectedImage(URL.createObjectURL(selectedFile));
      }
    };
    input.click();
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      setIsImageLoading(true);
      const formData = new FormData();
      formData.append("photo", selectedImageFile);
      await postApi(
        ADMIN_UPLOAD_USER_PHOTO + `?employeeId=${userData?.personalDetail?.id}`,
        formData,
        accessToken
      )
        .then((res) => {
          if (res.status === 200) {
            setIsImageLoading(false);
            toast.success("Profile Upload Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setSelectedImageFile(null);
          } else {
            setIsImageLoading(false);
          }
        })
        .catch((e) => {
          console.error("Upload error:", e);
          setIsImageLoading(false);
        });
    } else {
      toast.error("No image selected.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (data) {
      fetchUser();
    }
  }, [data]);

  const fetchUser = () => {
    setIsLoading(true);
    getApi(USER_PROFILE + "?employeeId=" + data, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleEditCollapse = () => {
    setIsPersonalDetailEdit(false);
    setIsWorkEdit(false);
    setIsBasicInfoEdit(false);
  };

  const handleUpdateUserDetails = async (e) => {
    e.preventDefault();
    let requestData = {
      aadharNum: formData.aadharNumber,
      alternateNumber: formData.alternateNumber,
      anniversaryDate: formData.anniversaryDate,
      bloodGroup: formData.bloodGroup,
      dob: moment(formData.dob).format("yyyy-MM-DD"),
      doj: moment(formData.joiningDate).format("yyyy-MM-DD"),
      firstName: formData.firstName,
      gender: formData.gender,
      lastName: formData.lastName,
      maritalStatus: formData.maritalStatus,
      employeeSubType: formData.employeeSubType,
      middleName: formData.middleName,
      panNum: formData.panCardNumber,
      passportNum: formData.passportNumber,
      permanentAddress: formData.permanentAddress,
      personalEmail: formData.personalEmail,
      phoneNumber: formData.phoneNumber,
      presentAddress: formData.presentAddress,
      alternateName: formData?.alternateName,
      relationWithAlternateNo: formData?.relationWithAlternateNo,
    };

    setIsLoadingUpdate(true);

    await postApi(
      API_USER_UPDATE_EMPLOYEE + "?employeeId=" + userData?.personalDetail?.id,
      requestData,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            res.data.message
              ? res.data.message
              : "Employee updated successfully !",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );

          setUserData(res.data.data);
          handleEditCollapse();
          // fetchList();

          setIsLoadingUpdate(false);
        } else {
          setIsLoadingUpdate(false);
        }
        // else {
        //   setIsError(true);
        //   setIsLoading(false);
        //   setError("Something went wrong. Please try again!");
        //   setTimeout(() => {
        //     setIsError(false);
        //   }, 4000);
        // }
      })
      .catch((e) => {
        setIsLoadingUpdate(false);

        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleClose = () => {
    if (userData) {
      setFormData({
        firstName: userData?.personalDetail?.firstName,
        middleName: userData?.personalDetail?.middleName,
        lastName: userData?.personalDetail?.lastName,
        bloodGroup: userData?.personalDetail?.bloodGroup,
        dob: moment(userData?.personalDetail?.dob, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        aadharNumber: userData?.personalDetail?.aadharNum,
        employeeSubType: userData?.personalDetail?.employeeSubType,
        panCardNumber: userData?.personalDetail?.panNum,
        passportNumber: userData?.personalDetail?.passportNum,
        gender: userData?.personalDetail?.gender,
        joiningDate: moment(userData?.personalDetail?.doj, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        maritalStatus: userData?.personalDetail?.maritalStatus,
        anniversaryDate: userData?.personalDetail?.anniversaryDate,
        phoneNumber: userData?.personalDetail?.phoneNumber,
        alternateNumber: userData?.personalDetail?.alternateNumber,
        presentAddress: userData?.personalDetail?.presentAddress,
        permanentAddress: userData?.personalDetail?.permanentAddress,
        personalEmail: userData?.personalDetail?.ccEmail,
        alternateName: userData?.personalDetail?.alternateName,
        relationWithAlternateNo:
          userData?.personalDetail?.relationWithAlternateNo,
      });
    }
  };

  return (
    <>
      <div className="wrapper-body">
        <div className="page-heading">
          <div
            id="showMenuBtn"
            className="collapse-button"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <PageTitle title={title} description={description} />
        </div>
        {isLoading && (
          <div className="centered-loader">
            <ThreeDots
              height="100"
              width="100"
              radius="9"
              color="blue"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {!isLoading && (
          // <form id="assign-asset">
          <div className="card">
            <div className="user-type-tag">
              <span
                className={`badge bg-${
                  userData?.personalDetail?.employeeType === "REGULAR"
                    ? "success"
                    : "primary"
                }`}
              >
                {userData?.personalDetail?.employeeType}
              </span>
            </div>
            <div className="row">
              <div className="col-md-2 border-right">
                <div className="d-flex flex-column align-items-center text-center p-3 py-2">
                  {!isImageLoading && (
                    <img
                      className="employee-profile"
                      width="150px"
                      src={`${
                        selectedImage
                          ? selectedImage
                          : !userData?.personalDetail?.photoPath.includes(
                              "photoDir"
                            )
                          ? "../assets/profile.png"
                          : process.env.REACT_APP_IMAGE_URL +
                            userData?.personalDetail?.photoPath
                      }`}
                      onClick={handleImageClick}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-7">
                <div className="d-flex flex-column  p-1 py-3">
                  <h4>
                    <b>{userData?.personalDetail?.empName}</b>
                  </h4>
                  <span className="mt-1">
                    - {userData?.personalDetail?.designation}
                  </span>
                  {userData?.personalDetail?.ccEmail && (
                    <span className="text-black font-small mt-1">
                      <a href="mailto:manjeet.saini@caeliusconsulting.com">
                        {userData?.personalDetail?.ccEmail}
                      </a>
                    </span>
                  )}
                  {userData?.personalDetail?.phoneNumber && (
                    <span className="text-black font-small ">
                      <a href="tel:123-456-7890">
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        <span className="ms-2">
                          {userData?.personalDetail?.phoneNumber}
                        </span>
                      </a>
                    </span>
                  )}

                  {selectedImage && selectedImageFile && (
                    <button
                      className="btn btn-primary btn-sm w-50"
                      onClick={handleUploadClick}
                    >
                      Upload Image
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-2">
                {/* <a
                    className="dropdown-item btn-primary"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropEmp"
                  >
                    Edit
                  </a> */}
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-5">
              <div className="container">
                <ul className="nav nav-tabs" id="myTabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                      type="button"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      General Information
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      type="button"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      Job
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      type="button"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Leave
                    </button>
                  </li> */}
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      type="button"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Assigned Assets
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab5"
                      type="button"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      Performance
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab6-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab6"
                      type="button"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                    >
                      Permission
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab7-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab7"
                      type="button"
                      role="tab"
                      aria-controls="tab7"
                      aria-selected="false"
                    >
                      Documents
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab8-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab8"
                      type="button"
                      role="tab"
                      aria-controls="tab8"
                      aria-selected="false"
                    >
                      Salary
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab9-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab9"
                      type="button"
                      role="tab"
                      aria-controls="tab9"
                      aria-selected="false"
                    >
                      Organization Chart
                    </button>
                  </li> */}
                  {userData?.personalDetail?.employeeType !== "REGULAR" && (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tab10-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tab10"
                        type="button"
                        role="tab"
                        aria-controls="tab10"
                        aria-selected="false"
                      >
                        Employee Type
                      </button>
                    </li>
                  )}
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="tab11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab11"
                      type="button"
                      role="tab"
                      aria-controls="tab11"
                      aria-selected="false"
                    >
                      Employee Access
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabsContent">
                  <div
                    className="tab-pane fade show active mt-3"
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="tab1-tab"
                  >
                    <div className="accordion" id="accordion1">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading1">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse1"
                            aria-expanded="false"
                            aria-controls="collapse1"
                            onClick={handleEditCollapse}
                          >
                            Basic info
                          </button>
                        </h2>
                        <div
                          id="collapse1"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading1"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-10"></div>
                              <div className="col-md-2">
                                {!isBasicInfoEdit && (
                                  <a
                                    style={{
                                      float: "right",
                                      fontSize: "18px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setIsBasicInfoEdit(!isBasicInfoEdit)
                                    }
                                  >
                                    <i class="fa fa-edit"></i>
                                  </a>
                                )}
                              </div>
                            </div>
                            {!isBasicInfoEdit && (
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>First Name</p>
                                    <p>Last Name</p>
                                    <p>Employee Id</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>{userData?.personalDetail?.firstName}</p>
                                    <p>{userData?.personalDetail?.lastName}</p>
                                    <p>
                                      {userData?.personalDetail?.employeeId}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>Middle Name</p>
                                    <p>Email</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>
                                      {userData?.personalDetail?.middleName
                                        ? userData?.personalDetail?.middleName
                                        : "-"}
                                    </p>

                                    <p>{userData?.personalDetail?.ccEmail}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {isBasicInfoEdit && (
                              <form onSubmit={handleUpdateUserDetails}>
                                <div className="row mt-3">
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>First Name</p>
                                      <p className="mt-1">Last Name</p>
                                      <p className="mt-1">Employee Id</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          name="firstName"
                                          className="border"
                                          value={formData.firstName}
                                          onChange={handleInputChange}
                                          required
                                          onKeyPress={(e) => {
                                            const inputValue =
                                              String.fromCharCode(e.charCode);
                                            if (
                                              (e.charCode === 32 &&
                                                e.target.selectionStart ===
                                                  0) ||
                                              (inputValue.match(/^\d+$/) &&
                                                e.target.selectionStart === 0)
                                            ) {
                                              e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="input-group mt-1 ">
                                        <input
                                          type="text"
                                          name="lastName"
                                          value={formData.lastName}
                                          className="border"
                                          onChange={handleInputChange}
                                          required
                                          onKeyPress={(e) => {
                                            const inputValue =
                                              String.fromCharCode(e.charCode);
                                            if (
                                              (e.charCode === 32 &&
                                                e.target.selectionStart ===
                                                  0) ||
                                              (inputValue.match(/^\d+$/) &&
                                                e.target.selectionStart === 0)
                                            ) {
                                              e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                            }
                                          }}
                                        />
                                      </div>
                                      <p>
                                        {userData?.personalDetail?.employeeId}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>Middle Name</p>
                                      <p>Email</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <div className="input-group ">
                                        <input
                                          type="text"
                                          name="middleName"
                                          value={formData.middleName}
                                          className="border"
                                          onChange={handleInputChange}
                                          onKeyPress={(e) => {
                                            const inputValue =
                                              String.fromCharCode(e.charCode);
                                            if (
                                              (e.charCode === 32 &&
                                                e.target.selectionStart ===
                                                  0) ||
                                              (inputValue.match(/^\d+$/) &&
                                                e.target.selectionStart === 0)
                                            ) {
                                              e.preventDefault(); // Prevent adding space at the beginning  of the string or numbers only
                                            }
                                          }}
                                        />
                                      </div>
                                      <p>{userData?.personalDetail?.ccEmail}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-10"></div>
                                  <div className="col-md-2">
                                    {isBasicInfoEdit && (
                                      <div className="action d-flex justify-content-end">
                                        <button
                                          type="submit"
                                          className={
                                            isLoading
                                              ? "btn btn-primary btn-sm disabled"
                                              : "btn btn-primary btn-sm"
                                          }
                                          onClick={(e) =>
                                            isLoadingUpdate
                                              ? e.preventDefault()
                                              : null
                                          }
                                          style={{
                                            marginLeft: "16px",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {isLoadingUpdate ? (
                                            <ThreeDots
                                              height="20"
                                              width="53"
                                              radius="9"
                                              color="white"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-secondary btn-sm ms-2"
                                          }
                                          style={{
                                            float: "right",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setIsBasicInfoEdit(
                                              !isBasicInfoEdit
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Close
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading2">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse2"
                            aria-expanded="false"
                            aria-controls="collapse2"
                            onClick={handleEditCollapse}
                          >
                            Work
                          </button>
                        </h2>
                        <div
                          id="collapse2"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading2"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-10"></div>
                              <div className="col-md-2">
                                {!isWorkEdit && (
                                  <a
                                    style={{
                                      float: "right",
                                      fontSize: "18px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setIsWorkEdit(!isWorkEdit)}
                                  >
                                    <i class="fa fa-edit"></i>
                                  </a>
                                )}
                              </div>
                            </div>
                            {!isWorkEdit && (
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>Department</p>
                                    <p>Designation</p>
                                    <p>Reporting To</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>
                                      {userData?.personalDetail?.department
                                        ? userData?.personalDetail?.department
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.designation
                                        ? userData?.personalDetail?.designation
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.managerName
                                        ? userData?.personalDetail?.managerName
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>Date of Joining</p>
                                    <p>Employee Status</p>
                                    <p>Employee Type</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>
                                      {userData?.personalDetail?.doj
                                        ? userData?.personalDetail?.doj
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.status
                                        ? userData?.personalDetail?.status
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.employeeSubType
                                        ? userData?.personalDetail
                                            ?.employeeSubType
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {isWorkEdit && (
                              <form onSubmit={handleUpdateUserDetails}>
                                <div className="row mt-3">
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>Department</p>
                                      <p>Designation</p>
                                      <p>Reporting To</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <p>
                                        {userData?.personalDetail?.department
                                          ? userData?.personalDetail?.department
                                          : "-"}
                                      </p>
                                      <p>
                                        {userData?.personalDetail?.designation
                                          ? userData?.personalDetail
                                              ?.designation
                                          : "-"}
                                      </p>
                                      <p>
                                        {userData?.personalDetail?.managerName
                                          ? userData?.personalDetail
                                              ?.managerName
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>Date of Joining</p>
                                      <p>Employee Status</p>
                                      <p>Employee Type</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <div className="input-group">
                                        <input
                                          type="date"
                                          name="joiningDate"
                                          value={formData.joiningDate}
                                          onChange={handleInputChange}
                                          min={getMinDate()}
                                          className="border"
                                          required
                                        />
                                      </div>
                                      <p>
                                        {userData?.personalDetail?.status
                                          ? userData?.personalDetail?.status
                                          : "-"}
                                      </p>
                                      <p>
                                        <select
                                          className="border"
                                          name="employeeSubType"
                                          value={formData.employeeSubType}
                                          onChange={handleInputChange}
                                          required
                                        >
                                          <option value="">
                                            Select Employee Sub Type
                                          </option>
                                          <option value="Fulltime">
                                            Full time
                                          </option>
                                          <option value="Intern">Intern</option>
                                        </select>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-10"></div>
                                  <div className="col-md-2">
                                    {isWorkEdit && (
                                      <div className="action d-flex justify-content-end">
                                        <button
                                          type="submit"
                                          className={
                                            isLoading
                                              ? "btn btn-primary btn-sm disabled"
                                              : "btn btn-primary btn-sm"
                                          }
                                          style={{
                                            marginLeft: "16px",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {isLoadingUpdate ? (
                                            <ThreeDots
                                              height="20"
                                              width="53"
                                              radius="9"
                                              color="white"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-secondary btn-sm ms-2"
                                          }
                                          style={{
                                            float: "right",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setIsWorkEdit(!isWorkEdit);
                                            handleClose();
                                          }}
                                        >
                                          Close
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse3"
                            aria-expanded="false"
                            aria-controls="collapse3"
                            onClick={handleEditCollapse}
                          >
                            Personal Details
                          </button>
                        </h2>
                        <div
                          id="collapse3"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading3"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-10"></div>
                              <div className="col-md-2">
                                {!isPersonalDetailEdit && (
                                  <a
                                    style={{
                                      float: "right",
                                      fontSize: "18px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setIsPersonalDetailEdit(
                                        !isPersonalDetailEdit
                                      )
                                    }
                                  >
                                    <i class="fa fa-edit"></i>
                                  </a>
                                )}
                              </div>
                            </div>
                            {!isPersonalDetailEdit && (
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>Date of Birth</p>
                                    <p>Aadhar Card Number</p>
                                    <p>Passport Number</p>
                                    <p>PAN Number</p>
                                    <p>Present Address</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>
                                      {userData?.personalDetail?.dob
                                        ? userData?.personalDetail?.dob
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.aadharNum
                                        ? userData?.personalDetail?.aadharNum
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.passportNum
                                        ? userData?.personalDetail?.passportNum
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.panNum
                                        ? userData?.personalDetail?.panNum
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.presentAddress
                                        ? userData?.personalDetail
                                            ?.presentAddress
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column  text-black-50">
                                    <p>Blood Group</p>
                                    <p>Gender</p>
                                    <p>Marital Status</p>
                                    <p>Alternate Number</p>
                                    <p>Permanent Address</p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="d-flex flex-column">
                                    <p>
                                      {userData?.personalDetail?.bloodGroup
                                        ? userData?.personalDetail?.bloodGroup
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.gender
                                        ? userData?.personalDetail?.gender
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.maritalStatus
                                        ? userData?.personalDetail
                                            ?.maritalStatus
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail?.alternateNumber
                                        ? userData?.personalDetail
                                            ?.alternateNumber
                                        : "-"}
                                    </p>
                                    <p>
                                      {userData?.personalDetail
                                        ?.permanentAddress
                                        ? userData?.personalDetail
                                            ?.permanentAddress
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {isPersonalDetailEdit && (
                              <form onSubmit={handleUpdateUserDetails}>
                                <div className="row mt-3">
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>Date of Birth</p>
                                      <p>Aadhar Card Number</p>
                                      <p>Passport Number</p>
                                      <p>PAN Number</p>
                                      <p>Present Address</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <div className="input-group">
                                        <input
                                          type="date"
                                          required
                                          name="dob"
                                          value={formData.dob}
                                          onChange={handleInputChange}
                                          max={today}
                                          className="border"
                                        />
                                        {errors.dob && (
                                          <small className="text-danger">
                                            {errors.dob}
                                          </small>
                                        )}
                                      </div>

                                      <div className="input-group mt-1">
                                        <input
                                          type="tel"
                                          required
                                          minLength="12"
                                          maxLength="12"
                                          name="aadharNumber"
                                          className="border"
                                          value={formData.aadharNumber}
                                          onChange={(e) => {
                                            const input = e.target;
                                            if (input.value.length < 12) {
                                              input.setCustomValidity(
                                                "Please enter a 12-digit Aadhar card number"
                                              );
                                            } else if (
                                              input.value.length > 12
                                            ) {
                                              input.setCustomValidity(
                                                "Aadhar card number should not exceed 12 digits"
                                              );
                                            } else {
                                              input.setCustomValidity("");
                                            }
                                            handleInputChange(e);
                                          }}
                                          placeholder="XXXX-XXXX-XXXX"
                                          title="Aadhar Number must be a 12-digit number"
                                          onKeyPress={(e) => {
                                            const isDigit = /\d/.test(e.key);
                                            const isLengthValid =
                                              e.target.value.length < 13;

                                            if (!isDigit || !isLengthValid) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onPaste={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {errors.aadharNumber && (
                                          <small className="text-danger">
                                            {errors.aadharNumber}
                                          </small>
                                        )}
                                      </div>
                                      <div className="input-group mt-1">
                                        <input
                                          type="text"
                                          maxLength="12"
                                          minLength="12"
                                          name="passportNumber"
                                          className="border"
                                          value={formData.passportNumber}
                                          onChange={(e) => {
                                            const input = e.target;
                                            if (input.value.length < 12) {
                                              input.setCustomValidity(
                                                "Please enter a 12-digit Passport number"
                                              );
                                            } else if (
                                              input.value.length > 12
                                            ) {
                                              input.setCustomValidity(
                                                "Passport number should not exceed 12 digits"
                                              );
                                            } else {
                                              input.setCustomValidity("");
                                            }
                                            handleInputChange(e);
                                          }}
                                          placeholder="XXXX-XXXX-XXXX"
                                          onKeyPress={(e) => {
                                            if (
                                              e.charCode === 32 &&
                                              e.target.selectionStart === 0
                                            ) {
                                              e.preventDefault(); // Prevent adding space at the beginning
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="input-group mt-1">
                                        <input
                                          type="text"
                                          required
                                          name="panCardNumber"
                                          maxLength="10"
                                          minLength="10"
                                          className="border"
                                          value={formData.panCardNumber}
                                          onChange={(e) => {
                                            const input = e.target;
                                            if (input.value.length < 10) {
                                              input.setCustomValidity(
                                                "Please enter a 10-digit PAN card number"
                                              );
                                            } else if (
                                              input.value.length > 10
                                            ) {
                                              input.setCustomValidity(
                                                "PAN card number should not exceed 10 digits"
                                              );
                                            } else {
                                              input.setCustomValidity("");
                                            }
                                            handleInputChange(e);
                                          }}
                                          placeholder="XXX-XXX-XXXX"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                          }}
                                          onKeyPress={(e) => {
                                            if (
                                              e.charCode === 32 &&
                                              e.target.selectionStart === 0
                                            ) {
                                              e.preventDefault(); // Prevent adding space at the beginning
                                            }
                                          }}
                                        />
                                        {errors.panCardNumber && (
                                          <small className="text-danger">
                                            {errors.panCardNumber}
                                          </small>
                                        )}
                                      </div>
                                      <div className="input-group mt-1">
                                        <textarea
                                          name="presentAddress"
                                          value={formData.presentAddress}
                                          onChange={handleInputChange}
                                          className="border"
                                          required
                                          onKeyPress={(e) => {
                                            // Prevent adding space at the beginning
                                            if (
                                              e.charCode === 32 &&
                                              e.target.selectionStart === 0
                                            ) {
                                              e.preventDefault();
                                            }

                                            // Regular expression to allow only letters, numbers, and spaces
                                            const allowedCharsRegex =
                                              /^[a-zA-Z0-9 ]*$/;

                                            // Check if the character is allowed
                                            const char = String.fromCharCode(
                                              e.charCode
                                            );
                                            if (!allowedCharsRegex.test(char)) {
                                              e.preventDefault(); // Prevent non-alphanumeric character input
                                            }
                                          }}
                                          rows={4}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column  text-black-50">
                                      <p>Blood Group</p>
                                      <p>Gender</p>
                                      <p>Marital Status</p>
                                      <p>Alternate Number</p>
                                      <p>Permanent Address</p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="d-flex flex-column">
                                      <div className="input-group">
                                        <select
                                          id="bloodGroup"
                                          value={formData.bloodGroup}
                                          required
                                          onChange={handleInputChange}
                                          name="bloodGroup"
                                        >
                                          <option value="">
                                            Select Blood Group
                                          </option>
                                          <option value="A+ve">A+ve</option>
                                          <option value="A-ve">A-ve</option>
                                          <option value="B+ve">B+ve</option>
                                          <option value="B-ve">B-ve</option>
                                          <option value="AB+ve">AB+ve</option>
                                          <option value="AB-ve">AB-ve</option>
                                          <option value="O+ve">O+ve</option>
                                          <option value="O-ve">O-ve</option>
                                        </select>
                                      </div>
                                      <div className="input-group mt-1 mb-1">
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value="male"
                                            checked={formData.gender === "male"}
                                            onChange={handleInputChange}
                                          />
                                          <label htmlFor="male">Male</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            checked={
                                              formData.gender === "female"
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label htmlFor="female">Female</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            id="others"
                                            name="gender"
                                            value="others"
                                            required
                                            checked={
                                              formData.gender === "others"
                                            }
                                            onChange={handleInputChange}
                                          />
                                          <label htmlFor="others">Others</label>
                                        </div>
                                      </div>
                                      <div className="input-group mt-1">
                                        <select
                                          id="maritalStatus"
                                          value={formData.maritalStatus}
                                          required
                                          onChange={handleInputChange}
                                          name="maritalStatus"
                                        >
                                          <option value="">
                                            Select Marital Status
                                          </option>
                                          <option value="Single">Single</option>
                                          <option value="Married">
                                            Married
                                          </option>
                                          <option value="Widowed">
                                            Widowed
                                          </option>
                                          <option value="Separated">
                                            Separated
                                          </option>
                                          <option value="Divorced">
                                            Divorced
                                          </option>
                                        </select>
                                      </div>
                                      <div className="input-group mt-1">
                                        <input
                                          type="tel"
                                          name="alternateNumber"
                                          minLength="10"
                                          maxLength="10"
                                          value={formData.alternateNumber}
                                          onChange={handleInputChange}
                                          onKeyPress={(e) => {
                                            const regex = /^[0-9\b]+$/;
                                            if (!regex.test(e.key)) {
                                              e.preventDefault();
                                            }
                                          }}
                                          className="border"
                                          onPaste={(e) => {
                                            e.preventDefault();
                                          }}
                                          required
                                        />
                                      </div>
                                      <div className="input-group  mt-1">
                                        <textarea
                                          name="permanentAddress"
                                          value={formData.permanentAddress}
                                          onChange={handleInputChange}
                                          required
                                          className="border"
                                          rows={4}
                                          onKeyPress={(e) => {
                                            // Prevent adding space at the beginning
                                            if (
                                              e.charCode === 32 &&
                                              e.target.selectionStart === 0
                                            ) {
                                              e.preventDefault();
                                            }

                                            // Regular expression to allow only letters, numbers, and spaces
                                            const allowedCharsRegex =
                                              /^[a-zA-Z0-9 ]*$/;

                                            // Check if the character is allowed
                                            const char = String.fromCharCode(
                                              e.charCode
                                            );
                                            if (!allowedCharsRegex.test(char)) {
                                              e.preventDefault(); // Prevent non-alphanumeric character input
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-md-10"></div>
                                  <div className="col-md-2">
                                    {isPersonalDetailEdit && (
                                      <div className="action d-flex justify-content-end">
                                        <button
                                          type="submit"
                                          className={
                                            isLoading
                                              ? "btn btn-primary btn-sm disabled"
                                              : "btn btn-primary btn-sm"
                                          }
                                          onClick={(e) =>
                                            isLoadingUpdate
                                              ? e.preventDefault()
                                              : null
                                          }
                                          style={{
                                            marginLeft: "16px",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {isLoadingUpdate ? (
                                            <ThreeDots
                                              height="20"
                                              width="53"
                                              radius="9"
                                              color="white"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          ) : (
                                            "Update"
                                          )}
                                        </button>
                                        <button
                                          type="button"
                                          className={
                                            "btn btn-secondary btn-sm ms-2"
                                          }
                                          style={{
                                            float: "right",
                                            fontSize: "17px",
                                            fontWeight: "bolder",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setIsPersonalDetailEdit(
                                              !isPersonalDetailEdit
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Close
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            Work Experience
                          </button>
                        </h2>
                        <div
                          id="collapse4"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading4"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.experience?.length === 0 &&
                                    userData?.experience && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.experience?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Job Title</th>
                                          <th>Previous Company</th>
                                          <th>From</th>
                                          <th>To</th>
                                          <th>Description</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.experience &&
                                          userData?.experience?.map((item) => (
                                            <tr>
                                              <td>{item.jobTitle}</td>
                                              <td>{item.previousCompany}</td>
                                              <td>{item.fromDate}</td>
                                              <td>{item.toDate}</td>
                                              <td>
                                                {item.jobDescription &&
                                                item.jobDescription.trim() !==
                                                  ""
                                                  ? item.jobDescription
                                                  : "-"}
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                {/* <WorkExperience workData={userData?.experience} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            Education
                          </button>
                        </h2>
                        <div
                          id="collapse5"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.education?.length === 0 &&
                                    userData?.education && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.education?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Degree</th>
                                          <th>College</th>
                                          <th>From</th>
                                          <th>To</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.education &&
                                          userData?.education?.map((item) => (
                                            <tr>
                                              <td>{item.highestDegree}</td>
                                              <td>{item.college}</td>
                                              <td>{item.fromDate}</td>
                                              <td>{item.toDate}</td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                {/* <Education educationData={userData?.education} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            Dependents
                          </button>
                        </h2>
                        <div
                          id="collapse6"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading6"
                          data-bs-parent="#accordion1"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  {userData?.dependents?.length === 0 &&
                                    userData?.dependents && (
                                      <div className="fs-4 text-secondary text-center">
                                        No records available
                                      </div>
                                    )}
                                  {userData?.dependents?.length !== 0 && (
                                    <table className="resume custom">
                                      {/* Table Headings */}
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Gender</th>
                                          <th>DOB</th>
                                          <th>Relation</th>
                                          <th>Mobile Number</th>
                                        </tr>
                                      </thead>

                                      {/* Table Body */}
                                      <tbody>
                                        {userData?.dependents &&
                                          userData?.dependents?.map((item) => (
                                            <tr>
                                              <td>{item.name}</td>
                                              <td>{item.gender}</td>
                                              <td>{item.dob}</td>
                                              <td>{item.relationship}</td>
                                              <td>{item.number}</td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab2"
                    role="tabpanel"
                    aria-labelledby="tab2-tab"
                  ></div>

                  <div
                    className="tab-pane fade"
                    id="tab3"
                    role="tabpanel"
                    aria-labelledby="tab3-tab"
                  ></div>

                  <div
                    className="tab-pane fade"
                    id="tab4"
                    role="tabpanel"
                    aria-labelledby="tab4-tab"
                  >
                    <EmployeeAsset
                      assestData={userData?.assestDetail}
                      employeeId={data}
                    />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab5"
                    role="tabpanel"
                    aria-labelledby="tab5-tab"
                  >
                    <OrgChartExample />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="tab6"
                    role="tabpanel"
                    aria-labelledby="tab6-tab"
                  ></div>

                  <div
                    className="tab-pane fade"
                    id="tab7"
                    role="tabpanel"
                    aria-labelledby="tab7-tab"
                  ></div>

                  <div
                    className="tab-pane fade"
                    id="tab8"
                    role="tabpanel"
                    aria-labelledby="tab8-tab"
                  >
                    <Salary />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab9"
                    role="tabpanel"
                    aria-labelledby="tab9-tab"
                  >
                    <IndexChart />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab10"
                    role="tabpanel"
                    aria-labelledby="tab10-tab"
                  >
                    <EmployeeType userData={userData?.personalDetail} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab11"
                    role="tabpanel"
                    aria-labelledby="tab11-tab"
                  >
                    <EmployeeAccess userData={userData?.personalDetail} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          // </form>
        )}
        <Footer />
      </div>
      <div
        className="modal fade"
        id="staticBackdropEmp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <EditEmployee
              selectedEmployee={userData}
              // fetchList={fetchApprovedUser}
              titleHeading="Edit Employee Action"
              isEditable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile;
