import { useEffect, useState } from "react";
import {
  API_REGULARIZATION_HR_APPROVE,
  API_REGULARIZATION_HR_LIST,
  API_REGULARIZATION_MANAGER_APPROVE,
  API_REGULARIZATION_MANAGER_LIST,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi, putApi } from "../../utils/api";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination";
import { ThreeDots } from "react-loader-spinner";
import { toggleMenu } from "../../utils/helpers";
import moment from "moment";
import PageTitle from "../../components/PageTitle";

const TeamRegularizations = ({title, description}) => {
  const [regularizationList, setRegularizationList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRegularization, setSelectedRegularization] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [comment, setComment] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  const accessToken = Cookies.get("accessToken");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRegularization, setTotalRegularization] = useState(0);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, selectedStatus]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_REGULARIZATION_MANAGER_LIST +
        `?status=${selectedStatus}&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 201) {
          setRegularizationList(res.data?.data.data);
          setFilteredItems(res.data?.data?.data);
          setTotalPages(res.data?.data.totalPages);
          setTotalRegularization(res.data.data.totalElements);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setStatus("");
    setComment("");
    setSelectedRegularization({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        API_REGULARIZATION_MANAGER_APPROVE +
          `?empId=${selectedRegularization.employeeId}&requestId=${selectedRegularization?.id}&status=${status}&comment=${comment}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          let dataRes = res?.data;
          const modalClose =
            document.getElementsByClassName("cancel-final-leave")[0];
          modalClose.click();
          toast.success(dataRes.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    const filtered = regularizationList?.filter(
      (item) =>
        item.employeeName &&
        item.employeeName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredItems(sortedData);
  };
  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Requests : <span>{totalRegularization}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Employee Name."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>

        {/* <button type="submit" form="form_filters">
            Search
          </button> */}
        {/* </form> */}
        <div className="search">
          <select
            id="status"
            required
            className="form-select"
            name="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
          >
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved (L1)</option>
            <option value="REJECTED">Rejected</option>
            <option value="HR_APPROVED">HR Approved (Final)</option>
            <option value="WITHDRAW">Withdrawn</option>
          </select>
        </div>
      </div>

      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={() => handleSort("employeeName")}>
                    Employee Name
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("creationDate")}>
                    Requested Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("attendanceDateFor")}>
                    Attendance Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("regHour")}>
                    Hour
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reason")}>
                    Reason
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("regDir")}>
                    Direction
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>

                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    <td>{item.employeeName}</td>
                    <td>{moment(item.creationDate).format("YYYY-MM-DD")}</td>
                    <td>{item.attendanceDateFor}</td>
                    <td>{item.regHour}</td>
                    <td>{item.reason}</td>
                    <td>{item.regDir}</td>
                    <td>
                      {selectedStatus === "WITHDRAW"
                        ? "WITHDRAWN"
                        : item.regFinalStatus}
                    </td>

                    <td>
                      {selectedStatus === "PENDING" ? (
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropLeave"
                          onClick={() => setSelectedRegularization(item)}
                        >
                          View
                        </a>
                      ) : (
                        <span className="text-muted">-</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center mt-5">
          No Record Available
        </div>
      )}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div
        className="modal fade"
        id="staticBackdropLeave"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Approve Team Regularization
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="resume custom">
                    {/* Table Headings */}
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Employee Name</th>
                        <th>Attendance Date</th>
                        <th>Hour</th>
                        <th>Reason</th>
                        <th>Direction</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                      <tr>
                        <td>{selectedRegularization.id}</td>
                        <td>{selectedRegularization.employeeName}</td>
                        <td>{selectedRegularization.attendanceDateFor}</td>
                        <td>{selectedRegularization.regHour}</td>
                        <td>{selectedRegularization.reason}</td>
                        <td>{selectedRegularization.regDir}</td>
                        <td>{selectedRegularization.regFinalStatus}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="status" className="has-asterisk">
                      Status
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      id="status"
                      className="border"
                      required
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="APPROVED">Approved</option>
                      <option value="REJECTED">Rejected</option>
                    </select>
                  </div>
                  <div className="col-md-2"></div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="reason" className="has-asterisk">
                      Comment
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      value={comment}
                      rows={3}
                      cols={5}
                      className="border"
                      required
                      onChange={(e) => setComment(e.target.value)}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-final-leave"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamRegularizations;
