import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EMPLOYEE_LIST, GET_DEPARTMENT_LIST } from "../../config/Endpoints";
import Cookies from "js-cookie";
import { getApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import EmployeeCard from "./EmployeeCard";
import AssignManager from "../AssignManager/AssignManager";
import SendEmployeeOnboarding from "../EmployeeOnboard/SendEmployeeOnboarding";
import { toggleMenu } from "../../utils/helpers";
import Footer from "../../components/Footer";
import PageTitle from "../../components/PageTitle";
// import Pagination from "../components/Pagination";

function EmployeeList({title, description}) {
  const [employeeList, setEmployeeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const navigate = useNavigate();

  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(10);

  useEffect(() => {
    fetchList();
    fetchDepartmentList();
  }, []);

  const fetchList = () => {
    setisLoading(true);
    getApi(EMPLOYEE_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setFilteredItems(res.data.data);
        }
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const fetchDepartmentList = () => {
    getApi(GET_DEPARTMENT_LIST, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleView = (e, item) => {
    e.preventDefault();
    navigate("/dashboard/profile", {
      state: {
        data: item,
      },
    });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(e.target.value);
    const filtered = employeeList.filter((item) =>
      item.empName
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(searchValue.replace(/\s/g, "").toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleDepartment = (e) => {
    setSelectedDepartmentId(e.target.value);
    setisLoading(true);
    getApi(
      EMPLOYEE_LIST +
        `?departmentId=${e.target.value}&status=${selectedStatus}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setFilteredItems(res.data.data);
        }
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };

  const handleStatus = (e) => {
    setSelectedStatus(e.target.value);
    setisLoading(true);
    getApi(
      EMPLOYEE_LIST +
        `?departmentId=${selectedDepartmentId}&status=${e.target.value}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setFilteredItems(res.data.data);
        }
        setisLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setisLoading(false);
      });
  };
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // const currentRecords =
  //   filteredItems && filteredItems.length > 0
  //     ? filteredItems.slice(
  //         Math.max(0, indexOfFirstRecord),
  //         Math.min(filteredItems.length, indexOfLastRecord)
  //       )
  //     : [];
  // const nPages = Math.ceil(filteredItems?.length / recordsPerPage);

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Employees : <span>{filteredItems?.length}</span>
        </div>
      </div>

      {/* Filters */}
      <div className="filters">
        {/* <form action="index.html" id="form_filters" className="d-f"> */}
        {/* Search By Company */}
        <div className="search">
          <input
            type="text"
            name="search"
            placeholder="Search By Name."
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={(e) => {
              if (e.charCode === 32 && e.target.selectionStart === 0) {
                e.preventDefault(); // Prevent adding space at the beginning
              }
            }}
          />
          <img src="/assets/images/search-ico.svg" width="14" alt="Search" />
        </div>
        <div className="search">
          <select
            id="department"
            required
            className="form-select"
            name="department"
            value={selectedDepartmentId}
            onChange={handleDepartment}
          >
            <option value="">Select Department</option>
            {departmentList &&
              departmentList.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="search">
          <select
            id="selectedStatus"
            required
            className="form-select"
            name="selectedStatus"
            value={selectedStatus}
            onChange={handleStatus}
          >
            <option value="">Select Status</option>
            <option value="VERIFIED">VERIFIED</option>
            <option value="BLOCKED">BLOCKED (Temporally Disable)</option>
            <option value="LEFTOUT">LEFTOUT (Permanently Disable)</option>
          </select>
        </div>
        {/* <button type="submit" form="form_filters">  
            Search
          </button> */}
        {/* </form> */}

        {/* Actions */}
        <div className="actions"></div>
      </div>
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div className="row emp-card">
        {!isLoading &&
          filteredItems?.length > 0 &&
          filteredItems.map((employee, index) => (
            <div className="col-md-4 col-lg-3" key={index}>
              <EmployeeCard employee={employee} />
            </div>
          ))}
        {!isLoading && filteredItems?.length === 0 && (
          <div className="fs-4 text-secondary text-center">
            No Record Available
          </div>
        )}
      </div>
      {/* <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      /> */}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <AssignManager
              selectedEmployee={selectedEmployee}
              fetchList={fetchList}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropSend"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <SendEmployeeOnboarding />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default EmployeeList;
