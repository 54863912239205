import { TailSpin, ThreeDots } from "react-loader-spinner";
import Footer from "../../components/Footer";
import { toggleMenu } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import ApplyRegularizationModal from "./ApplyRegularizationModal";
import { getApi, putApi } from "../../utils/api";
import {
  API_REGULARIZATION_GET,
  API_REGULARIZATION_WITHDRAW,
} from "../../config/Endpoints";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import PageTitle from "../../components/PageTitle";

const ApplyRegularization = ({title, description}) => {
  const [, setRegularizationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRegularizationRequest, setSelectedRegularizationRequest] =
    useState([]);
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("PENDING");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegularization, setTotalRegularization] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  const accessToken = Cookies.get("accessToken");

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredItems].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredItems(sortedData);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, limit, status]);

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_REGULARIZATION_GET +
        `?status=${status}&pageSize=${limit}&page=${currentPage}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 201) {
          setRegularizationList(res.data?.data);
          setFilteredItems(res.data?.data?.data);
          setTotalPages(res.data?.data.totalPages);
          setTotalRegularization(res.data?.data.totalElements);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await putApi(
        API_REGULARIZATION_WITHDRAW +
          `?requestId=${selectedRegularizationRequest?.id}&comment=${reason}`,
        {},
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchList();
          const modalClose = document.getElementsByClassName(
            "cancel-modal-withdraw"
          )[0];
          modalClose.click();
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedRegularizationRequest({});
    setReason("");
  };

  return (
    <div className="wrapper-body">
      {/* Page Heading */}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
        <div className="total">
          Total Requests : <span>{totalRegularization}</span>
        </div>
      </div>
      {/* Filters */}
      <div className="filters">
        {/* Search By Company */}
        <div className="search">
          <select
            id="selectedStatus"
            required
            className="form-select"
            name="selectedStatus"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved (L1)</option>
            <option value="REJECTED">Rejected</option>
            <option value="HR_APPROVED">HR Approved (Final)</option>
            <option value="WITHDRAW">Withdrawn</option>
          </select>
        </div>
        {/* Actions */}

        <div className="actions">
          <Link
            className="export theme-button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropApplyRegularization"
          >
            Apply Regularization
          </Link>
        </div>
      </div>
      {/* Data Table */}
      {!isLoading && filteredItems?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="resume custom">
              {/* Table Headings */}
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={() => handleSort("creationDate")}>
                    Requested Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("attendanceDateFor")}>
                    Attendance Date
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("regHour")}>
                    Hour
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("reason")}>
                    Reason
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("firstApprovalComment")}>
                    L1 Comment
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th onClick={() => handleSort("secondApprovalComment")}>
                    L2 Comment
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                    />
                  </th>
                  <th>
                    Direction
                    <img
                      src="/assets/images/sort.png"
                      alt="sort"
                      width={18}
                      height={18}
                      onClick={() => handleSort("regDir")}
                    />
                  </th>
                  <th>Status</th>

                  <th>Action</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {filteredItems?.map((item, index) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    {/* <td>{item.id}</td> */}
                    {/* <td>{item.employeeName}</td> */}
                    <td>{moment(item.creationDate).format("YYYY-MM-DD")}</td>
                    <td>{item.attendanceDateFor}</td>
                    <td>{item.regHour}</td>
                    <td>
                      {item.reason ? (
                        item.reason
                      ) : (
                        <p className="text-center">-</p>
                      )}
                    </td>
                    <td>
                      {item.firstApprovalComment ? (
                        item.firstApprovalComment
                      ) : (
                        <p className="text-center">-</p>
                      )}
                    </td>
                    <td>
                      {item.secondApprovalComment ? (
                        item.secondApprovalComment
                      ) : (
                        <p className="text-center">-</p>
                      )}
                    </td>
                    <td>{item.regDir}</td>
                    <td>{status === "WITHDRAW" ? "WITHDRAWN" : status}</td>
                    <td>
                      {item.regFinalStatus === "PENDING" ? (
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropRegularizationRequest"
                          onClick={() => {
                            setSelectedRegularizationRequest(item);
                          }}
                        >
                          Withdraw
                        </a>
                      ) : (
                        <p
                          className="text-muted text-left"
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          -
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div>
                {/* Dropdown for selecting items per page */}
                <label htmlFor="itemsPerPage">Items per page </label>
                <select
                  id="itemsPerPage"
                  onChange={handleLimitChange}
                  value={limit}
                  className="border ms-3 w-25  p-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </select>
              </div>
            </div>
            <div className="col-md-9">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && filteredItems?.length === 0 && (
        <div className="fs-4 text-secondary text-center">
          No Record Available
        </div>
      )}
      {isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropApplyRegularization"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <ApplyRegularizationModal fetchList={fetchList} />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropRegularizationRequest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropRegularizationRequest"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              {isLoading && (
                <div className="overlay">
                  <div className="loader-container">
                    <TailSpin
                      height="100"
                      width="100"
                      radius="1"
                      color="blue"
                      ariaLabel="tail-spin-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </div>
              )}
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Withdraw Regularization Request
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="reason" className="has-asterisk">
                      Reason
                    </label>
                  </div>
                  <div className="col-md-4">
                    <textarea
                      rows={3}
                      cols={5}
                      className="border"
                      name="reason"
                      required
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      maxLength={100}
                      minLength={5}
                      onKeyPress={(e) => {
                        // Prevent adding space at the beginning
                        if (
                          e.charCode === 32 &&
                          e.target.selectionStart === 0
                        ) {
                          e.preventDefault();
                        }

                        // Regular expression to allow only letters, numbers, and spaces
                        const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                        // Check if the character is allowed
                        const char = String.fromCharCode(e.charCode);
                        if (!allowedCharsRegex.test(char)) {
                          e.preventDefault(); // Prevent non-alphanumeric character input
                        }
                      }}
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled " : "theme-button "
                  }
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-modal-withdraw"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ApplyRegularization;
