import { TailSpin } from "react-loader-spinner";
import Footer from "../../components/Footer";
import { generateYear, toggleMenu } from "../../utils/helpers";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  API_EXPORT_ATTANDANCE,
  API_LEAVE_ATTANDANCE_COMPILE_LIST,
  EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { getApi } from "../../utils/api";
import Select, { components } from "react-select";
import PageTitle from "../../components/PageTitle";
import { employeeFlag } from "../../enums/employeeFlag";

const ExportAttendance = ({ title, description }) => {
  const [yearList] = useState(generateYear(2024));
  const accessToken = Cookies.get("accessToken");
  const [isLoading, setIsLoading] = useState();
  const [isDownLoading, setIsDownLoading] = useState(true);
  const [isCompileActive, setIsCompileActive] = useState(true);
  const [isCompile, setIsCompile] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds

  const initialState = {
    employeeId: "",
    month: new Date().toLocaleString("en-US", { month: "long" }),
    year: new Date().getFullYear(),
    employeeFlag: employeeFlag.ALL,
  };

  const [formData, setFormData] = useState(initialState);

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    fetchEmployeeList();
  }, [formData.employeeFlag]);

  useEffect(() => {
    if (isCompile) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            setIsCompileActive(true);
            clearInterval(timerId);
            return 0; // Stop the timer
          }
          return prevTime - 1; // Decrease the time
        });
      }, 1000); // 1 second interval
      return () => {
        clearInterval(timerId);
      };
    }
  }, [isCompile]);

  const fetchEmployeeList = async () => {
    setIsLoading(true);
    let url = EMPLOYEE_LIST;

    if (
      !(
        formData.employeeFlag === "" ||
        formData.employeeFlag === employeeFlag.ALL
      )
    ) {
      url += "?type=" + formData.employeeFlag;
    }

    await getApi(url, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setEmployeeList(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });

    setFormData((prevFormData) => ({
      ...prevFormData,
      employeeId: initialState.employeeId,
    }));
    setSelectedEmployee(selectedEmployeeDefaultValue);
  };

  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      await fetch(
        `${API_EXPORT_ATTANDANCE}?month=${formData.month}&year=${formData.year}&empid=${formData.employeeId}&type=${formData.employeeFlag}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/vnd.ms-excel",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            toast.error("No record available to export", {
              position: toast.POSITION.TOP_RIGHT,
            });
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          // Create a URL object from the blob
          const url = URL.createObjectURL(blob);
          // Create a temporary link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = selectedEmployee
            ? `${formData.employeeFlag == employeeFlag.ALL ? "" : `${formData.employeeFlag}_`}${selectedEmployee?.label}_Attendance_${formData.month}_${formData.year}_list.xlsx`
            : `Employees_Attendance_${formData.month}_${formData.year}_list.xlsx`;
          link.click();
          // Clean up the URL object
          URL.revokeObjectURL(url);
          setIsLoading(false);
          handleReset();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          setIsLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const optionList = employeeList?.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const selectedEmployeeDefaultValue = {
    value: "",
    label: "All Employees",
    type: "",
  };

  optionList.unshift(selectedEmployeeDefaultValue);

  const employeeFlagOptionList = {
    CCI_INDIA: employeeFlag.CCI_INDIA,
    CCI_US: employeeFlag.CCI_US,
    IRED_INDIA: employeeFlag.IRED,
    All: employeeFlag.ALL,
  };

  const validateForm = () => {
    return formData.employeeFlag !== "";
  };

  useEffect(() => {
    setIsFormValid(validateForm);
  }, [formData]);

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };
  const handleReset = () => {
    setFormData(initialState);
    setSelectedEmployee("");
  };

  const handleCompile = async (e) => {
    e.preventDefault();
    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0 for January, 1 for February, etc.
    const currentYear = parseInt(currentDate.getFullYear());

    // Get the selected month and year from formData
    const selectedMonthIndex = new Date(
      Date.parse(formData.month + " 1, 2021")
    ).getMonth(); // Convert month name to index
    const selectedYear = parseInt(formData.year);
    // Check if the selected month and year are in the future

    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonthIndex > currentMonth)
    ) {
      toast.error(
        "The selected month and year cannot be in the future. Please choose a valid month and year.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      return; // Exit the function if the validation fails
    }

    setIsCompile(true);
    setIsCompileActive(false);
    setTimeLeft(5); // Set timer to 45 seconds
    // Make the API call here
    try {
      const res = await getApi(
        API_LEAVE_ATTANDANCE_COMPILE_LIST +
          `?month=${formData.month}&year=${formData.year}&empid=${formData.employeeId}`,
        accessToken
      );
      if (res.status === 200) {
        console.log(res.data.data);
      } else {
        console.error("Failed to fetch employee list:", res.status);
      }
    } catch (error) {
      console.error("Error calling the API:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is updated
    }
    // Start the timer
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          handleSubmit(e); // Call the download function when the timer ends
          setIsCompileActive(true);
          setIsCompile(false);

          return 0; // Stop the timer
        }
        return prevTime - 1; // Decrease the time
      });
    }, 1000); // 1 second interval
  };

  return (
    <div className="wrapper-body">
      {(isLoading || isCompile) && (
        <div className="overlay">
          <div className="loader-container">
            <TailSpin
              height="100"
              width="100"
              radius="1"
              color="blue"
              ariaLabel="tail-spin-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="page-heading">
        <div id="showMenuBtn" className="collapse-button" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <PageTitle title={title} description={description} />
      </div>
      <form id="assign-asset" onSubmit={handleCompile}>
        <div className="card">
          {/* <h3 className="heading">Download Attendance</h3> */}
          <div className="inputs column-gap-2">
            <div className="row mt-4">
              <div className="col-md-2"></div>
              <div className="col-md-2 d-flex align-items-center">
                <label htmlFor="month" className="has-asterisk">
                  Month
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="month"
                    required
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                  >
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 d-flex align-items-center ">
                <label htmlFor="year" className="has-asterisk">
                  Year
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="year"
                    required
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                  >
                    {yearList &&
                      yearList.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 d-flex align-items-center">
                <label htmlFor="employee-flag" className="has-asterisk">
                  Employee Flag
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <select
                    id="employee-flag"
                    required
                    name="employeeFlag"
                    value={formData.employeeFlag}
                    onChange={handleInputChange}
                  >
                    {Object.entries(employeeFlagOptionList).map(
                      ([label, value]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-1">
              <div className="col-md-2"></div>
              <div className="col-md-2 d-flex align-items-center ">
                <label
                  htmlFor="month"
                  // className="has-asterisk"
                >
                  Select Employee
                </label>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <div className="dropdown-container">
                    <Select
                      options={optionList}
                      components={{ Option }}
                      onChange={(selectedOption) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          employeeId: selectedOption.value,
                        }));
                        setSelectedEmployee(selectedOption);
                      }}
                      value={selectedEmployee}
                      // placeholder="Choose"
                      isSearchable={true}
                      // required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <br />

          <div className="action text-center">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
            <button
              className={
                isLoading || !isFormValid
                  ? "theme-button disabled ms-2 "
                  : "theme-button ms-2 "
              }
              // onClick={handleCompile}
              type="submit"
              disabled={!isFormValid}
              // disabled="true"
            >
              Compile & Download
            </button>
            {/* <button
              className={
                isDownLoading
                  ? "theme-button disabled ms-2 "
                  : "theme-button ms-2 "
              }
              type="submit"
              disabled={isDownLoading}
            >
              Download
            </button> */}
          </div>

          <div className="row">
            <h4 className="heading"></h4>
            <h4 className="heading">Instructions :</h4>
            <div className="col-md-10">
              <ul className="m-2">
                <li>
                  To download attendance, select the appropriate flag and
                  employee name using the "Employee Flag" feature.
                </li>
                <li>
                  For location-specific attendance of all employees, select the
                  corresponding flag from the same feature.
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              {/* {!isCompileActive && (
                <span className="">
                  <p
                    style={{ pointerEvents: "none" }}
                    className="text-white ms-1 fw-bolder fs-4 bg-success p-2 rounded text-center"
                  >
                    {formatTime(timeLeft)}
                  </p>
                </span>
              )} */}
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default ExportAttendance;
