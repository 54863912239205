import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { postApi } from "../../utils/api";
import { CREATE_ASSET_TYPE } from "../../config/Endpoints";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import * as Yup from "yup";
import axios from "axios";

const maxInputWidthInPX = 400;

const CreateAssetType = ({ setAssetTypeList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const formDataInitialState = {
    name: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [errors, setErrors] = useState({});
  const accessToken = Cookies.get("accessToken");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await AssetTypeSchema.validate(formData, {
        abortEarly: false,
      });

      await axios
        .post(
          CREATE_ASSET_TYPE,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            params: {
              name: formData.name,
            },
          }
        )
        .then((res) => {
          setAssetTypeList(res.data.data);

          toast.success(formData.name + " asset type created successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          handleReset();
        })
        .catch((err) => {
          const errMsg = err.response.data.message
            ? err.response.data.message
            : "Something went wrong. Please try again!";

          toast.error(errMsg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setFormData(formDataInitialState);
    setErrors({});
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="px-2 py-4">
          <div className="inputs">
            <div className="d-f justify-content-center gap-15">
              <div
                className="input-group"
                style={{ maxWidth: `${maxInputWidthInPX}px` }}
              >
                <label htmlFor="manufacture" className="has-asterisk">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formData.name}
                  disabled={isLoading}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name}</small>
                )}
              </div>
            </div>
          </div>
          <div className="action text-center mt-5">
            <button
              className={isLoading ? "theme-button disabled " : "theme-button "}
              type="submit"
            >
              {isLoading ? (
                <ThreeDots
                  height="25"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const AssetTypeSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
});

export default CreateAssetType;
