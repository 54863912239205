import { useState } from "react";
import Cookies from "js-cookie";
import { deleteApi, getApi, putApi } from "../../utils/api";
import {
  API_ASSIGN_LEAVE_MANAGER,
  API_LEAVE_MANAGER,
  API_REMOVE_LEAVE_MANAGER,
  GET_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { ThreeDots } from "react-loader-spinner";

const AssignLeaveManager = ({ employeeList }) => {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [previousEmployeeData, setPreviousEmployeeData] = useState({});

  const accessToken = Cookies.get("accessToken");
  const [managerId, setManagerId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [manager, setManager] = useState(false);

  const handleChangeEmployee = async (e) => {
    let employeeId = e.value;
    setIsLoading(true);
    setResponseMessage("");
    setSelectedEmployee(e);
    setSelectedEmployeeId(e.value);
    setPreviousEmployeeData({});
    await getApi(API_LEAVE_MANAGER + `?employeeId=${employeeId}`, accessToken)
      .then((res) => {
        if (res.data.message !== "Success") {
          setResponseMessage(res.data.message);
        } else {
          setPreviousEmployeeData(res.data.data.personalDetail);
        }

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!managerId) {
        toast.error("Please select manager.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        return;
      }
      setIsLoading(true);
      if (managerId !== "") {
        await putApi(
          API_ASSIGN_LEAVE_MANAGER +
            `?employeeId=${selectedEmployeeId}&leaveManagerId=${managerId}`,
          {},
          accessToken
        )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              setPreviousEmployeeData(res?.data?.data?.personalDetail);
              const modalClose = document.getElementsByClassName(
                "cancel-leave-manager"
              )[0];
              modalClose.click();
              toast.success("Successfully Assigned!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setManager("");
            } else {
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await deleteApi(
        API_REMOVE_LEAVE_MANAGER + `?employeeId=${selectedEmployeeId}`,
        accessToken
      )
        .then((res) => {
          setIsLoading(false);
          setPreviousEmployeeData({});
          const modalClose = document.getElementsByClassName(
            "cancel-modal-Manager-delete"
          )[0];
          modalClose.click();
          setResponseMessage(res.data.message);

          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setManager("");
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(e?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleReset = () => {
    setSelectedEmployee("");
    setPreviousEmployeeData("");
    setManagerId("");
    setManager("");
  };

  const optionList = employeeList.map((item) => ({
    value: item.id,
    label: item.empName,
    type: item.employeeType,
  }));

  const Option = (props) => {
    const { data } = props;
    const { label, type } = data;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{label}</span>
          <span style={{ fontSize: "12px", color: "gray" }}>({type})</span>
        </div>
      </components.Option>
    );
  };

  const handleManager = (e) => {
    setManager(e);
    setManagerId(e.value);
  };

  return (
    <>
      <div className="card">
        <form id="assign-asset">
          <h3 className="heading">Leave Manager</h3>
          <div className="inputs">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="dropdown-container">
                  <Select
                    options={optionList}
                    components={{ Option }}
                    onChange={handleChangeEmployee}
                    value={selectedEmployee}
                    placeholder="Select Employee"
                    isSearchable={true}
                    isDisabled={employeeList?.length === 0}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </form>
      </div>

      {Object.keys(previousEmployeeData).length === 0 && isLoading && (
        <div className="centered-loader">
          <ThreeDots
            height="100"
            width="100"
            radius="9"
            color="blue"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      {responseMessage &&
        !isLoading &&
        Object.keys(previousEmployeeData).length === 0 && (
          <div className="fs-4 text-secondary text-center mt-5  capitalize">
            <h4>{responseMessage}</h4>
            <button
              className="btn btn-secondary m-3"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropReassignLeaveManager"
              onClick={() =>
                handleManager({
                  label: previousEmployeeData?.manager?.name,
                  value: previousEmployeeData?.manager?.id,
                })
              }
            >
              {previousEmployeeData?.manager?.name ? "Re-assign" : "Assign"}
            </button>
          </div>
        )}

      {Object.keys(previousEmployeeData).length > 0 && (
        <div className="table-responsive">
          <table className="resume custom">
            <thead>
              <tr>
                <th>Name</th>
                <th>Assigned Manager</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{previousEmployeeData?.empName}</td>
                <td>
                  {previousEmployeeData?.leaveManagerName
                    ? previousEmployeeData?.leaveManagerName
                    : "Not Assigned"}
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropLeaveManager"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div
        className="modal fade"
        id="staticBackdropReassignLeaveManager"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5
                  className="modal-title text-center flex"
                  id="staticBackdropLabel"
                >
                  Assign Leave Manager
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setManagerId("");
                    setManager("");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <h6>Employee Name</h6>
                  </div>

                  <div className="col-md-4">
                    <h6>
                      {selectedEmployee?.label || previousEmployeeData?.name}
                    </h6>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <label htmlFor="manager" className="has-asterisk">
                      Assign Manager
                    </label>
                  </div>
                  <div className="col-md-4">
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        components={{ Option }}
                        onChange={handleManager}
                        value={manager}
                        placeholder="Select employee"
                        isSearchable={true}
                        isDisabled={employeeList?.length === 0}
                        isRequired={true}
                        required
                      />
                    </div>
                    {/* setManagerId */}
                    {/* <select
                        id="manager"
                        className="border"
                        required
                        name="manager"
                        value={managerId}
                        onChange={(e) => setManagerId(e.target.value)}
                      >
                        <option value="">Select Manager</option>
                        {employeeList &&
                          employeeList.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.firstName}
                            </option>
                          ))}
                      </select> */}
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>

              <div className="justify-content-center modal-footer">
                <button
                  type="submit"
                  className={
                    isLoading ? "theme-button disabled" : "theme-button"
                  }
                >
                  {isLoading ? (
                    <ThreeDots
                      height="20"
                      width="53"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="theme-button bg-grey mx-3 w-35 cancel-leave-manager"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setManagerId("");
                    setManager("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdropLeaveManager"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="text-center">Are you sure you want to delete ?</h5>
            </div>
            <div className="justify-content-center modal-footer">
              <button
                type="button"
                className={
                  isLoading ? "theme-button disabled " : "theme-button "
                }
                onClick={handleRemove}
              >
                {isLoading ? (
                  <ThreeDots
                    height="20"
                    width="53"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                type="button"
                className="theme-button bg-grey mx-3 w-35 cancel-modal-Manager-delete"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignLeaveManager;
