import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
  API_POLICY,
  GET_ONBOARDING_EMPLOYEE_LIST,
} from "../../config/Endpoints";
import { postApi, putApi } from "../../utils/api";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";

const PolicyModal = ({ selectedPolicy, setSelectedPolicy, fetchList }) => {
  const [formData, setFormData] = useState({
    policyTitle: "",
    policyDocument: null,
    policyValidFrom: "",
    description: "",
  });
  const [isFileChange, setIsFileChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [errors, setErrors] = useState("");

  const handleInputChange = (event) => {
    const { name, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      // Define an array of accepted file types
      const acceptedFileTypes = ["application/pdf"];

      if (file) {
        if (acceptedFileTypes.includes(file.type)) {
          // File type is supported
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: file,
          }));
        } else {
          // File type is not supported, show an alert
          toast.error(
            "Unsupported file type. Please select a valid  PDF file.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );

          event.target.value = null; // Clear the file input
        }
      }
    } else if (type === "date") {
      const value = event.target.value;
      const year = value.split("-")[0];
      if (year.length <= 4) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      const value = event.target.value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  };

  const handleReset = () => {
    setIsFileChange(false);
    setFormData({
      policyTitle: "",
      policyDocument: null,
      policyValidFrom: "",
      description: "",
    });
    if (!isFileChange) {
      document.getElementById("file-input").value = "";
    }
    setErrors("");
    setSelectedPolicy({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await AddPolicySchema.validate(
        {
          policyTitle: formData.policyTitle,
          description: formData.description,
        },
        {
          abortEarly: false,
        }
      );

      if (selectedPolicy?.policyId) {
        const formdata = new FormData();
        formdata.append("title", formData.policyTitle);
        formdata.append("policyId", selectedPolicy?.policyId);
        formdata.append("policyFile", formData.policyDocument);
        formdata.append("validFrom", formData.policyValidFrom);
        formdata.append("description", formData.description);
        await putApi(API_POLICY, formdata, accessToken)
          .then((res) => {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: true,
            });

            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error("Something went wrong please try again!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        const formdata = new FormData();
        formdata.append("title", formData.policyTitle);
        formdata.append("policyFile", formData.policyDocument);
        formdata.append("validFrom", formData.policyValidFrom);
        formdata.append("description", formData.description);
        await postApi(API_POLICY, formdata, accessToken)
          .then((res) => {
            setIsLoading(false);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: true,
            });

            handleReset();
            fetchList();
            const modalClose =
              document.getElementsByClassName("cancel-modal")[0];
            modalClose.click();
          })
          .catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error("Something went wrong please try again!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsLoading(false);

      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedPolicy).length > 0) {
      setFormData({
        policyTitle: selectedPolicy.title,
        policyDocument: null,
        policyValidFrom: selectedPolicy.validFrom,
        description: selectedPolicy.description,
      });
      setIsFileChange(selectedPolicy?.policyId ? true : false);
    }
  }, [selectedPolicy]);

  const getLastPDFFileName = (filePath) => {
    // Split the file path by "/"
    const parts = filePath.split("/");

    // Initialize a variable to store the last file name
    let lastFileName = null;

    // Iterate through the parts in reverse order
    for (let i = parts.length - 1; i >= 0; i--) {
      const fileName = parts[i];

      // Check if the current part ends with ".pdf"
      if (fileName.endsWith(".pdf")) {
        lastFileName = fileName;
        break; // Stop iterating once a filename with ".pdf" extension is found
      }
    }

    return lastFileName;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title text-center flex" id="staticBackdropLabel">
          {Object.keys(selectedPolicy).length > 0 ? "Update" : "Add"} Policy
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleReset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="policyTitle" className="has-asterisk">
              Title
            </label>
          </div>
          <div className="col-md-4 ">
            <input
              type="text"
              name="policyTitle"
              value={formData.policyTitle}
              onChange={handleInputChange}
              placeholder="Enter Policy Title"
              className="border"
              required
              onKeyPress={(e) => {
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault(); // Prevent adding space at the beginning
                }
              }}
              maxLength={150}
            />
            {errors.policyTitle && (
              <small className="text-danger">{errors.policyTitle}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="policyDocument" className="has-asterisk">
              Policy Document
            </label>
          </div>
          <div className="col-md-4 ">
            {/* {formData.policyDocument && selectedPolicy?.policyId && (
              <a
                href={process.env.REACT_APP_IMAGE_URL + formData.policyDocument}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open File
              </a>
            )} */}
            {selectedPolicy?.policyId ? (
              <>
                {isFileChange && selectedPolicy?.documentPath !== "" ? (
                  <div className="policy-uploaded-file border p-2 ">
                    <div className="col-md-10">
                      <span className="span-policy">
                        {getLastPDFFileName(selectedPolicy?.documentPath)}
                      </span>
                    </div>
                    <div className="col-md-2">
                      <a
                        className="m-5 text-danger fw-bolder policy-file-delete"
                        onClick={() => setIsFileChange(false)}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </div>
                ) : (
                  <input
                    type="file"
                    name="policyDocument"
                    id="file-input"
                    onChange={handleInputChange}
                    accept=".pdf,application/pdf"
                    className="border"
                    required
                  />
                )}
              </>
            ) : (
              <input
                type="file"
                name="policyDocument"
                id="file-input"
                onChange={handleInputChange}
                accept=".pdf,application/pdf"
                className="border"
                required
              />
            )}
            {/* <input
                type="file"
                name="policyDocument"
                id="file-input"
                onChange={handleInputChange}
                accept=".pdf,application/pdf"
                className="border"
                required={selectedPolicy?.policyId ? false : true}
              /> */}
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="policyValidFrom" className="has-asterisk">
              Policy Valid From
            </label>
          </div>
          <div className="col-md-4">
            <input
              type="date"
              required
              name="policyValidFrom"
              value={formData.policyValidFrom}
              placeholder="08/02/2019"
              onChange={handleInputChange}
              className="border"
            />
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <label htmlFor="description" className="has-asterisk">
              Brief Description About Policy
            </label>
          </div>
          <div className="col-md-4 ">
            <textarea
              id="description"
              name="description"
              required
              value={formData.description}
              onChange={handleInputChange}
              rows={3}
              cols={20}
              className="border"
              maxLength={160}
              onKeyPress={(e) => {
                // Prevent adding space at the beginning
                if (e.charCode === 32 && e.target.selectionStart === 0) {
                  e.preventDefault();
                }

                // Regular expression to allow only letters, numbers, and spaces
                const allowedCharsRegex = /^[a-zA-Z0-9 ]*$/;

                // Check if the character is allowed
                const char = String.fromCharCode(e.charCode);
                if (!allowedCharsRegex.test(char)) {
                  e.preventDefault(); // Prevent non-alphanumeric character input
                }
              }}
            ></textarea>
            {errors.description && (
              <small className="text-danger">{errors.description}</small>
            )}
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="justify-content-center modal-footer">
        <button
          type="submit"
          className={isLoading ? "theme-button disabled " : "theme-button "}
        >
          {isLoading ? (
            <ThreeDots
              height="20"
              width="53"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          type="button"
          className="theme-button bg-grey mx-3 w-35 cancel-modal"
          data-bs-dismiss="modal"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

const AddPolicySchema = Yup.object().shape({
  policyTitle: Yup.string().trim().required("Policy Title is required"),
  description: Yup.string().trim().required("Description is required"),
});

export default PolicyModal;
