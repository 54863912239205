import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logoutUser } from "../utils/Storage";
import { toggleMenu } from "../utils/helpers";

export default function SideNavbar({ menu }) {
  const [menuList] = useState(menu);
  const { pathname } = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);

  const preprocessMenuList = () => {
    const subItems = [{name: "Attendance Report", achorValue: "/exportAttendance"}, {name: "Reimbursement Report", achorValue: "/downloadReimbursement"}]
    menuList.forEach((item) => {
      if (item.name === 'Analytics & Insights' && Array.isArray(item.subMenu)) {
        subItems.forEach((subItem) => {
          if (!item.subMenu.includes(subItem)) {
            item.subMenu.push(subItem)
          }
        })
      }
    })
  }

  useEffect(() => {
    preprocessMenuList()
  }, [menuList])

  useEffect(() => {

  }, [menu]);

  const handleSubMenuClick = (index) => {
    if (activeSubMenu === index) {
      setActiveSubMenu(null);
      setSubMenu(null);
    } else {
      setActiveSubMenu(index);
      setSubMenu(null); // Reset subMenu when clicking on a menu item
    }
  };
  const handleSubMenuActiveClick = (index) => {
    if (subMenu === index) {
      setSubMenu(null);
    } else {
      setSubMenu(index);
    }
  };

  return (
    <nav>
      <div id="hideMenuBtn" className="close-button" onClick={toggleMenu}>
        <span></span>
        <span></span>
      </div>
      <a href="/dashboard/" className="text-center">
        <img
          src="https://www.caeliusconsulting.com/image/logo.svg"
          alt="Caelius Consulting Logo"
          width="135"
          height="32"
        />
      </a>
      <ul className="mt-5">
        {menuList?.map((item, index) => {
          const mainMenuPath = item.subMenu
            ? null
            : `/dashboard${item.achorValue}`;
          const isActiveMainMenu = pathname === mainMenuPath;
          const isSubMenuOpen =
            item.subMenu && pathname.includes(item.subMenu[0].achorValue); // Check if submenu name is in pathname

          return (
            <li
              key={index}
              className={`${activeSubMenu === index ? "active" : ""}`}
            >
              <Link
                to={mainMenuPath}
                onClick={() => handleSubMenuClick(index)}
                aria-expanded={activeSubMenu === index}
              >
                {item.name}
                {item.subMenu && (
                  <span className="ms-auto">
                    <i
                      className={`fa ${
                        activeSubMenu === index
                          ? "fa-angle-down"
                          : "fa-angle-right"
                      }`}
                    />
                  </span>
                )}
              </Link>
              {item?.subMenu?.length > 0 && (
                <ul
                  className={`submenu ${activeSubMenu === index ? "show" : ""}`}
                >
                  {item.subMenu.map((menu, subIndex) => {
                    const subMenuPath = `/dashboard${menu.achorValue}`;
                    const isActiveSubMenu = pathname === subMenuPath;

                    return (
                      <li
                        key={subIndex}
                        className={`submenu ${
                          isActiveSubMenu ? "active " : ""
                        }`}
                      >
                        <Link
                          to={subMenuPath}
                          onClick={() => handleSubMenuActiveClick(subIndex)}
                        >
                          {menu.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      {/* Log Out */}
      <div className="log-out">
        <a onClick={logoutUser} className="text-center">
          <img
            src="/assets/images/logout.svg"
            alt="Logout"
            width="25"
            height="25"
          />
          Log Out
        </a>
      </div>
    </nav>
    // <nav class="navbar navbar-expand-lg ">
    //   <div class="container-fluid">
    //     <a class="navbar-brand" href="/dashboard/">
    //       <img
    //         src="/assets/images/dashboard/logo.svg"
    //         alt="Logo"
    //         width="170"
    //         height="40"
    //       />
    //     </a>
    //     <button
    //       class="navbar-toggler"
    //       type="button"
    //       data-bs-toggle="collapse"
    //       data-bs-target="#navbarSupportedContent"
    //       aria-controls="navbarSupportedContent"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span class="navbar-toggler-icon"></span>
    //     </button>
    //     <div class="collapse navbar-collapse" id="navbarSupportedContent">
    //       <ul className="navbar-nav">
    //         {menuList?.map((item, index) => {
    //           const mainMenuPath = item.subMenu
    //             ? null
    //             : `/dashboard${item.achorValue}`;
    //           const isActiveMainMenu = pathname === mainMenuPath;
    //           const isSubMenuOpen =
    //             item.subMenu && pathname.includes(item.subMenu[0].achorValue); // Check if submenu name is in pathname

    //           return (
    //             <li
    //               key={index}
    //               style={{
    //                 width: "100%",
    //               }}
    //               className={`nav-item ${
    //                 activeSubMenu === index ? "active" : ""
    //               }`}
    //             >
    //               <Link
    //                 to={mainMenuPath}
    //                 onClick={() => handleSubMenuClick(index)}
    //                 aria-expanded={activeSubMenu === index}
    //               >
    //                 {item.name}
    //                 {item.subMenu && (
    //                   <span className="ms-auto">
    //                     <i
    //                       className={`fa ${
    //                         activeSubMenu === index
    //                           ? "fa-angle-down"
    //                           : "fa-angle-right"
    //                       }`}
    //                     />
    //                   </span>
    //                 )}
    //               </Link>
    //               {item?.subMenu?.length > 0 && (
    //                 <ul
    //                   className={`submenu ${
    //                     activeSubMenu === index ? "show" : ""
    //                   }`}
    //                 >
    //                   {item.subMenu.map((menu, subIndex) => {
    //                     const subMenuPath = `/dashboard${menu.achorValue}`;
    //                     const isActiveSubMenu = pathname === subMenuPath;

    //                     return (
    //                       <li
    //                         key={subIndex}
    //                         className={`submenu ms-2 nav-item ${
    //                           isActiveSubMenu ? "active" : ""
    //                         }`}
    //                       >
    //                         <Link
    //                           to={subMenuPath}
    //                           onClick={() => handleSubMenuActiveClick(subIndex)}
    //                         >
    //                           {menu.name}
    //                         </Link>
    //                       </li>
    //                     );
    //                   })}
    //                 </ul>
    //               )}
    //             </li>
    //           );
    //         })}
    //       </ul>
    //       {/* <ul class="navbar-nav">
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/dashboard.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Dashboard</div>
    //           </div>
    //         </li>

    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/profile.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Profile</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/performance.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Performance</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/asset_managament.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Asset Management</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/Attendence Management.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Attendence Management</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/expense_management.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Expense Management</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/Policy Management.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Policy Management</div>
    //           </div>
    //         </li>
    //         <li class="nav-item">
    //           <div class="nav-item-container">
    //             <a href="#"></a>
    //             <div class="icon">
    //               <img
    //                 src="/assets/images/dashboard/announcement.svg"
    //                 alt="Dashboard"
    //                 width="20"
    //                 height="20"
    //               />
    //             </div>
    //             <div class="nav-item-text">Announcements</div>
    //           </div>
    //         </li>
    //       </ul> */}
    //     </div>
    //   </div>
    //   <div class="logout" onClick={logoutUser}>
    //     <div class="logout-icon">
    //       <img
    //         src="/assets/images/dashboard/logout.svg"
    //         alt="Logout"
    //         width="25"
    //         height="25"
    //       />
    //     </div>
    //     <div class="logouttext">Log Out</div>
    //   </div>
    // </nav>
  );
}
