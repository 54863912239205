import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Carousel = ({ session }) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1, // Number of cards to show at once
    slidesToScroll: 1,
  };

  // Function to get URL based on eventType
  const getEventUrl = (eventType) => {
    switch (eventType) {
      case "Classroom Session":
        return "/dashboard/view-announcement#tab1";
      case "Coffee & Learn Session":
        return "/dashboard/view-announcement#tab2";
      case "MuleSoft Meetups":
        return "/dashboard/view-announcement#tab3";
      case "Salesforce Session":
        return "/dashboard/view-announcement#tab4";
      default:
        return "/dashboard/view-announcement"; // Default URL if eventType doesn't match
    }
  };

  return (
    <div class="slider-container">
      <div class="session-container">
        {session && session?.length > 1 && (
          <Slider {...settings}>
            {session?.map((item, index) => (
              <Link
                class="session"
                key={index}
                to={getEventUrl(item.eventType)}
              >
                <img
                  src={process.env.REACT_APP_IMAGE_URL + item?.attachment}
                  alt={item?.title}
                  class="img-fluid"
                />
              </Link>
            ))}
          </Slider>
        )}
      </div>
      {session && session?.length === 1 && (
        <div class="session-container">
          <Link class="session" to={getEventUrl(session[0].eventType)}>
            <img
              src={process.env.REACT_APP_IMAGE_URL + session[0].attachment}
              alt={session[0].title}
              class="img-fluid"
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Carousel;
