import React, { useEffect, useState } from "react";
import ChangePassword from "./Employee/ChangePassword";
import { getUser, logoutUser } from "../utils/Storage";
import { formatLeaveType, getGreeting, toggleMenu } from "../utils/helpers";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import {
  API_ANNOUNCEMENT_DASHBOARD,
  API_DOCUMENT_STATUS,
  API_LEAVE_TODAY,
  API_PUNCH,
  LEAVE_BALANCE,
} from "../config/Endpoints";
import { getApi } from "../utils/api";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import RaiseConcern from "./Dashboard/RaiseConcern";
import DocumentUpload from "./Dashboard/DocumentUpload";
import moment from "moment";
import TruncateText from "../components/TruncateText";
import Carousel from "../components/CarouselComponent";

const MainDashboard = () => {
  const userData = JSON.parse(getUser("userData"));

  const [punchList, setPunchList] = useState([]);
  const [classroomSession, setClassroomSession] = useState([]);
  const [coffeeLearnSession, setCoffeeLearnSession] = useState([]);
  const [salesforceSession, setSalesforceSession] = useState([]);
  const [muleSoftMeetups, setMuleSoftMeetups] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [session, setSession] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const [documentList, setDocumentList] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState({});

  useEffect(() => {
    setIsLoading(true);
    getApi(API_PUNCH, accessToken)
      .then((res) => {
        setPunchList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setIsLoading(false);
      });

    fetchSessions();
  }, []);

  const fetchSessions = () => {
    getApi(API_ANNOUNCEMENT_DASHBOARD, accessToken)
      .then((res) => {
        if (res?.data?.data) {
          const resultData = res?.data?.data?.data;
          setSession(resultData);
          const classSession = resultData?.filter(
            (it) => it.eventType === "Classroom Session"
          );
          const coffeeSession = resultData?.filter(
            (it) => it.eventType === "Coffee & Learn Session"
          );
          const muleSoft = resultData?.filter(
            (it) => it.eventType === "MuleSoft Meetups"
          );
          const salesforce = resultData?.filter(
            (it) => it.eventType === "Salesforce Session"
          );
          setClassroomSession(classSession);
          setCoffeeLearnSession(coffeeSession);
          setMuleSoftMeetups(muleSoft);
          setSalesforceSession(salesforce);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.message !== "Announcement not found !") {
          toast.error(
            e?.response?.data?.message
              ? e?.response?.data?.message
              : "Something went wrong please try again !!",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDocumentStatus();
    fetchList();
    fetchLeaveBalance();
  }, []);

  const fetchDocumentStatus = () => {
    setIsLoading(true);
    getApi(API_DOCUMENT_STATUS, accessToken)
      .then((res) => {
        setDocumentList(res?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error("Something went wrong please try again !!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  const fetchList = () => {
    setIsLoading(true);
    getApi(
      API_LEAVE_TODAY + `?date=${new Date().toISOString().slice(0, 10)}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(res.data?.data ? res.data?.data : []);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const fetchLeaveBalance = () => {
    setIsLoading(true);
    getApi(LEAVE_BALANCE + `/${userData?.id}`, accessToken)
      .then((res) => {
        if (res.status === 200) {
          setLeaveBalance(res.data?.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (documentList.length > 1) {
      const hasPendingFiles = documentList.filter(
        (doc) => doc.isMandatory === true && !doc.isUploaded
      );
      const localData = localStorage.getItem("documentModal", "Closed");
      if (hasPendingFiles.length > 1 && localData !== "Closed") {
        // Open the modal for uploading
        const openModalButton =
          document.getElementsByClassName("click-modal-upload")[0];
        if (openModalButton) {
          openModalButton.click();
        }
      }
      if (hasPendingFiles.length === 0) {
        // Close the modal if all mandatory documents are uploaded
        const closeModalButton =
          document.getElementsByClassName("btn-close-document")[0];
        if (closeModalButton) {
          closeModalButton.click();
        }
      }
    }
  }, [documentList]);

  function getDisplaySessions(sessions) {
    return sessions.filter((item) => item.eventType === "Display Announcement");
  }
  const displaySessions = getDisplaySessions(session);

  return (
    <div className="wrapper-body">
      {/* New UI Dashboard */}
      <div class="container-fluid right-section d-none">
        <div class="row">
          <div class="col">
            <h2 class="red heading-margin">
              Welcome, {userData ? userData?.name : "Loading..."}!
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="ad">
              <img
                src="/assets/images/dashboard/ad.png"
                alt="Ad"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="user-profile">
              <div class="profie-icon-wrapper">
                <img
                  src={
                    !userData?.photoPath.includes("photoDir")
                      ? "../assets/profile.png"
                      : process.env.REACT_APP_IMAGE_URL + userData?.photoPath
                  }
                  alt="profile icon"
                  width="104"
                  height="104"
                />
              </div>
              <div class="profie-info text-center">
                <h2>{userData?.name}</h2>
                <p>
                  UI UX Designer
                  <br />
                  {userData?.email}
                  <br />
                  7404040553
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class="session-wrapper">
                  <h3 class="heading-margin">Live sessions</h3>
                  <Carousel session={session} />
                  {/* {session && session?.length > 0 && (
                    <>
                      {session?.map((item, index) => (
                        <div class="session-container" key={index}>
                          <div class="session">
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                item?.attachment
                              }
                              alt={item?.title}
                              class="img-fluid"
                            />
                          </div>
                          <div class="session"></div>
                        </div>
                      ))}
                    </>
                  )} */}
                  {/* <div class="session-container">
                    <div class="session">
                      <img
                        src="/assets/images/dashboard/session.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="session"></div>
                  </div> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="employee-on-leave-wrapper">
                  <div class="heading">
                    <h3>Employees on leave</h3>
                    {leaveList?.length > 3 && (
                      <div class="button">
                        <Link to="/dashboard/on-leave-today">See all</Link>
                      </div>
                    )}
                  </div>
                  <div class="employee-on-leave-container">
                    {!isLoading && leaveList?.length > 0 && (
                      <ul class="list-unstyled m-0">
                        {leaveList?.slice(0, 3).map((item, index) => (
                          <li class="employee-detail-list mt-2" key={index}>
                            <div class="profile-icon">
                              <img
                                src="../assets/profile.png"
                                alt="profile icon"
                                width="47"
                                height="47"
                              />
                            </div>
                            <div class="profile-detail">
                              <h5>{item.name}</h5>
                              <p class="m-0">
                                {item.startDate} | {item.endDate}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-4">
              <div class="col">
                <div class="attandance-record-wrapper">
                  <div class="heading">
                    <h3>Attendance Log</h3>
                    <div class="button">
                      <Link
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropRaiseConcern"
                      >
                        Raise concern
                      </Link>
                    </div>
                  </div>
                  <div class="row">
                    {punchList?.map((item, index) => (
                      <div className="col-md-4 col-sm-6" key={index}>
                        <div class="attandance-record-container">
                          <div class="day">
                            <h5 class="red">{item.date}</h5>
                          </div>
                          <div class="attandance-detail-container">
                            <div class="attandance-detail">
                              <div class="type">
                                <p>
                                  <b>In</b>
                                </p>
                              </div>
                              <div class="value">{item.punch_in_time}</div>
                            </div>
                            <div class="attandance-detail">
                              <div class="type">
                                <p>
                                  <b>Out</b>
                                </p>
                              </div>
                              <div class="value">{item.punch_in_out}</div>
                            </div>
                            <div class="attandance-detail">
                              <div class="type">
                                <p>
                                  <b>Hours</b>
                                </p>
                              </div>
                              <div
                                className={`value ${
                                  item.is_positive_hour
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {item.total_hour}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="birthday-anniversaries-wrapper">
              <h3 class="heading-margin">Birthday/Anniversaries</h3>
              <div class="birthday-anniversaries-container">
                <div class="birthday-anniversaries-graphic">
                  <img
                    src="/assets/images/dashboard/birthday-poster.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="birthday-anniversaries-details">
                  <ul class="list-unstyled m-0">
                    <li class="employee-detail-list mt-2">
                      <div class="profile-icon">
                        <img
                          src="/assets/images/dashboard/profile.png"
                          alt="profile icon"
                          width="47"
                          height="47"
                        />
                      </div>
                      <div class="profile-detail">
                        <h5>Vanshika Gupta</h5>
                        <p class="m-0">Birthday | Today</p>
                      </div>
                    </li>

                    <li class="employee-detail-list">
                      <div class="profile-icon">
                        <img
                          src="/assets/images/dashboard/profile.png"
                          alt="profile icon"
                          width="47"
                          height="47"
                        />
                      </div>
                      <div class="profile-detail">
                        <h5>Vanshika Gupta</h5>
                        <p class="m-0">Birthday | Today</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="leave-balance-wrapper mt-4">
              <h3 class="heading-margin">Leave Balance</h3>
              <div class="leave-balance-container">
                <ul class="list-unstyled m-0">
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>WFH (MTD):</b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>
                        {leaveBalance?.workFromHomeLeave
                          ? leaveBalance?.workFromHomeLeave
                          : 0}
                      </p>
                    </div>
                  </li>
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>WFH (QTD):</b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>
                        {leaveBalance?.workFromHomeLeaveForQuarter
                          ? leaveBalance?.workFromHomeLeaveForQuarter
                          : 0}
                      </p>
                    </div>
                  </li>
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>Privilege Leave : </b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>{leaveBalance?.privilegeLeave}</p>
                    </div>
                  </li>
                  {/* <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>Short Leave :</b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>{leaveBalance?.shortLeave}</p>
                    </div>
                  </li> */}
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>Unpaid Leave (MTD) :</b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>{leaveBalance?.unpaidLeaveBalanceMonth}</p>
                    </div>
                  </li>
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>Unpaid Leave (YTD):</b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>{leaveBalance?.unpaidLeaveBalanceYear}</p>
                    </div>
                  </li>
                  <li class="leave-balance-list">
                    <div class="leave-type">
                      <p>
                        <b>Half Yearly Leave : </b>
                      </p>
                    </div>
                    <div class="leave-value">
                      <p>{leaveBalance?.halfYearlyLeave}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* New UI Dashboard */}
      <div className="f">
        {/* Page Heading */}
        {isLoading && (
          <div className="overlay">
            <div className="loader-container">
              <TailSpin
                height="100"
                width="100"
                radius="1"
                color="blue"
                ariaLabel="tail-spin-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}
        <div className="page-heading">
          <div
            id="showMenuBtn"
            className="collapse-button"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <h1>
            {getGreeting()},
            <span> {userData ? userData?.name : "Loading..."}</span>!
          </h1>
          <div className="dropdown">
            <div
              className="btn btn-mute dropdown-toggle "
              type="button"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={
                  !userData?.photoPath.includes("photoDir")
                    ? "../assets/profile.png"
                    : process.env.REACT_APP_IMAGE_URL + userData?.photoPath
                }
                alt="profile"
                className="avatar"
              />
            </div>
            <ul className="dropdown-menu" aria-labelledby="userDropdown">
              {/* <li>
                <a
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropChangePassword"
                >
                  Change Password
                </a>
              </li> */}
              <li>
                <Link className="dropdown-item" to="/dashboard/myProfile">
                  Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" onClick={logoutUser}>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* {displaySessions && displaySessions?.length > 0 && (
          <div className="marquee-container">
            <div className="marquee">
              {displaySessions?.map((event) => (
                <span
                  key={event.id}
                  style={{
                    display: "inline",
                    marginRight: "20px",
                    textTransform: "capitalize",
                  }}
                >
                  - {event.title} (
                  {moment(event.startDateTime)
                    .utcOffset("+05:30")
                    .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                  )
                </span>
              ))}
            </div>
          </div>
        )} */}

        <div className="punchin">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <h3 className="heading">
                  Punch in-out timings (Current Week)
                  <Link
                    className="theme-button p-2 float-end"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropRaiseConcern"
                  >
                    Raise Concern
                  </Link>
                </h3>
                <div className="row g-2">
                  {punchList?.map((item, index) => (
                    <div className="col" key={index}>
                      <div className="bg-white punchin--box p-2 rounded shadow-sm">
                        <h6 className="border-2 border-bottom pb-2">
                          {item.date}
                        </h6>
                        <div className="d-grid punchin--box_outer">
                          <p>
                            <span>In</span>
                          </p>
                          <p>{item.punch_in_time}</p>
                          <p>
                            <span>Out</span>
                          </p>
                          <p>{item.punch_in_out}</p>
                          <p>
                            <span>Hours</span>
                          </p>
                          <p
                            className={
                              item.is_positive_hour
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {item.total_hour}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card min-dashboard-card h-100">
              <h3 className="heading d-f a-i-c justify-content-between">
                Classroom Sessions
                {classroomSession?.length > 0 && (
                  <Link
                    className="theme-button p-2"
                    to="/dashboard/view-announcement#tab1"
                  >
                    View All
                  </Link>
                )}
              </h3>
              <div className="row gx-2">
                {classroomSession?.map((item, index) => (
                  <div className="col-md-6 mb-2" key={index}>
                    <div className="outer border p-2">
                      <Link to="/dashboard/view-announcement#tab1">
                        <h6 className="text-capitalize">{item.title}</h6>
                        <p className="mb-1">
                          <small>
                            {moment(item.startDateTime)
                              .utcOffset("+05:30")
                              .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small className="text-capitalize">
                            {item.presentedBy}
                          </small>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
                {classroomSession?.length === 0 && (
                  <div className="col-md-12">
                    <div className="outer border p-2 text-center">
                      <h6 className="space-text">
                        No upcoming training sessions are currently scheduled.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card min-dashboard-card h-100">
              <h3 className="heading d-f a-i-c justify-content-between">
                Coffee & Learn Sessions
                {coffeeLearnSession?.length > 0 && (
                  <Link
                    className="theme-button p-2"
                    to="/dashboard/view-announcement#tab2"
                  >
                    View All
                  </Link>
                )}
              </h3>
              <div className="row gx-2">
                {coffeeLearnSession?.map((item, index) => (
                  <div className="col-md-6 mb-2" key={index}>
                    <div className="outer border p-2">
                      <Link to="/dashboard/view-announcement#tab2">
                        <h6 className="text-capitalize">{item.title}</h6>
                        <p className="mb-1">
                          <small>
                            {moment(item.startDateTime)
                              .utcOffset("+05:30")
                              .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small className="text-capitalize">
                            {item.presentedBy}
                          </small>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
                {coffeeLearnSession?.length === 0 && (
                  <div className="col-md-12">
                    <div className="outer border p-2 text-center ">
                      <h6 className="space-text">
                        No upcoming training sessions are currently scheduled.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card min-dashboard-card h-100">
              <h3 className="heading d-f a-i-c justify-content-between">
                MuleSoft Meetups
                {muleSoftMeetups?.length > 0 && (
                  <Link
                    className="theme-button p-2"
                    to="/dashboard/view-announcement#tab3"
                  >
                    View All
                  </Link>
                )}
              </h3>
              <div className="row gx-2">
                {muleSoftMeetups?.map((item, index) => (
                  <div className="col-md-6 mb-2" key={index}>
                    <div className="outer border p-2">
                      <Link to="/dashboard/view-announcement#tab3">
                        <h6 className="text-capitalize">{item.title}</h6>
                        <p className="mb-1">
                          <small>
                            {moment(item.startDateTime)
                              .utcOffset("+05:30")
                              .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small className="text-capitalize">
                            {item.presentedBy}
                          </small>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
                {muleSoftMeetups?.length === 0 && (
                  <div className="col-md-12">
                    <div className="outer border p-2 text-center ">
                      <h6 className="space-text">
                        No upcoming training sessions are currently scheduled.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card min-dashboard-card h-100">
              <h3 className="heading d-f a-i-c justify-content-between">
                Salesforce Sessions
                {salesforceSession?.length > 0 && (
                  <Link
                    className="theme-button p-2"
                    to="/dashboard/view-announcement#tab4"
                  >
                    View All
                  </Link>
                )}
              </h3>
              <div className="row gx-2">
                {salesforceSession?.map((item, index) => (
                  <div className="col-md-6 mb-2" key={index}>
                    <div className="outer border p-2">
                      <Link to="/dashboard/view-announcement#tab4">
                        <h6 className="text-capitalize">{item.title}</h6>
                        <p className="mb-1">
                          <small>
                            {moment(item.startDateTime)
                              .utcOffset("+05:30")
                              .format("ddd, DD MMM, YYYY | HH:mm") + " IST"}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small className="text-capitalize">
                            {item.presentedBy}
                          </small>
                        </p>
                      </Link>
                    </div>
                  </div>
                ))}
                {salesforceSession?.length === 0 && (
                  <div className="col-md-12">
                    <div className="outer border p-2 text-center ">
                      <h6 className="space-text">
                        No upcoming training sessions are currently scheduled.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {leaveList?.length > 0 && (
            <div className="col-md-12">
              <div className="employee_leaves">
                <div className="card ">
                  <h3 className="heading d-f a-i-c justify-content-between">
                    Out Of Office
                    {leaveList?.length > 5 && (
                      <Link
                        className="theme-button p-2"
                        to="/dashboard/on-leave-today"
                      >
                        View All
                      </Link>
                    )}
                  </h3>

                  {/* Data Table */}
                  {!isLoading && leaveList?.length > 0 && (
                    <table className="resume custom">
                      {/* Table Headings */}
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Employee</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Type</th>
                          <th>Reason</th>
                          <th>Leave Count</th>
                        </tr>
                      </thead>
                      {/* Table Body */}
                      <tbody>
                        {leaveList?.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.startDate}</td>
                            <td>{item.endDate}</td>
                            <td>{formatLeaveType(item.typeOfLeave)}</td>
                            <td style={{ maxWidth: "250px" }}>
                              <TruncateText text={item.reasonOfLeave} />
                            </td>
                            <td>{item.noOfDays}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="modal fade"
          id="staticBackdropChangePassword"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <ChangePassword />
            </div>
          </div>
        </div>
      </div>
      <button
        className="click-modal-upload"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropDocument"
        style={{ display: "none" }}
      >
        click
      </button>
      <div
        className="modal fade"
        id="staticBackdropDocument"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <button
            type="button"
            className="btn-close-document"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ display: "none" }}
          ></button>
          <div className="modal-content">
            <DocumentUpload
              documentList={documentList}
              fetchDocumentStatus={fetchDocumentStatus}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdropRaiseConcern"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <RaiseConcern />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
